/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubLite } from '../models/ClubLite';
import type { ClubsAvailabilityGetResponse } from '../models/ClubsAvailabilityGetResponse';
import type { ClubSetupModel } from '../models/ClubSetupModel';
import type { ClubsInitializeAiChatPostResponse } from '../models/ClubsInitializeAiChatPostResponse';
import type { ClubsPostRequest } from '../models/ClubsPostRequest';
import type { ClubsPostResponse } from '../models/ClubsPostResponse';
import type { ClubsPutRequest } from '../models/ClubsPutRequest';
import type { CreateCheckoutSessionRequest } from '../models/CreateCheckoutSessionRequest';
import type { CreateCheckoutSessionResponse } from '../models/CreateCheckoutSessionResponse';
import type { CreatePortalSessionRequest } from '../models/CreatePortalSessionRequest';
import type { CreatePortalSessionResponse } from '../models/CreatePortalSessionResponse';
import type { CreateTrialSubscriptionRequest } from '../models/CreateTrialSubscriptionRequest';
import type { CreateTrialSubscriptionResponse } from '../models/CreateTrialSubscriptionResponse';
import type { GetClubAdminsResponse } from '../models/GetClubAdminsResponse';
import type { GetClubMembershipsResponse } from '../models/GetClubMembershipsResponse';
import type { GetClubsResponse } from '../models/GetClubsResponse';
import type { GetSubscriptionResponse } from '../models/GetSubscriptionResponse';
import type { UpdateClubSetupDataRequest } from '../models/UpdateClubSetupDataRequest';
import type { UpdateSubscriptionRequest } from '../models/UpdateSubscriptionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClubsService {
	/**
	 * Get club setup data
	 * GET /clubs/{displayId}/setup-data
	 * @returns ClubSetupModel Successful Response
	 * @throws ApiError
	 */
	public static getClubSetupDataClubsDisplayIdSetupDataGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubSetupModel> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/setup-data',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update club setup data
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateClubSetupDataClubsDisplayIdSetupDataPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: UpdateClubSetupDataRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/setup-data',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Request a report to be exported for this club
	 * @returns CreateCheckoutSessionResponse Successful Response
	 * @throws ApiError
	 */
	public static createCheckoutSessionClubsDisplayIdSubscriptionCreateCheckoutSessionPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: CreateCheckoutSessionRequest;
	}): CancelablePromise<CreateCheckoutSessionResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/subscription/create-checkout-session',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a trial subscription for the group
	 * @returns CreateTrialSubscriptionResponse Successful Response
	 * @throws ApiError
	 */
	public static createTrialSubscriptionClubsDisplayIdSubscriptionCreateTrialSubscriptionPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: CreateTrialSubscriptionRequest;
	}): CancelablePromise<CreateTrialSubscriptionResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/subscription/create-trial-subscription',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a portal session for the user to manage their subscription
	 * @returns CreatePortalSessionResponse Successful Response
	 * @throws ApiError
	 */
	public static createPortalSessionClubsDisplayIdSubscriptionCreatePortalSessionPost({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: CreatePortalSessionRequest;
	}): CancelablePromise<CreatePortalSessionResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/subscription/create-portal-session',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get the subscription status for the group
	 * @returns GetSubscriptionResponse Successful Response
	 * @throws ApiError
	 */
	public static getClubSubscriptionClubsDisplayIdSubscriptionGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<GetSubscriptionResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/subscription',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update the subscription for the group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateClubSubscriptionClubsDisplayIdSubscriptionPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: UpdateSubscriptionRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}/subscription',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Delete the subscription for the group
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deleteClubSubscriptionClubsDisplayIdSubscriptionDelete({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'DELETE',
			url: '/clubs/{displayId}/subscription',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Create a New Intros Club
	 * Used to create a new Intros Club via the API
	 * @returns ClubsPostResponse Successful Response
	 * @throws ApiError
	 */
	public static createNewGroupClubsPost({
		requestBody
	}: {
		requestBody: ClubsPostRequest;
	}): CancelablePromise<ClubsPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Load Clubs for a User
	 * GET /clubs
	 * @returns GetClubsResponse Successful Response
	 * @throws ApiError
	 */
	public static getClubsClubsGet({
		uid,
		includeAdmin,
		includeMember
	}: {
		uid?: number;
		includeAdmin?: boolean;
		includeMember?: boolean;
	}): CancelablePromise<GetClubsResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs',
			query: {
				uid: uid,
				include_admin: includeAdmin,
				include_member: includeMember
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Check if a club exists
	 * Retrieve a club's public info if it exists
	 * @returns ClubsAvailabilityGetResponse Successful Response
	 * @throws ApiError
	 */
	public static checkClubAvailabilityClubsAvailabilityGet({
		name,
		displayId
	}: {
		name?: string;
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId?: string;
	}): CancelablePromise<ClubsAvailabilityGetResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/availability',
			query: {
				name: name,
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Club Lite Info
	 * GET /clubs/{displayId}
	 * @returns ClubLite Successful Response
	 * @throws ApiError
	 */
	public static getClubLiteByDisplayIdClubsDisplayIdGet({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubLite> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Update the group with the given display id
	 * PUT /clubs/{displayId}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static updateGroupClubsDisplayIdPut({
		displayId,
		requestBody
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		requestBody: ClubsPutRequest;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'PUT',
			url: '/clubs/{displayId}',
			path: {
				displayId: displayId
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Initialize AI Chat for the given group
	 * PUT /clubs/{displayId}
	 * @returns ClubsInitializeAiChatPostResponse Successful Response
	 * @throws ApiError
	 */
	public static initializeAiChatClubsDisplayIdInitializeAiChatPost({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<ClubsInitializeAiChatPostResponse> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/initialize-ai-chat',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Deactivate AI Chat for the given group
	 * PUT /clubs/{displayId}
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static deactivateAiChatClubsDisplayIdDeactivateAiChatPost({
		displayId
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
	}): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/clubs/{displayId}/deactivate-ai-chat',
			path: {
				displayId: displayId
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Get Club feature access information
	 * GET /clubs/<display id>/access/<feature>
	 * @returns any Successful Response
	 * @throws ApiError
	 */
	public static checkClubFeatureAccessClubsDisplayIdAccessFeatureGet({
		displayId,
		feature
	}: {
		/**
		 * The unique Display Id of the associated Intros Club. Obtained from Intros Club Dashboard.
		 */
		displayId: string;
		feature: string;
	}): CancelablePromise<boolean | null> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/clubs/{displayId}/access/{feature}',
			path: {
				displayId: displayId,
				feature: feature
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Load Club Admins for a User
	 * GET /clubs
	 * @returns GetClubAdminsResponse Successful Response
	 * @throws ApiError
	 */
	public static getClubAdminsUsersSelfClubAdminsGet({
		uid
	}: {
		uid?: number;
	}): CancelablePromise<GetClubAdminsResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/self/clubAdmins',
			query: {
				uid: uid
			},
			errors: {
				422: `Validation Error`
			}
		});
	}

	/**
	 * Load Club Memberships for a User
	 * GET /clubs
	 * @returns GetClubMembershipsResponse Successful Response
	 * @throws ApiError
	 */
	public static getClubMembershipsUsersSelfClubMembershipsGet(): CancelablePromise<GetClubMembershipsResponse> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/users/self/clubMemberships',
			errors: {
				422: `Validation Error`
			}
		});
	}
}
