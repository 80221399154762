import { action, makeObservable, observable } from 'mobx';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../../AppModel';
import { RestApiClient } from '../../api/rest/restApiClientModel';
import { IGroupIdentificationData } from '../types';
import {
	ClubsConfigGetResponse,
	ClubsConfigPutRequest,
	ClubsConfigPutResponse,
	ConfigurationService,
	GroupConfigModel
} from '../../../api-client';

export interface IGroupConfig {
	/**
	 * The welcome title for join survey of the specified group
	 */
	welcomeTitle: string;
	/**
	 * The welcome message for join survey of the specified group
	 */
	welcomeMessage: string;
	/**
	 * Custom wording for the matching times question
	 */
	matchingTimesQuestion: string;
	/**
	 * Custom Prefix for the user join email of the specified group
	 */
	firstEmailTop: string;
	/**
	 * Custom Appendage for the user join email of the specified group
	 */
	firstEmailBottom: string;
	/**
	 * Welcome instructions used for plaintext welcome emails
	 */
	welcomeInstructions: string;
	/**
	 * Custom signature used for plaintext welcome emails
	 */
	customSignature: string;
	/**
	 * Greater than 0 if group should use plaintext for welcome emails
	 */
	plainTextWelcome: boolean;
	/**
	 * Greater than 0 if group should use plaintext for feedback emails
	 */
	plainTextFeedback: boolean;
	/**
	 * Greater than 0 if group should use plaintext for opt-in emails
	 */
	plainTextOptIn: boolean;
	/**
	 * Greater than 0 if group should use plaintext for introduction emails
	 */
	plainTextIntro: boolean;
	/**
	 * Custom Prefix for Intro email of the specified group
	 */
	introEmailTop: string;
	/**
	 * Custom image path for this group
	 */
	imagePath: string;
	/**
	 * True if the group has autoaccept enabled
	 */
	autoaccept: boolean;
	/**
	 * True if the group has their topic question enabled
	 */
	topicQuestionEnabled: boolean;
	/**
	 * True if the group wants Meyers-Briggs personality assessment to be used in their matching algorithm
	 */
	personalityIntros: number;
	/**
	 * True if the group wants to limit matching based on desired tone
	 */
	toneMatching: number;
	/**
	 * Custom wording for the topic question fo the specified group
	 */
	topicQuestionTitle: string;
	/**
	 * Minimum number of topics required for users joining the specified group
	 */
	minTopicsRequired: boolean;
	/**
	 * True if this group has paused their intros
	 */
	paused: boolean;
	/**
	 * True if this group has double opt-in enabled
	 */
	doubleOptIn: boolean;
	/**
	 * True if this group has single opt-in enabled
	 */
	singleOptIn: boolean;
	/**
	 * True if this group has intros scheduling enabled
	 */
	scheduleIntro: boolean;
	/**
	 * Unique discord channel id to send intros updates on discord
	 */
	discordChannelId: number;
	/**
	 * True if this group is utilizing random matching
	 */
	randomMatch: boolean;

	/**
	 * 1 if users choose weights for this groups matching algorithm
	 */
	usersChooseWeights: boolean;
	/**
	 * 1 if the directory is enabled for this community, 0 otherwise
	 */
	isDirectoryEnabled: boolean;
	/**
	 * True if the proposer should be CC'd on proposal emails
	 */
	isProposerCCd: boolean;
	/**
	 * 1 if Slack flow is enabled for this community, 0 otherwise
	 */
	isSlack: boolean;
	/**
	 * 1 if Discord flow is enabled for this community, 0 otherwise
	 */
	isDiscord: boolean;
	/**
	 * Custom directory link for this group (if provided)
	 */
	customDirectoryLink: string;
	/**
	 * 1 if user replies to emails should go to the admin, False otherwise
	 */
	replyToAdmin: boolean;
	/**
	 * 1 if the admin should be CC'd on all Intros
	 */
	ccAdmin: boolean;
	/**
	 * Name to display for this admin when sending emails to users
	 */
	emailDisplayName: string;
	/**
	 * Title of the calendar invite that is sent between 2 members of a scheduled intro
	 */
	calendarInviteTitle: string;
	/**
	 * Verified email from which to send messages
	 */
	verifiedSender: string;
	/**
	 * True if the verified email has been confirmed by the user
	 */
	verifiedSenderConfirmed: boolean;
	/**
	 * Date this group was created
	 */
	dateCreated: string;
	/**
	 * Color to use for this group's primary buttons
	 */
	buttonColor: string;
	/**
	 * Color to use for this group's primary text
	 */
	textColor: string;
	/**
	 * Color to use for this group's join form background
	 */
	joinFormColor: string;
	/**
	 * True if we should use the default join form image as background
	 */
	useJoinFormImage: boolean;
	/**
	 * Greater than 0 if we should disable Intros aesthetic images
	 */
	disableImages: boolean;
	/**
	 * Greater than 0 if we should include promo in join form
	 */
	includeJoinFormPromo: boolean;
	/**
	 * Subject to notify subscribers when the data has been initialized
	 */
	initialized: Subject<any>;
	/**
	 * True when the config is loading
	 */
	loading: boolean;
	/**
	 * Preferred timezone for the group
	 */
	timezone: string;
	/**
	 * True if the group is using the matching v2 flow
	 */
	useMatchingV2: boolean;
	/**
	 * True if the matching v2 flow is started (group has enough members)
	 */
	hasGroupKickedOff: boolean;
	/**
	 * True when we should require the member bio in the join form for this group
	 */
	includeBioInJoinForm: boolean;
	/**
	 * True when we should require the member location in the join form for this group
	 */
	includeLocationInJoinForm: boolean;
	/**
	 * True when we should require the member job info in the join form for this group
	 */
	includeJobTitleInJoinForm: boolean;
	/**
	 * True when we should require the member company in the join form for this group
	 */
	includeCompanyInJoinForm: boolean;
	/**
	 * True when we should require the member education info in the join form for this group
	 */
	includeEducationInJoinForm: boolean;
	/**
	 * True when we should require the member degrees in the join form for this group
	 */
	includeDegreesInJoinForm: boolean;
	/**
	 * True when we should require the member LinkedIn in the join form for this group
	 */
	includeLinkedInInJoinForm: boolean;
	/**
	 * True when we should require the member Twitter in the join form for this group
	 */
	includeTwitterInJoinForm: boolean;
	/**
	 * True when we should require the member personal site in the join form for this group
	 */
	includePersonalSiteInJoinForm: boolean;
	/**
	 * True when we should require the member pronouns in the join form for this group
	 */
	includePronounsInJoinForm: boolean;
	/**
	 * True when we should require the member phone number in the join form for this group
	 */
	includePhoneNumberInJoinForm: boolean;
	/**
	 * Welcome message to send to members who join the Slack
	 */
	slackWelcomeMessage: string;
	/**
	 * Opt-In message to send to members on slack
	 */
	slackOptInMessage: string;
	/**
	 * Opt-Out message to send to members on slack
	 */
	slackOptOutMessage: string;
	/**
	 * Intro message to send to members on slack
	 */
	slackIntroMessage: string;
	/**
	 * Opt-In message to send to members on discord
	 */
	discordOptInMessage: string;
	/**
	 * Opt-Out message to send to members on discord
	 */
	discordOptOutMessage: string;
	/*
	 * The time for the upper bound of the group's period of time that member communications can be sent out
	 */
	communicationStartTime: string;
	/**
	 * The time for the lower bound of the group's period of time that member communications can be sent out
	 */
	communicationEndTime: string;
	/**
	 * The JSON body for the group's opt in email
	 */
	optInJSON: object;
	/**
	 * The subject line for the group's opt in email
	 */
	optInSubject: string;
	/**
	 * The JSON object for the group's opt in email
	 */
	optOutJSON: object;
	/**
	 * The subject line for the group's opt in email
	 */
	optOutSubject: string;
	/**
	 * The JSON object for the group's welcome email
	 */
	welcomeJSON: object;
	/**
	 * The subject line for the group's welcome email
	 */
	welcomeSubject: string;
	/**
	 * Greater than 0 if this group should use the legacy welcome email system
	 */
	legacyWelcomeMessage: boolean;
	/**
	 * Greater than 0 if we should default this group to auto enroll
	 */
	defaultToAutoEnroll: boolean;
	/**
	 * Heading for a group's auto enroll invite
	 */
	autoEnrollInviteHeading: string;
	/**
	 * Body for a group's auto enroll invite
	 */
	autoEnrollInviteBody: string;
	/**
	 * Heading for a group's general invite
	 */
	generalInviteHeading: string;
	/**
	 * Body for a group's general invite
	 */
	generalInviteBody: string;
	/**
	 * True if we should send members who join the slack group a welcome message to join the Intros club
	 */
	sendNewSlackMembersWelcomeMessage: boolean;
	/**
	 * True if this group has Zapier connected
	 */
	isZapierConnected: boolean;
	/**
	 * True if we should send members who directly join through zapier an invite message
	 */
	zapierAutoInvite: boolean;
	/**
	 * True if we should automatically enroll members who join through zapier
	 */
	zapierAutoEnroll: boolean;
	/**
	 * Index for the email question
	 */
	emailIndex: number;
	/**
	 * Index for the basics question
	 */
	basicsIndex: number;
	/**
	 * Index for the times question
	 */
	timesIndex: number;
	/**
	 *
	 */
	openAiAssistantInstructions: string;
	/**
	 * Triggers the config model to load itself from the database
	 */
	loadConfig(): void;
	/**
	 * Set the display id of the config
	 */
	setDisplayId(displayId: string): void;
	/**
	 * Set whether the sender is verified
	 * @param verified
	 */
	setSenderVerified(verified: boolean): void;
	/**
	 * Updates the config
	 */
	update(config: ClubsConfigPutRequest): Observable<ClubsConfigPutResponse>;
	/**
	 * The date that the admin last notified the users with incomplete surveys
	 */
	lastNotifiedUsers: string;
	/**
	 * True if there are still members in the group that have incomplete surveys
	 */
	incompleteUserSurveys: boolean;
	/**
	 * The subject line for the intro email
	 */
	introEmailSubjectLine: string;
	/**
	 * True if we should show the bio in the intro email
	 */
	introEmailBasicsShowBio: boolean;
	/**
	 * True if we should show the location in the intro email
	 */
	introEmailBasicsShowLocation: boolean;
	/**
	 * True if we should show the job title in the intro email
	 */
	introEmailBasicsShowJobTitle: boolean;
	/**
	 * True if we should show the company in the intro email
	 */
	introEmailBasicsShowCompany: boolean;
	/**
	 * True if we should show the education in the intro email
	 */
	introEmailBasicsShowEducation: boolean;
	/**
	 * True if we should show the degrees in the intro email
	 */
	introEmailBasicsShowDegrees: boolean;
	/**
	 * True if we should show the linkedin in the intro email
	 */
	introEmailBasicsShowLinkedin: boolean;
	/**
	 * True if we should show the twitter in the intro email
	 */
	introEmailBasicsShowTwitter: boolean;
	/**
	 * True if we should show the personal site in the intro email
	 */
	introEmailBasicsShowPersonalSite: boolean;
	/**
	 * True if we should show the pronouns in the intro email
	 */
	introEmailBasicsShowPronouns: boolean;
	/**
	 * True if we should show the phone number in the intro email
	 */
	introEmailBasicsShowPhoneNumber: boolean;
	/**
	 * True if automated connections are enabled for this group
	 */
	automatedConnectionsEnabled: boolean;
	/**
	 * True if AI assistant connections are enabled for this group
	 */
	aiAssistantConnectionsEnabled: boolean;
	/**
	 * True if directory connections are enabled for this group
	 */
	directoryConnectionsEnabled: boolean;
	/**
	 * The first example of the opening message for the slack bot intro
	 */
	slackIntroOpeningMessageExample1: string;
	/**
	 * The second example of the opening message for the slack bot intro
	 */
	slackIntroOpeningMessageExample2: string;
	/**
	 * The third example of the opening message for the slack bot intro
	 */
	slackIntroOpeningMessageExample3: string;
	/**
	 * The first example of the middle message for the slack bot intro
	 */
	slackIntroMiddleMessageExample1: string;
	/**
	 * The second example of the middle message for the slack bot intro
	 */
	slackIntroMiddleMessageExample2: string;
	/**
	 * The third example of the middle message for the slack bot intro
	 */
	slackIntroMiddleMessageExample3: string;
	/**
	 * The first example of the next steps message for the slack bot intro
	 */
	slackIntroNextStepsMessageExample1: string;
	/**
	 * The second example of the next steps message for the slack bot intro
	 */
	slackIntroNextStepsMessageExample2: string;
	/**
	 * The third example of the next steps message for the slack bot intro
	 */
	slackIntroNextStepsMessageExample3: string;
	/**
	 * True if we should display a scheduler in a slack intro
	 */
	displaySchedulerInSlackIntro: boolean;
	/**
	 * True if we should display a scheduler in an intro request
	 */
	displaySchedulerInIntroRequest: boolean;
}

export class GroupConfig implements IGroupConfig {
	/**
	 * Unique user-readable id for the group to which this config belongs
	 */
	private displayId: string;
	@observable
	public welcomeTitle: string;
	@observable
	public welcomeMessage: string;
	@observable
	public matchingTimesQuestion: string;
	@observable
	public firstEmailTop: string;
	@observable
	public firstEmailBottom: string;
	@observable
	public welcomeInstructions: string;
	@observable
	public customSignature: string;
	@observable
	public plainTextWelcome: boolean;
	@observable
	public plainTextFeedback: boolean;
	@observable
	public plainTextOptIn: boolean;
	@observable
	public plainTextIntro: boolean;
	@observable
	public introEmailTop: string;
	@observable
	public imagePath: string;
	@observable
	public autoaccept: boolean;
	@observable
	public topicQuestionEnabled: boolean;
	@observable
	public personalityIntros: number;
	@observable
	public toneMatching: number;
	@observable
	public topicQuestionTitle: string;
	@observable
	public minTopicsRequired: boolean;
	@observable
	public paused: boolean;
	@observable
	public doubleOptIn: boolean;
	@observable
	public singleOptIn: boolean;
	@observable
	public scheduleIntro: boolean;
	@observable
	public discordChannelId: number;
	@observable
	public randomMatch: boolean;
	@observable
	public usersChooseWeights: boolean;
	@observable
	public isSlack: boolean;
	@observable
	public isDiscord: boolean;
	@observable
	public isDirectoryEnabled: boolean;
	@observable
	public isProposerCCd: boolean;
	@observable
	public customDirectoryLink: string;
	@observable
	public replyToAdmin: boolean;
	@observable
	public ccAdmin: boolean;
	@observable
	public emailDisplayName: string;
	@observable
	public calendarInviteTitle: string;
	@observable
	public verifiedSender: string;
	@observable
	public verifiedSenderConfirmed: boolean;
	@observable
	public dateCreated: string;
	@observable
	public initialized: Subject<any>;
	@observable
	public incompleteUserSurveys: boolean = false;
	@observable
	public lastNotifiedUsers: string;
	@observable
	public buttonColor: string;
	@observable
	public textColor: string;
	@observable
	public joinFormColor: string;
	@observable
	public useJoinFormImage: boolean;
	@observable
	public disableImages: boolean;
	@observable
	public includeJoinFormPromo: boolean;
	@observable
	public allowEventRoundOptOut: number;
	@observable
	public loading: boolean = false;
	@observable
	public timezone: string;
	@observable
	public useMatchingV2: boolean;
	@observable
	public hasGroupKickedOff: boolean;
	@observable
	public slackWelcomeMessage: string;
	@observable
	public slackOptInMessage: string;
	@observable
	public slackOptOutMessage: string;
	@observable
	public slackIntroMessage: string;
	@observable
	public discordOptInMessage: string;
	@observable
	public discordOptOutMessage: string;
	@observable
	public communicationStartTime: string;
	@observable
	public communicationEndTime: string;
	@observable
	public optInJSON: object;
	@observable
	public optInSubject: string;
	@observable
	public optOutJSON: object;
	@observable
	public optOutSubject: string;
	@observable
	public welcomeJSON: object;
	@observable
	public welcomeSubject: string;
	@observable
	public legacyWelcomeMessage: boolean;
	@observable
	public defaultToAutoEnroll: boolean;
	@observable
	public autoEnrollInviteHeading: string;
	@observable
	public autoEnrollInviteBody: string;
	@observable
	public generalInviteHeading: string;
	@observable
	public generalInviteBody: string;
	@observable
	public includeBioInJoinForm: boolean;
	@observable
	public includeLocationInJoinForm: boolean;
	@observable
	public includeJobTitleInJoinForm: boolean;
	@observable
	public includeCompanyInJoinForm: boolean;
	@observable
	public includeEducationInJoinForm: boolean;
	@observable
	public includeDegreesInJoinForm: boolean;
	@observable
	public includeLinkedInInJoinForm: boolean;
	@observable
	public includeTwitterInJoinForm: boolean;
	@observable
	public includePersonalSiteInJoinForm: boolean;
	@observable
	public includePronounsInJoinForm: boolean;
	@observable
	public includePhoneNumberInJoinForm: boolean;
	@observable
	public sendNewSlackMembersWelcomeMessage: boolean;
	@observable
	public isZapierConnected: boolean;
	@observable
	public zapierAutoInvite: boolean;
	@observable
	public zapierAutoEnroll: boolean;
	@observable
	public emailIndex: number;
	@observable
	public basicsIndex: number;
	@observable
	public timesIndex: number;
	@observable
	public introEmailSubjectLine: string;
	@observable
	public introEmailBasicsShowBio: boolean;
	@observable
	public introEmailBasicsShowLocation: boolean;
	@observable
	public introEmailBasicsShowJobTitle: boolean;
	@observable
	public introEmailBasicsShowCompany: boolean;
	@observable
	public introEmailBasicsShowEducation: boolean;
	@observable
	public introEmailBasicsShowDegrees: boolean;
	@observable
	public introEmailBasicsShowLinkedin: boolean;
	@observable
	public introEmailBasicsShowTwitter: boolean;
	@observable
	public introEmailBasicsShowPersonalSite: boolean;
	@observable
	public introEmailBasicsShowPronouns: boolean;
	@observable
	public introEmailBasicsShowPhoneNumber: boolean;
	@observable
	public automatedConnectionsEnabled: boolean;
	@observable
	public aiAssistantConnectionsEnabled: boolean;
	@observable
	public directoryConnectionsEnabled: boolean;
	@observable
	public openAiAssistantInstructions: string;
	@observable
	public slackIntroOpeningMessageExample1: string;
	@observable
	public slackIntroOpeningMessageExample2: string;
	@observable
	public slackIntroOpeningMessageExample3: string;
	@observable
	public slackIntroMiddleMessageExample1: string;
	@observable
	public slackIntroMiddleMessageExample2: string;
	@observable
	public slackIntroMiddleMessageExample3: string;
	@observable
	public slackIntroNextStepsMessageExample1: string;
	@observable
	public slackIntroNextStepsMessageExample2: string;
	@observable
	public slackIntroNextStepsMessageExample3: string;
	@observable
	public displaySchedulerInSlackIntro: boolean;
	@observable
	public displaySchedulerInIntroRequest: boolean;

	constructor(params: IGroupIdentificationData) {
		makeObservable(this);
		this.displayId = params.displayId;
		this.initialized = new Subject();
		this.loadConfig();
	}

	public loadConfig() {
		if (!this.displayId) {
			console.error(
				'[loadConfig] Cannot load group config without display id...'
			);
			return;
		}
		this.setLoading(true);
		RestApiClient.serviceRequest<ClubsConfigGetResponse>({
			generator: () =>
				ConfigurationService.getConfigClubsDisplayIdConfigGet({
					displayId: encodeURIComponent(this.displayId)
				}),
			userToken: AppState.user?.cookie
		}).subscribe((data: ClubsConfigGetResponse) => {
			this.setConfig(data.config);
			this.setLoading(false);
			this.initialized.next();
		});
	}

	public update(
		config: ClubsConfigPutRequest
	): Observable<ClubsConfigPutResponse> {
		return RestApiClient.serviceRequest({
			generator: () =>
				ConfigurationService.updateConfigClubsDisplayIdConfigPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: config
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map((response: ClubsConfigPutResponse) => {
				Object.entries(config).forEach(([key, value]) => {
					if (value !== undefined) {
						// @ts-ignore
						this[key] = value;
					}
				});
				// pass on response
				return response;
			})
		);
	}

	/**
	 * Used to update the display id
	 * @param displayId
	 */
	@action
	public setDisplayId(displayId: string) {
		this.displayId = displayId;
	}

	/**
	 * Used to update verified sender confirmed
	 * @param verified
	 */
	@action
	public setSenderVerified(verified: boolean) {
		this.verifiedSenderConfirmed = verified;
	}

	/**
	 * Sets the config
	 */
	@action
	private setConfig(config: GroupConfigModel) {
		// messaging settings
		this.welcomeTitle = config.welcomeTitle;
		this.welcomeMessage = config.welcomeMessage;
		this.matchingTimesQuestion = config.matchingTimesQuestion;
		this.firstEmailBottom = config.firstEmailBottom;
		this.firstEmailTop = config.firstEmailTop;
		this.welcomeInstructions = config.welcomeInstructions;
		this.customSignature = config.customSignature;
		this.plainTextWelcome = config.plainTextWelcome;
		this.plainTextFeedback = config.plainTextFeedback;
		this.plainTextOptIn = config.plainTextOptIn;
		this.plainTextIntro = config.plainTextIntro;
		this.introEmailTop = config.introEmailTop;
		this.discordChannelId = config.discordChannelId;
		// image path
		this.imagePath = config.imagePath;
		// matching
		// TODO: fix server! if returns autoaccept as a string "0" or "1", rather than as a number
		this.autoaccept = config.autoaccept;
		this.randomMatch = config.randomMatch;
		this.usersChooseWeights = config.usersChooseWeights;
		this.paused = config.paused;
		this.isDirectoryEnabled = config.isDirectoryEnabled;
		this.isProposerCCd = config.isProposerCCd;
		this.customDirectoryLink = config.customDirectoryLink;
		this.replyToAdmin = config.replyToAdmin;
		this.ccAdmin = config.ccAdmin;
		this.emailDisplayName = config.emailDisplayName;
		this.calendarInviteTitle = config.calendarInviteTitle;
		this.verifiedSender =
			config.verifiedSender !== null ? config.verifiedSender : undefined;
		this.verifiedSenderConfirmed = config.verifiedSenderConfirmed;
		this.buttonColor = config.buttonColor;
		this.textColor = config.textColor;
		this.joinFormColor = config.joinFormColor;
		this.useJoinFormImage = config.useJoinFormImage;
		this.disableImages = config.disableImages;
		this.includeJoinFormPromo = config.includeJoinFormPromo;
		this.timezone = config.timezone;
		this.useMatchingV2 = config.useMatchingV2;
		this.hasGroupKickedOff = config.hasGroupKickedOff;
		this.slackWelcomeMessage = config.slackWelcomeMessage;
		this.slackOptInMessage = config.slackOptInMessage;
		this.slackOptOutMessage = config.slackOptOutMessage;
		this.slackIntroMessage = config.slackIntroMessage;
		this.discordOptInMessage = config.discordOptInMessage;
		this.discordOptOutMessage = config.discordOptOutMessage;
		this.isSlack = config.isSlack;
		this.isDiscord = config.isDiscord;
		this.sendNewSlackMembersWelcomeMessage =
			config.sendNewSlackMembersWelcomeMessage;
		this.communicationStartTime = config.communicationStartTime;
		this.communicationEndTime = config.communicationEndTime;
		this.optInJSON = config.optInJSON;
		this.optInSubject = config.optInSubject;
		this.optOutJSON = config.optOutJSON;
		this.optOutSubject = config.optOutSubject;
		// set data for saved invites
		this.defaultToAutoEnroll = config.defaultToAutoEnroll;
		this.autoEnrollInviteHeading = config.autoEnrollInviteHeading;
		this.autoEnrollInviteBody = config.autoEnrollInviteBody;
		this.generalInviteHeading = config.generalInviteHeading;
		this.generalInviteBody = config.generalInviteBody;
		// set data for migrating from old welcome to new welcome template
		this.welcomeJSON = config.welcomeJSON;
		this.welcomeSubject = config.welcomeSubject;
		this.legacyWelcomeMessage = config.legacyWelcomeMessage;
		// zapier connection settings
		this.isZapierConnected = config.isZapierConnected;
		this.zapierAutoInvite = config.zapierAutoInvite;
		this.zapierAutoEnroll = config.zapierAutoEnroll;
		// additional join form settings
		this.emailIndex = config.emailIndex;
		this.basicsIndex = config.basicsIndex;
		this.timesIndex = config.timesIndex;
		// basics settings
		this.includeBioInJoinForm = config.includeBioInJoinForm;
		this.includeLocationInJoinForm = config.includeLocationInJoinForm;
		this.includeJobTitleInJoinForm = config.includeJobTitleInJoinForm;
		this.includeCompanyInJoinForm = config.includeCompanyInJoinForm;
		this.includeEducationInJoinForm = config.includeEducationInJoinForm;
		this.includeDegreesInJoinForm = config.includeDegreesInJoinForm;
		this.includeLinkedInInJoinForm = config.includeLinkedInInJoinForm;
		this.includeTwitterInJoinForm = config.includeTwitterInJoinForm;
		this.includePersonalSiteInJoinForm =
			config.includePersonalSiteInJoinForm;
		this.includePronounsInJoinForm = config.includePronounsInJoinForm;
		this.includePhoneNumberInJoinForm = config.includePhoneNumberInJoinForm;
		this.introEmailSubjectLine = config.introEmailSubjectLine;
		this.introEmailBasicsShowBio = config.introEmailBasicsShowBio;
		this.introEmailBasicsShowLocation = config.introEmailBasicsShowLocation;
		this.introEmailBasicsShowJobTitle = config.introEmailBasicsShowJobTitle;
		this.introEmailBasicsShowCompany = config.introEmailBasicsShowCompany;
		this.introEmailBasicsShowEducation =
			config.introEmailBasicsShowEducation;
		this.introEmailBasicsShowDegrees = config.introEmailBasicsShowDegrees;
		this.introEmailBasicsShowLinkedin = config.introEmailBasicsShowLinkedin;
		this.introEmailBasicsShowTwitter = config.introEmailBasicsShowTwitter;
		this.introEmailBasicsShowPersonalSite =
			config.introEmailBasicsShowPersonalSite;
		this.introEmailBasicsShowPronouns = config.introEmailBasicsShowPronouns;
		this.introEmailBasicsShowPhoneNumber =
			config.introEmailBasicsShowPhoneNumber;
		this.automatedConnectionsEnabled = config.automatedConnectionsEnabled;
		this.aiAssistantConnectionsEnabled =
			config.aiAssistantConnectionsEnabled;
		this.directoryConnectionsEnabled = config.directoryConnectionsEnabled;
		this.openAiAssistantInstructions = config.openAiAssistantInstructions;
		this.slackIntroOpeningMessageExample1 =
			config.slackIntroOpeningMessageExample1;
		this.slackIntroOpeningMessageExample2 =
			config.slackIntroOpeningMessageExample2;
		this.slackIntroOpeningMessageExample3 =
			config.slackIntroOpeningMessageExample3;
		this.slackIntroMiddleMessageExample1 =
			config.slackIntroMiddleMessageExample1;
		this.slackIntroMiddleMessageExample2 =
			config.slackIntroMiddleMessageExample2;
		this.slackIntroMiddleMessageExample3 =
			config.slackIntroMiddleMessageExample3;
		this.slackIntroNextStepsMessageExample1 =
			config.slackIntroNextStepsMessageExample1;
		this.slackIntroNextStepsMessageExample2 =
			config.slackIntroNextStepsMessageExample2;
		this.slackIntroNextStepsMessageExample3 =
			config.slackIntroNextStepsMessageExample3;
		this.displaySchedulerInSlackIntro = config.displaySchedulerInSlackIntro;
		this.displaySchedulerInIntroRequest =
			config.displaySchedulerInIntroRequest;
	}

	/**
	 * Updates the loading state of the config
	 * @param loading
	 */
	@action
	private setLoading(loading: boolean) {
		this.loading = loading;
	}
}
