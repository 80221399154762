import {
	action,
	computed,
	makeObservable,
	observable,
	ObservableMap,
	ObservableSet
} from 'mobx';
import posthog from 'posthog-js';
import { RestApiClient } from '../../models/api/rest/restApiClientModel';
import { ApiErrorResponse } from '../../models/api/rest/types';
import { INotificationData } from '../intros/types';
import { IDynamicQuestionModel } from '../../models/survey/question/dynamic/dynamic-question';
import { SurveyQuestionTypeEnum } from '../../models/survey/question/types';
import {
	IEditSurveyQuestionParams,
	IEditSurveyQuestionWeightParams,
	MemberProfilePathNamesEnum,
	MemberProfileTabs,
	IUpdateMemberProfileParams,
	ProposerResponseActionEnum,
	IChangeEventRegistrationParams
} from './types';
import { IMatch } from '../../models/match/match';
import {
	IConnectionDataModel,
	PendingConnectionStatusEnum
} from '../../data-models/response/rest/users/connections/UsersConnectionsGetResponse';
import { IOptInPreviewData } from '../../data-models/response/rest/match/preview/MatchPreviewGetResponse';
import { IBaseResponse } from '../../data-models/response/BaseResponse';
import debounce from 'lodash.debounce';
import { combineLatest, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ISelectOption } from '../shared/select/intros-select';
import { awsS3Client } from '../../models/api/s3/aws-s3-client-model';
import {
	deleteMemberAuthCookies,
	getMemberAuthCookie,
	setMemberAuthCookie
} from '../shared/utils/cookies';
import {
	AuthService,
	MemberProfileModel,
	GetMemberRecommendations,
	MatchingUpdate,
	MatchRequestsService,
	MembersService,
	OAuth2UrlResponse,
	QuestionFilterRowModel,
	RecommendationsService,
	ResetVerificationPostResponse,
	SearchOption,
	SearchParametersGetResponse,
	SendVerificationPostResponse,
	SortDirection,
	Sorting,
	UserAvailabilityMap,
	UserMemberships,
	UsersService,
	VerifyEmailPostResponse,
	ClubMembership,
	GetClubMembershipsResponse
} from '../../api-client';
import { GroupModel } from '../../models/group/group';
import { UserProfile } from '../../models/user/UserProfile';
import {
	GroupMember,
	IGroupMember
} from '../../models/groupMember/GroupMember';
import { IMatchingRoundModel } from '../../models/group/matching-round/matching-round';
import { IntentionalSurveyQuestionModel } from '../../models/survey/question/dynamic/radio/multiselect/intentional/intentional-survey-question';
import { IFiltersUpdate } from '../shared/filters/filters';
import { IGroupSelectionData } from './views/desktop/navigation/desktop/nav-bar';
import { JoinSurveyModel } from '../../models/survey/join/join-survey';
import { AppState } from '../../AppModel';

/**
 * Enumizes the diffent relationship statuses that affect the group member tile displaya
 */
export enum RelationshipStatusEnum {
	HaveNotMet,
	HaveMet,
	RequestSent,
	RequestReceived
}
export class MemberProfileViewModel {
	/**
	 * Includes question indexes being edited by the user
	 */
	@observable
	public editedIndices: number[] = [];

	/**
	 * Notification to display on the intros page
	 */
	// TODO: Notification logic should be an enhancer!!!
	@observable
	public notification: INotificationData;

	/**
	 * The optional request message this user writes when requesting a match
	 */
	@observable
	public matchRequestMessage: string = '';

	/**
	 * Users cookie that is obtained from the page url
	 */
	public cookie: string;

	/**
	 * True when the leave group modal should initialize to open
	 */
	public leaveGroup: boolean;

	/**
	 * Show saved success notification
	 */
	@observable
	public saveChangesSuccess: boolean;

	/**
	 * True when the link is invalid
	 */
	@observable
	public showReauthenticationModal: boolean = false;

	/**
	 * Track the tab that we are currently on
	 */
	@observable
	public tab: MemberProfileTabs;
	/**
	 * The unique id of the match which the member is rescheduling
	 */
	@observable
	public matchHashForRescheduleModal: string;
	/**
	 * True when we should show the review modal
	 */
	@observable
	public showMatchPreviewModal: boolean = false;
	/**
	 * Match to display in the preview modal
	 */
	@observable
	public matchForPreviewModal: IConnectionDataModel;
	/**
	 * True when we should show the "Request More" button on the UI
	 */
	@observable
	public showRequestMoreButton: boolean = true;
	/**
	 * Preview data for the match to display in the modal
	 */
	@observable
	public matchPreview: IOptInPreviewData;
	/**
	 * Member whose profile information we are viewing
	 */
	@observable
	public memberToView: number;
	/**
	 * Search options to allow the user
	 */
	@observable
	public searchOptions: SearchOption[];
	/**
	 * Location filter options for this group
	 */
	@observable
	public locations: string[];
	/**
	 * Company filter options for this group
	 */
	@observable
	public companies: string[];
	/**
	 * The number of approvals required before we will allow a user to opt-in
	 */
	@observable
	public approvalsRequired: number;
	/**
	 * Filter rows for the directory
	 */
	@observable.ref
	filters: QuestionFilterRowModel[] = [];
	/**
	 * Filtered locations for the directory
	 */
	@observable
	locationFilters: string[] = [];
	/**
	 * Filtered companies for the directory
	 */
	@observable
	companyFilters: string[] = [];
	/**
	 * Directory search term
	 */
	@observable
	public directorySearchTerm: string = '';
	/**
	 * History search term
	 */
	@observable
	public historySearchTeam: string = '';
	/**
	 * Check if Google connection is already established
	 */
	@observable
	public googleIsConnected: boolean;
	/**
	 * Check if Facebook connection is already established
	 */
	@observable
	public facebookIsConnected: boolean;
	/**
	 * Check if Twitter connection is already established
	 */
	@observable
	public twitterIsConnected: boolean;
	/**
	 * Check if LinkedIn connection is already established
	 */
	@observable
	public linkedInIsConnected: boolean;

	/**
	 * True if opt-ins are closed for the week
	 */
	@observable
	public optInClosed: boolean = false;

	/**
	 * True when the opt-in request has completed
	 */
	@observable
	public optInRequestComplete: boolean = false;

	/**
	 * The number of additional approvals this user needs, if any
	 */
	@observable
	public numApprovalsRequired: number;

	/**
	 * True when we are loading the directory
	 */
	@observable
	public loadingDirectory: boolean = true;
	/**
	 * True when we are loading experiences for this user
	 */
	@observable
	public loadingExperiences: boolean = true;
	/**
	 * Track whether or not the user has responded to the proposal request
	 */
	@observable
	public proposalResponseCompleted: boolean = false;
	/**
	 * The message to display to the user after the proposal response has been processed
	 */
	@observable
	public proposalResponseMessage: string;

	/**
	 * Time that the opted-in / out matching round will take place at
	 */
	@observable
	public matchingRoundTime: Date;
	/**
	 * True if the user opted-in, False otherwise
	 */
	@observable
	public didOptIn: boolean;
	/**
	 * The page of the directory we are currently viewing
	 */
	@observable
	public directoryPage: number = 0;

	/**
	 * Track any error message that was received after attempting to verify an email
	 */
	@observable
	public emailVerificationErrorMessage: string;

	/**
	 * True if the member profile is authenticated via OTP
	 */
	@observable
	public otpAuthenticated: boolean = false;

	/**
	 * The display id of the group that the user is authenticated for
	 */
	@observable
	public otpGroupDisplayId: string;

	/**
	 * True if we should disable navigation
	 */
	@observable
	public disableNavigation: boolean = false;

	/**
	 * Records the edited freetext questions
	 * Maps answer ids to answer values
	 */
	@observable
	private editedFreetextQuestions: ObservableMap<
		number,
		string
	> = new ObservableMap<number, string>();
	/**
	 * Records the edited likert questions
	 * Maps answer ids to answer values
	 */
	@observable
	private editedLikertQuestions: ObservableMap<
		number,
		number
	> = new ObservableMap<number, number>();
	/**
	 * Records the edited algo params
	 * Maps question ids to weights
	 */
	@observable
	private editedAlgoParamWeights: ObservableMap<
		number,
		number
	> = new ObservableMap<number, number>();
	/**
	 * Records removed survey answer ids
	 */
	@observable
	private removedSurveyAnswers: ObservableSet<number> = new ObservableSet<number>();
	/**
	 * Records added survey answer ids
	 */
	@observable
	private addedSurveyAnswers: ObservableSet<number> = new ObservableSet<number>();
	/**
	 * Display id of the group
	 */
	@observable
	public displayId: string;

	/**
	 * True if recommendations have been loaded from the server
	 */
	@observable
	public recommendationsLoaded: boolean = false;
	/**
	 * Profile recommendations for this group member
	 */
	@observable
	public recommendationUids: number[];
	/**
	 * Required recommendations to approve
	 */
	@observable
	public requiredRecommendations: number = 0;
	/**
	 * The action that the proposer is taking (for proposer modal)
	 */
	@observable
	public proposerResponseAction: ProposerResponseActionEnum;

	/**
	 * Profile for this user
	 */
	@observable
	public profile: UserProfile;

	/**
	 * The group member that is currently being viewed
	 */
	@observable
	public member: IGroupMember;

	/**
	 * True when in mobile mode
	 */
	@observable
	public showMobile: boolean;

	/**
	 * Map of match id to match model
	 */
	@observable
	private _connections: ObservableMap<
		number,
		IConnectionDataModel
	> = new ObservableMap<number, IConnectionDataModel>();
	/**
	 * Map of match id to opt-in preview
	 */
	@observable
	private _matchPreviews: ObservableMap<
		number,
		IOptInPreviewData
	> = new ObservableMap<number, IOptInPreviewData>();
	/**
	 * Map of uids to member data models
	 */
	@observable
	private _members: ObservableMap<
		number,
		MemberProfileModel
	> = new ObservableMap<number, MemberProfileModel>();

	@observable
	private _memberships: ObservableMap<
		string,
		ClubMembership
	> = new ObservableMap<string, ClubMembership>();

	@observable
	private _groups: ObservableMap<string, GroupModel> = new ObservableMap<
		string,
		GroupModel
	>();

	/**
	 * True if the search process has started
	 */
	@observable
	private searchInProgress: boolean = false;

	/**
	 * Not undefined if we should open this users first review on load
	 */
	private openReviewOnLoad: string;

	/**
	 * Tab to target when the protected navigation modal loads
	 */
	@observable
	private protectedNavigationTargetTab: MemberProfileTabs;

	/**
	 * True if we should disable protected navigation
	 * Use to stop navigation protection after completing actions on a protected page
	 */
	@observable
	private disableProtectedNavigation: boolean = false;

	/**
	 * Allow certain functionality to wait until groups have loaded to fire
	 */
	private observeGroupsHaveLoaded: ReplaySubject<undefined> = new ReplaySubject();

	constructor() {
		makeObservable(this);
		// set show mobile
		this.showMobile = window.innerWidth < 1024;
		// create url search params object
		const urlParams = new URLSearchParams(window.location.search);

		// Set the value of otpAuthenticated from the url params
		this.otpAuthenticated = urlParams.get('otp_auth') === 'true';
		// Set the value of otpGroupDisplayId from the url params
		this.otpGroupDisplayId = urlParams.get(
			'otp_group_display_id'
		) as string;
		// Set the value of disableNavigation from the url params
		this.disableNavigation = urlParams.get('disable_navigation') === 'true';

		// set the cookie of this user
		this.cookie = urlParams.get('cookie') as string;
		// first backup - use state param from Auth0 with another product
		if (this.cookie === null) {
			// backup => fetch cookie from state parameter
			this.cookie = urlParams.get('state') as string;
		}
		// second backup - use stored cookie
		if (this.cookie === null) {
			this.cookie = getMemberAuthCookie();
		}

		// set reauthenticate modal required if cookie is Null
		if (!this.cookie || this.cookie === null) {
			this.setShowReauthenticationModal(true);
		}

		/**
		 * NOTE: Limit requests to the server if cookie is undefined
		 * We don't need to make server requests, just redirect the user
		 */
		if (this.cookie && this.cookie !== null) {
			this.setUser();

			// set leaveGroup according to url params
			this.leaveGroup = urlParams.get('leave_group') === 'true';
			// show proposer response modal according to url params
			this.setProposerResponseAction(
				urlParams.get(
					'proposer_response_action'
				) as ProposerResponseActionEnum
			);
			// set whether we should open the modal on load
			this.openReviewOnLoad = urlParams.get('open');
			// determine proper page
			const location: string = window.location.pathname.split('/')[1];
			// gets called once, return email in here
			this.loadUserGroups();

			// track that the user went to the profile
			posthog.capture('Member Profile Opened');
			switch (location) {
				case MemberProfilePathNamesEnum.Home:
					this.tab = MemberProfileTabs.Home;
					posthog.capture('Home Page Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.Profile:
					this.tab = MemberProfileTabs.Profile;
					posthog.capture('Profile Page Visited', {
						source: 'web'
					});
					break;
				case MemberProfilePathNamesEnum.Preferences:
					this.tab = MemberProfileTabs.Preferences;
					// track member navigation
					posthog.capture('Preferences Page Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.History:
					// update url
					this.tab = MemberProfileTabs.Activity;
					posthog.capture('Activity Page Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.ApproveIntros:
					this.getMemberMatchPreviews();
					this.tab = MemberProfileTabs.ApproveIntros;
					// track navigation to approvals page
					posthog.capture('Approvals Page Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.AIChat:
					this.observeGroupsHaveLoaded.pipe(first()).subscribe(() => {
						if (this.selectedGroup?.openAiAssistantEnabled) {
							this.tab = MemberProfileTabs.AIChat;
							// track navigation to approvals page
							posthog.capture('AI Chat Page Visited', {
								source: 'web',
								group: this.groupName
							});
						} else {
							// this.tab = MemberProfileTabs.Profile;
							this.moveTabs(MemberProfileTabs.Profile);
						}
					});
					break;

				case MemberProfilePathNamesEnum.OptIn:
					this.tab = MemberProfileTabs.OptIn;
					// track navigation to single opt-in page
					posthog.capture('Opt-In Page Visited', {
						source: 'web',
						group: this.groupName
					});
					break;

				case MemberProfilePathNamesEnum.Directory:
					this.observeGroupsHaveLoaded.pipe(first()).subscribe(() => {
						this.loadMemberDirectory();
						this.loadGroupSearchParameters();
						this.loadUpcomingMatches();
					});
					this.tab = MemberProfileTabs.Directory;
					// track navigation to member directory
					posthog.capture('Directory Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.Experiences:
					this.observeGroupsHaveLoaded.pipe(first()).subscribe(() => {
						this.loadUpcomingMatches();
					});
					this.tab = MemberProfileTabs.Upcoming;
					posthog.capture('Upcoming Events Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.Requests:
					this.tab = MemberProfileTabs.Requests;
					// track navigation to requests page
					posthog.capture('Match Requests Page Visited', {
						source: 'web',
						group: this.groupName
					});
					break;
				case MemberProfilePathNamesEnum.Match:
					if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Meet
					) {
						this.tab = MemberProfileTabs.Meet;
						// track navigation to the meeting page
						posthog.capture('Meeting Visited', {
							source: 'web',
							group: this.groupName
						});
					} else if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Schedule
					) {
						this.tab = MemberProfileTabs.Schedule;
						// track navigation to the scheduler
						posthog.capture('Match Scheduler Visited', {
							source: 'web',
							group: this.groupName
						});
					} else if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Reschedule
					) {
						this.tab = MemberProfileTabs.Reschedule;
						// track navigation to reschedule
						posthog.capture('Reschedule Page Visited', {
							source: 'web',
							group: this.groupName
						});
					} else if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Cancel
					) {
						this.tab = MemberProfileTabs.Cancel;
						// track navigation to cancel the match
						posthog.capture('Cancel Match Visited', {
							source: 'web',
							group: this.groupName
						});
					} else {
						window.history.pushState(
							{},
							null as any,
							MemberProfilePathNamesEnum.Profile +
								'?cookie=' +
								this.cookie
						);
						this.tab = MemberProfileTabs.Profile;
					}
					break;
				default:
					window.history.pushState(
						{},
						null as any,
						MemberProfilePathNamesEnum.Profile +
							'?cookie=' +
							this.cookie
					);
					this.tab = MemberProfileTabs.Profile;
					break;
			}

			// if suspended, redirect to profile tab
			this.observeGroupsHaveLoaded.pipe(first()).subscribe(() => {
				this.validateAccessToPage();
			});

			// handle a Microsoft provided auth code
			const authCode: string = urlParams.get('code') as string;
			if (authCode !== null) {
				if (authCode[0] === 'M') {
					this.profile
						?.authenticateOutlook(authCode)
						.subscribe(() => {
							this.setNotification({
								type: 'success',
								message:
									'Successfully linked Microsoft Outlook account.'
							});
							// capture action
							posthog.capture(
								'Linked Microsoft Outlook Account',
								{
									source: 'web',
									uid: this.userId
								}
							);
						});
				} else {
					this.profile?.authenticateGoogle(authCode).subscribe(() => {
						this.setNotification({
							type: 'success',
							message:
								'Successfully linked Google Calendar account.'
						});
						// capture action
						posthog.capture('Linked Google Account', {
							source: 'web',
							uid: this.userId
						});
					});
				}
			}
			// clear all search params except for mrid, opt_in, and path_shortcut
			const urlSearchParams = new URLSearchParams(window.location.search);
			const _mrid = urlSearchParams.get('mrid');
			const _optIn = urlSearchParams.get('opt_in');
			const _pathShortcut = urlSearchParams.get('path_shortcut');
			const _reviewMatch = urlSearchParams.get('review_match');
			const _disableNavigation = urlSearchParams.get(
				'disable_navigation'
			);
			// Construct query string if any params exist that we want to propagate
			let queryString = undefined;
			if (
				_mrid ||
				_optIn ||
				_pathShortcut ||
				_reviewMatch ||
				_disableNavigation
			) {
				queryString = '?';
				if (_mrid) {
					queryString += `mrid=${_mrid}&`;
				}
				if (_optIn) {
					queryString += `opt_in=${_optIn}&`;
				}
				if (_pathShortcut) {
					queryString += `path_shortcut=${_pathShortcut}&`;
				}
				if (_reviewMatch) {
					queryString += `review_match=${_reviewMatch}&`;
				}
				if (_disableNavigation) {
					queryString += `disable_navigation=${_disableNavigation}`;
				}
			}

			window.history.pushState(
				{},
				document.title,
				window.location.pathname + (queryString || '')
			);
		} else {
			// determine proper page
			const location: string = window.location.pathname.split('/')[1];
			switch (location) {
				case MemberProfilePathNamesEnum.Match:
					if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Meet
					) {
						this.tab = MemberProfileTabs.Meet;
						// track navigation to the meeting page
						posthog.capture('Meeting Visited', {
							source: 'web',
							group: this.groupName
						});
					} else if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Schedule
					) {
						this.tab = MemberProfileTabs.Schedule;
						// track navigation to the scheduler
						posthog.capture('Match Scheduler Visited', {
							source: 'web',
							group: this.groupName
						});
					} else if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Reschedule
					) {
						this.tab = MemberProfileTabs.Reschedule;
						// track navigation to reschedule
						posthog.capture('Reschedule Page Visited', {
							source: 'web',
							group: this.groupName
						});
					} else if (
						window.location.pathname.split('/')[3] ===
						MemberProfilePathNamesEnum.Cancel
					) {
						this.tab = MemberProfileTabs.Cancel;
						// track navigation to cancel the match
						posthog.capture('Cancel Match Visited', {
							source: 'web',
							group: this.groupName
						});
					} else {
						// redirect user to sign in page
						this.setShowReauthenticationModal(true);
					}
					break;
				default:
					// redirect user to sign in page
					this.setShowReauthenticationModal(true);
					break;
			}
		}
	}

	/**
	 * Change the selected group in the member profile
	 * @param displayId
	 */
	@action
	public changeGroup(displayId: string) {
		this.observeGroupsHaveLoaded.pipe(first()).subscribe(() => {
			const membership: ClubMembership = this._memberships.get(displayId);
			// set the data that we have stored
			this.cookie = membership.cookie;
			if (membership.cookie) {
				setMemberAuthCookie(membership.cookie);
				RestApiClient.setUser(membership.cookie);
			}
			this.setDisplayId(membership.displayId);
			this._groups.get(membership.displayId).loadConfig();

			// load new data for the group
			// get called when change groups or nav pages
			this.member = new GroupMember({
				displayId: this.displayId,
				model: {
					uid: this.userId,
					name: this.userName,
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email
				} as any
			} as any);
			this.member.getProfile();
			this.loadReviewMatchesData();
			// validate access to the page
			this.validateAccessToPage();
			// load page specific data
			switch (this.tab) {
				case MemberProfileTabs.ApproveIntros:
					this.getMemberMatchPreviews();
					break;
				case MemberProfileTabs.Directory:
					this.loadMemberDirectory();
					this.loadGroupSearchParameters();
					this.loadUpcomingMatches();
					break;
				case MemberProfileTabs.Upcoming:
					this.loadUpcomingMatches();
					break;
				default:
					break;
			}
			// set group
			posthog.group('club', this.selectedGroup.gid.toString());
			// track that the event occurred
			posthog.capture('Profile Group Changed', {
				source: 'web',
				group: this.groupName
			});

			if (this.tab === MemberProfileTabs.Home) {
				this.loadMemberRecommendations();
			}
		});
	}

	/**
	 * Calculate the relationship status between 2 members
	 */
	public calculateRelationshipStatus(name: string): RelationshipStatusEnum {
		// return have met if they have a past match
		if (
			this.pastMatches.filter((val: IMatch) => val.matchName === name)
				.length > 0
		) {
			return RelationshipStatusEnum.HaveMet;
		}
		// return request sent if this user has requested
		if (
			this.sentRequests.filter((val: IMatch) => val.matchName === name)
				.length > 0
		) {
			return RelationshipStatusEnum.RequestSent;
		}
		// return request received if this user is pending
		if (
			this.pendingRequests.filter((val: IMatch) => val.matchName === name)
				.length > 0
		) {
			return RelationshipStatusEnum.RequestReceived;
		}
		// return have not met otherwise
		return RelationshipStatusEnum.HaveNotMet;
	}

	/**
	 * Get the group currently selected
	 */
	@computed
	public get selectedGroup(): GroupModel {
		return this._groups.get(this.displayId);
	}

	/**
	 * Name of the selected group
	 */
	@computed
	public get groupName(): string {
		return this.selectedGroup?.name;
	}

	/**
	 * Logo for the selected group
	 */
	@computed
	public get logo(): string {
		return this.selectedGroup?.imagePath;
	}

	/**
	 * True if the directory is enabled
	 */
	@computed
	public get directoryEnabled(): boolean {
		return this.selectedGroup
			? this.selectedGroup.isDirectoryEnabled
			: true;
	}

	/**
	 * Custom directory link for the group
	 */
	@computed
	public get customDirectoryLink(): string {
		return this.selectedGroup?.customDirectoryLink;
	}

	/**
	 * True when we should allow users to choose weights
	 */
	@computed
	public get usersChooseWeights(): boolean {
		return this.selectedGroup?.usersChooseWeights;
	}

	/**
	 * Number of pages of members in this group
	 */
	@computed
	public get numActiveMemberPages(): number {
		return this.selectedGroup?.numberOfSearchedMemberPages;
	}

	/**
	 * Unique id for the user
	 */
	@computed
	public get userId(): number {
		return this.profile?.uid;
	}

	/**
	 * Username fo the user
	 */
	@computed
	public get userName(): string {
		return this.profile?.firstName + ' ' + this.profile?.lastName;
	}

	/**
	 * Firstname of the user
	 */
	@computed
	public get firstName(): string {
		return this.profile?.firstName;
	}

	/**
	 * Lastname of the user
	 */
	@computed
	public get lastName(): string {
		return this.profile?.lastName;
	}

	/**
	 * Pronouns of the user
	 */
	@computed
	public get pronouns(): string {
		return this.profile?.pronouns;
	}

	/**
	 * Email of the user
	 */
	@computed
	public get email(): string {
		return this.profile?.email;
	}

	/**
	 * Email of the user
	 */
	@computed
	public get unverifiedEmail(): string {
		return this.profile?.unverifiedEmail;
	}

	/**
	 * Discord tag for this user
	 */
	@computed
	public get discord(): string {
		return this.profile?.discord;
	}

	/**
	 * Phone number for this user
	 */
	@computed
	public get phone(): string {
		return this.profile?.phone;
	}

	/**
	 * Users company
	 */
	@computed
	public get company(): string {
		return this.profile?.company;
	}

	/**
	 * Users title
	 */
	@computed
	public get title(): string {
		return this.profile?.title;
	}

	/**
	 * Location of the member
	 */
	@computed
	public get location(): string {
		return this.profile?.location;
	}

	/**
	 * LinkedIn of the member
	 */
	@computed
	public get linkedIn(): string {
		return this.profile?.linkedIn;
	}

	/**
	 * Twitter of the member
	 */
	@computed
	public get twitter(): string {
		return this.profile?.twitter;
	}

	/**
	 * Alma mater of the user
	 */
	@computed
	public get education(): string {
		return this.profile?.education;
	}

	/**
	 * Degrees of the member
	 */
	@computed
	public get degrees(): string {
		return this.profile?.degrees;
	}

	/**
	 * Personal site of the member
	 */
	@computed
	public get personalSite(): string {
		return this.profile?.personalSite;
	}

	/**
	 * Profile photo for this user
	 */
	@computed
	public get profilePhoto(): string {
		return this.profile?.profilePhoto;
	}

	/**
	 * Timezone chosen by this user
	 */
	@computed
	public get userTimezone(): string {
		return this.profile?.timezone;
	}

	/**
	 * User's daily availability
	 */
	@computed
	public get dailyAvailability(): UserAvailabilityMap {
		return this.profile?.availability || {};
	}

	/**
	 * True if this user has authenticated their google account with Intros
	 */
	@computed
	public get googleAuthed(): boolean {
		return this.profile?.googleAuthed;
	}

	/**
	 * True if this user has authenticated their outlook account with Intros
	 */
	@computed
	public get outlookAuthed(): boolean {
		return this.profile?.outlookAuthed;
	}

	/**
	 * Users introduction
	 */
	@computed
	public get introduction(): string {
		return this.member?.bio;
	}

	/**
	 * User activation/deactivation button
	 */
	@computed
	public get active(): boolean {
		return this.member?.active;
	}

	/**
	 * True if automatic bookings are enabled by the user
	 */
	@computed
	public get enableAutomaticBookings(): boolean {
		return this.member?.enableAutomaticBookings;
	}

	/**
	 * True if available hours are enabled by the user
	 */
	@computed
	public get enableAvailableHours(): boolean {
		return this.member?.enableAvailableHours;
	}

	/**
	 * True fi we should show the Home tab
	 */
	@computed
	public get showHomeTab(): boolean {
		return this.tab === MemberProfileTabs.Home;
	}

	/**
	 * True if we should show the Profile Tab
	 */
	@computed
	public get showProfileTab(): boolean {
		return this.tab === MemberProfileTabs.Profile;
	}

	/**
	 * True if we should show the preferences tab
	 */
	@computed
	public get showPreferencesTag(): boolean {
		return this.tab === MemberProfileTabs.Preferences;
	}

	/**
	 * True if we should show the Directory Tab
	 */
	@computed
	public get showDirectoryTab(): boolean {
		return this.tab === MemberProfileTabs.Directory;
	}

	/**
	 * True if we should show the AI Chat Tab
	 */
	@computed
	public get showAIChatTab(): boolean {
		return this.tab === MemberProfileTabs.AIChat;
	}

	/**
	 * True if the AI Chat tab is disabled
	 */
	@computed
	public get disableAiChatTab(): boolean {
		return !this.selectedGroup?.openAiAssistantEnabled;
	}

	/**
	 * True if we should show the Experiences Tab
	 */
	@computed
	public get showUpcomingTab(): boolean {
		return this.tab === MemberProfileTabs.Upcoming;
	}

	/**
	 * True if we should show the Meet Tab
	 */
	@computed
	public get showMeetTab(): boolean {
		return this.tab === MemberProfileTabs.Meet;
	}

	/**
	 * True if we should show the Schedule Tab
	 */
	@computed
	public get showScheduleTab(): boolean {
		return this.tab === MemberProfileTabs.Schedule;
	}

	/**
	 * True if we should show the Reschedule Tab
	 */
	@computed
	public get showRescheduleTab(): boolean {
		return this.tab === MemberProfileTabs.Reschedule;
	}

	/**
	 * True if we should show the Cancel Tab
	 */
	@computed
	public get showCancelTab(): boolean {
		return this.tab === MemberProfileTabs.Cancel;
	}

	/**
	 * True if we should show the History Tab
	 */
	@computed
	public get showActivityTab(): boolean {
		return this.tab === MemberProfileTabs.Activity;
	}

	/**
	 * True if we should show the Approve Intros Tab
	 */
	@computed
	public get showApproveIntrosTab(): boolean {
		return this.tab === MemberProfileTabs.ApproveIntros;
	}

	/**
	 * True if we should show the Single Opt-In Tab
	 */
	@computed
	public get showOptInTab(): boolean {
		return this.tab === MemberProfileTabs.OptIn;
	}

	/**
	 * True if we should show the proposal response tab
	 */
	@computed
	public get showProposalResponseTab(): boolean {
		return this.tab === MemberProfileTabs.ProposalResponse;
	}

	/**
	 * True if we should show the alert banner for this user profile
	 */
	@computed
	public get showOptInBanner(): boolean {
		return this.upcomingMatches.length > 0;
	}

	/**
	 * True if we are signed up for the next match
	 */
	@computed
	public get optedIn(): boolean {
		return (
			this.upcomingMatches.length > 0 &&
			this.upcomingMatches[0].signUps.includes(this.profile?.uid)
		);
	}

	/**
	 * True if we should show the protected navigation modal
	 */
	@computed
	public get showProtectedNavigationModal(): boolean {
		return this.protectedNavigationTargetTab !== undefined;
	}

	/**
	 * Map the filtered locations to ISelectOption
	 */
	@computed
	public get selectedLocations(): ISelectOption[] {
		return this.locationFilters.map((location: string) => ({
			value: location,
			label: location
		}));
	}

	/**
	 * Map the filtered companies to ISelectOption
	 */
	@computed
	public get selectedCompanies(): ISelectOption[] {
		return this.companyFilters.map((company: string) => ({
			value: company,
			label: company
		}));
	}

	@computed
	public get membersPageSubheading(): string {
		// return no subheading if loading directory
		if (this.searchInProgress || this.loadingDirectory) {
			return '';
		}
		const noun: string =
			'member' +
			(this.selectedGroup?.numberOfSearchedMembers !== 1 ? 's' : '');
		return `${this.selectedGroup?.numberOfSearchedMembers} ${noun}`;
	}

	/**
	 * True when we should disable the save changes button
	 */
	@computed
	public get shouldDisableSubmitButton(): boolean {
		return (
			this.editedFreetextQuestions.size < 1 &&
			this.editedLikertQuestions.size < 1 &&
			this.removedSurveyAnswers.size < 1 &&
			this.addedSurveyAnswers.size < 1 &&
			this.editedAlgoParamWeights.size < 1
		);
	}

	@computed
	public get memberGroups(): IGroupSelectionData[] {
		return Array.from(this._memberships?.values()).map(
			(val: ClubMembership) => {
				return {
					displayId: val.displayId,
					name: val.name,
					logo: val.image
				};
			}
		);
	}

	/**
	 * Past matches for this user
	 */
	@computed
	public get pastMatches(): IMatch[] {
		return this.profile?.pastMatches;
	}

	/**
	 * Unrated past matches for this user
	 */
	@computed
	public get unratedMatches(): IMatch[] {
		let matches: IMatch[] = this.pastMatches?.filter(
			(match: IMatch) => !match.reviewed
		);
		if (this.historySearchTeam.length > 2) {
			matches = matches.filter((match: IMatch) =>
				match.matchName
					.toLowerCase()
					.includes(this.historySearchTeam.toLowerCase())
			);
		}
		return matches;
	}

	/**
	 * Previously rated matches for this user
	 */
	@computed
	public get ratedMatches(): IMatch[] {
		let matches: IMatch[] = this.pastMatches?.filter(
			(match: IMatch) => match.reviewed
		);
		if (this.historySearchTeam.length > 2) {
			matches = matches.filter((match: IMatch) =>
				match.matchName
					.toLowerCase()
					.includes(this.historySearchTeam.toLowerCase())
			);
		}
		return matches;
	}

	/**
	 * Members to display in the directory
	 */
	@computed
	public get activeMembers(): IGroupMember[] {
		return this.selectedGroup?.orderedGroupMembers;
	}

	/**
	 * Recommended users
	 */
	@computed
	public get recommendations(): IGroupMember[] {
		return this.recommendationUids
			? this.recommendationUids.map((uid: number) =>
					this.selectedGroup?.getMemberInfo(uid)
			  )
			: [];
	}

	/**
	 * Upcoming matches in this group
	 */
	@computed
	public get upcomingMatches(): IMatchingRoundModel[] {
		return this.selectedGroup?.futureAdHocMatches || [];
	}

	/**
	 * First upcoming match in the group
	 */
	@computed
	public get nextUpcomingMatch(): IMatchingRoundModel {
		return this.upcomingMatches.length > 0
			? this.upcomingMatches[0]
			: undefined;
	}

	/**
	 * Number of pending requests
	 */
	@computed
	public get numPendingRequests(): number {
		return this.pendingRequests?.length || 0;
	}

	/**
	 * True if we should show the proposer response modal
	 */
	@computed
	public get showProposerResponseModal(): boolean {
		return !!this.proposerResponseAction;
	}

	/**
	 * Sent Requests
	 */
	@computed
	private get sentRequests(): IMatch[] {
		return this.profile?.sentRequests;
	}

	/**
	 * Pending Requests
	 */
	@computed
	private get pendingRequests(): IMatch[] {
		return this.profile?.pendingRequests;
	}

	/**
	 * Approved Requests
	 */
	@computed
	private get approvedRequests(): IMatch[] {
		return this.profile?.approvedRequests;
	}

	/**
	 * Connections for this user
	 */
	@computed
	private get connections(): IConnectionDataModel[] {
		return Array.from(this._connections.values());
	}

	/**
	 * List of match approvals for the /approve-intros page
	 */
	@computed
	public get matchPreviewsForApprovalsPage(): IOptInPreviewData[] {
		return Array.from(
			this._matchPreviews.values()
		).sort((first: IOptInPreviewData, second: IOptInPreviewData) =>
			second.status === PendingConnectionStatusEnum.OtherMemberApproved &&
			first.status !== PendingConnectionStatusEnum.OtherMemberApproved
				? 1
				: -1
		);
	}

	/**
	 * Unreviewed connections for this user
	 */
	@computed
	public get unreviewedConnections(): IConnectionDataModel[] {
		return this.connections
			?.filter(
				(connection: IConnectionDataModel) =>
					connection.status ===
						PendingConnectionStatusEnum.BothPending ||
					connection.status ===
						PendingConnectionStatusEnum.OtherMemberApproved
			)
			.sort((first: IConnectionDataModel, second: IConnectionDataModel) =>
				second.status ===
					PendingConnectionStatusEnum.OtherMemberApproved &&
				first.status !== PendingConnectionStatusEnum.OtherMemberApproved
					? 1
					: -1
			);
	}

	/**
	 * True if we should disable and hide the directory tab
	 */
	@computed
	public get disableDirectoryTab(): boolean {
		return !this.directoryEnabled && !this.customDirectoryLink;
	}

	/**
	 * True if we should disable and hide the requests tab
	 */
	@computed
	public get disableRequestsTab(): boolean {
		return false;
	}

	@computed
	public get filteredSurvey(): IDynamicQuestionModel[] {
		return (
			this.selectedGroup?.joinSurveyModel?.dynamicQuestionsForProfile ||
			[]
		);
	}

	/**
	 * Search options to allow the user
	 */
	@computed
	public get searchableQuestions(): IDynamicQuestionModel[] {
		return (this.selectedGroup?.userDefinedJoinSurvey || []).filter(
			(value: IDynamicQuestionModel) =>
				!(
					value.type === SurveyQuestionTypeEnum.Intentional &&
					typeof (value as IntentionalSurveyQuestionModel)
						.linkedQuestion === 'boolean'
				) && value.type !== SurveyQuestionTypeEnum.Freetext
		);
	}

	/**
	 * True if the user is suspended from the group
	 * Only allow access to preferences and profile if true
	 */
	@computed
	public get suspended(): boolean {
		if (this.displayId) {
			return this.member?.suspended;
		}
		return false;
	}

	/**
	 * Full survey model of the selected group
	 */
	@computed
	public get fullSurveyModel(): JoinSurveyModel {
		return this.selectedGroup?.joinSurveyModel;
	}

	/**
	 * The group member profile to view in the
	 */
	@computed
	public get profileToView(): IGroupMember {
		return this.getGroupMemberData(this.memberToView);
	}

	/**
	 * Update the value of the match request message
	 */
	@action
	public updateMatchRequestMessage(val: string) {
		this.matchRequestMessage = val;
	}

	/**
	 * Reset the value of the match request message
	 */
	@action
	public resetMatchRequestMessage() {
		this.matchRequestMessage = '';
	}

	/**
	 * Removes user from group
	 */
	public handleActiveStatusChange(status?: boolean) {
		// User rejoins group (set active = 1)
		this.member.changeActiveStatus(status).subscribe(
			() => {
				if (status) {
					this.setNotification({
						type: 'success',
						message: `Accepted invite. Welcome!`
					});
				} else {
					this.setNotification({
						type: 'success',
						message: `Successfully ${
							this.active ? 'left' : 'rejoined'
						} group.`
					});
				}
				// capture action
				posthog.capture(
					`Member ${
						status
							? 'Accepted Invite to'
							: this.active
							? 'rejoined the'
							: 'left the'
					} Group`,
					{
						source: 'web',
						group: this.selectedGroup?.name
					}
				);
			},
			() => {
				// notify the user of suspension
				this.setNotification({
					type: 'error',
					message:
						'Membership suspended. Please contact your admin if this is a mistake.'
				});
			}
		);
	}

	/**
	 * Get the google auth url for the member
	 */
	public getGoogleAuthUrl() {
		// capture request
		posthog.capture('Requesting Google Auth link', {
			group: this.selectedGroup?.name,
			source: 'web'
		});
		// request link
		RestApiClient.serviceRequest<OAuth2UrlResponse>({
			generator: () =>
				AuthService.getGoogleOauthUrlUsersAuthGoogleGet({
					redirectUri:
						window.location.origin + window.location.pathname,
					state: this.cookie
				})
		}).subscribe((data: OAuth2UrlResponse) => {
			// capture request
			posthog.capture('Redirected to Google Auth link', {
				group: this.selectedGroup?.name,
				source: 'web'
			});
			// redirect use to the google auth url
			window.location.replace(data.url);
		});
	}

	/**
	 * Constructs and submits the survey changes for the user
	 */
	public submitSurveyChanges() {
		// body for our request - TODO: lets type this eventually
		const body: MatchingUpdate = {
			addedSurveyAnswers: Array.from(this.addedSurveyAnswers.values()),
			removedSurveyAnswers: Array.from(
				this.removedSurveyAnswers.values()
			),
			editedFreetextQuestionIds: Array.from(
				this.editedFreetextQuestions.keys()
			),
			editedFreetextAnswers: Array.from(
				this.editedFreetextQuestions.values()
			),
			editedLikertQuestionIds: Array.from(
				this.editedLikertQuestions.keys()
			),
			editedLikertAnswers: Array.from(
				this.editedLikertQuestions.values()
			),
			editedAlgoParamIds: Array.from(this.editedAlgoParamWeights.keys()),
			editedAlgoParamWeights: Array.from(
				this.editedAlgoParamWeights.values()
			)
		};
		this.member
			?.update({
				matching: body
			})
			.subscribe(() => {
				this.setNotification({
					type: 'success',
					message: 'Successfully updated your survey.'
				});
				this.resetEditSurveyInputs();
				// capture action
				posthog.capture('Updated Matching Preferences', {
					source: 'web',
					group: this.selectedGroup?.name
				});
			});
	}

	public uploadProfilePhoto(file: File) {
		// upload this file
		awsS3Client
			.uploadFile({
				file: file,
				cookie: this.cookie,
				profilePhoto: true
			})
			.then((response: unknown | IBaseResponse) => {
				const parsed = response as IBaseResponse;
				if (parsed.success) {
					// update photo
					this.updateMemberProfile({
						profilePhoto: (parsed as any).path
					});
				}
				// capture action
				posthog.capture('Profile Photo Uploaded', {
					source: 'web'
				});
			});
	}

	/**
	 * Sends an email verification email for a user to change their email. Providing an email will set the user's unverifiedEmail. If an email is not provided, then we will resend a verification email to the unverifiedEmail which should have been set previously.
	 */
	public sendEmailVerification(email?: string) {
		this.profile.sendEmailVerification(email).subscribe(
			(data: SendVerificationPostResponse) => {
				this.setNotification({
					type: 'success',
					message: `Successfully sent a verification email to ${this.profile.unverifiedEmail}.`
				});
			},
			(error: ApiErrorResponse) => {
				this.setEmailVerificationErrorMessage(error.body.detail);
			}
		);
	}

	/**
	 * Sends a request to clear out the user's unverified email and unverifiedEmailHash
	 */
	public resetEmailVerification() {
		// get preview for this userId
		this.profile.resetEmailVerification().subscribe(
			(data: ResetVerificationPostResponse) => {
				this.setNotification({
					type: 'success',
					message: `Successfully reset email verification.`
				});
			},
			(error: ApiErrorResponse) => {
				this.setNotification({
					type: 'error',
					message: `There was an error resetting email verification.`
				});
			}
		);
	}

	/**
	 * Lock in the user's new email by verifying it with a emailVerificationHash
	 */
	public verifyNewEmail(hash: string) {
		this.profile.verifyNewEmail(hash).subscribe(
			(data: VerifyEmailPostResponse) => {
				this.setNotification({
					type: 'success',
					message: `Successfully changed email.`
				});
			},
			(error: ApiErrorResponse) => {
				this.setNotification(
					{
						type: 'error',
						message: error.body.detail
					},
					7500
				);
			}
		);
	}

	/**
	 * Clear the value of emailVerificationErrorMessage
	 */
	public clearEmailVerificationErrorMessage() {
		this.setEmailVerificationErrorMessage(undefined);
	}

	/**
	 * Returns group member data associated with the passed user id
	 * @param uid
	 * @returns
	 */
	public getGroupMemberData(uid: number): IGroupMember {
		return this.selectedGroup?.getMemberInfo(uid);
	}

	/**
	 * submit opt-in decision for match
	 * TODO: we need to handle both approval-intros and modal here
	 */
	@action
	public submitOptInDecision(cid: number, decision: boolean) {
		// capture request
		posthog.capture(
			`Submitted Opt-In Decision: ${decision ? 'Accepted' : 'Rejected'}`,
			{
				group: this.selectedGroup?.name,
				cid: cid,
				source: 'web'
			}
		);
		// submit true for approval or false for rejection
		RestApiClient.serviceRequest({
			generator: () =>
				MatchRequestsService.approveOrRejectMatchRequestUsersMatchRequestsCidPut(
					{
						cid: cid,
						uid: this.userId,
						requestBody: {
							approve: decision
						}
					}
				)
		}).subscribe(() => {
			if (this._connections.has(cid)) {
				// get index of current match preview
				const idx: number = this.unreviewedConnections.findIndex(
					(conn: IConnectionDataModel) => conn.cid === cid
				);
				// get current connection
				const connection: IConnectionDataModel = this
					.unreviewedConnections[idx];
				// if approved by other member, update its local status
				if (
					decision &&
					connection.status ===
						PendingConnectionStatusEnum.OtherMemberApproved
				) {
					connection.status = PendingConnectionStatusEnum.Approved;
				} else if (
					decision &&
					connection.status ===
						PendingConnectionStatusEnum.BothPending
				) {
					// this match was just self approved
					connection.status =
						PendingConnectionStatusEnum.SelfApproved;
				} else {
					// otherwise, we won't be showing this anymore on UI
					this._connections.delete(connection.cid);
				}

				// if index is still valid, show the next unreviewed connection
				if (idx < this.unreviewedConnections.length) {
					this.showPreviewForMatch(
						this.unreviewedConnections[idx].cid
					);
					// if no more unreviewed connection, close modal
				} else if (!this.unreviewedConnections.length) {
					this.closeMatchPreviewModal();
					// if at end, loop around to start again
				} else {
					this.showPreviewForMatch(this.unreviewedConnections[0].cid);
				}
			}
			/**
			 * If this is in our match previews, lets update the approvals required
			 */
			if (this._matchPreviews.has(cid)) {
				// remove from match previews
				this._matchPreviews.delete(cid);
				// if we accepted, lets decrement required approvals
				if (decision) {
					this.setApprovalsRequired(this.approvalsRequired - 1);
					// when approvals required hits 0, lets opt-in the user
					if (this.approvalsRequired === 0) {
						// TODO:
					}
				}
				// if we still require more but are out of matches, request more
				if (
					this._matchPreviews.size < 1 &&
					this.approvalsRequired > 0
				) {
					this.getMemberMatchPreviews();
				}
			}
		});
	}

	/**
	 * Get the review for a specific match and show in the review modal
	 */
	@action
	public showPreviewForMatch(cid: number) {
		this.matchForPreviewModal = this._connections.get(
			cid
		) as IConnectionDataModel;
		if (!this.matchForPreviewModal) {
			return;
		}
		// capture action
		posthog.capture('Loading Match Preview for a Proposed Connection', {
			group: this.selectedGroup?.name,
			connection_id: cid,
			source: 'web'
		});
		// get preview for this connection
		RestApiClient.serviceRequest({
			generator: () =>
				MatchRequestsService.getMatchRequestPreviewUsersMatchRequestsCidGet(
					{
						uid: this.profile?.uid,
						cid: cid
					}
				)
		}).subscribe((data: any) => {
			this.setMatchPreview(data.preview);
		});
		// show match preview modal
		this.showMatchPreviewModal = true;
	}

	/**
	 * Callback to trigger on successful proposal of times
	 * @param uid
	 */
	@action
	public onSuccessfulProposal() {
		this.loadReviewMatchesData();
		this.resetMatchRequestMessage();
	}

	/**
	 * closes the match preview modal
	 */
	@action
	public closeMatchPreviewModal() {
		this.showMatchPreviewModal = false;
	}

	/**
	 * Move between tabs, or launch a modal to ensure that the user wants to navigate away
	 * @param tab
	 */
	@action
	public executeProtectedNavigation(tab: MemberProfileTabs) {
		if (
			(this.tab === MemberProfileTabs.Meet ||
				this.tab === MemberProfileTabs.Schedule ||
				this.tab === MemberProfileTabs.Reschedule) &&
			!this.disableProtectedNavigation
		) {
			this.protectedNavigationTargetTab = tab;
		} else {
			this.moveTabs(tab);
		}
	}

	/**
	 * Finish protected navigation and set target tab to undefined
	 */
	@action
	public confirmNavigation() {
		this.moveTabs(this.protectedNavigationTargetTab);
		this.protectedNavigationTargetTab = undefined;
	}

	/**
	 * Cancel navigation by setting protectedNavigationTarget to undefined
	 */
	@action
	public cancelNavigation() {
		this.protectedNavigationTargetTab = undefined;
	}

	/**
	 * Update the tab we are on of the member profile
	 * TODO: we can improve network request saving checks here
	 * @param tab
	 */
	@action
	public moveTabs(tab: MemberProfileTabs) {
		switch (tab) {
			case MemberProfileTabs.Home:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.Home}${window.location.search}`
				);

				if (!this.recommendationsLoaded) {
					this.loadMemberRecommendations();
				}
				// track member navigation
				posthog.capture('Home Page Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.AIChat:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.AIChat}${window.location.search}`
				);
				// track member navigation
				posthog.capture('AI Chat Page Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.Profile:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.Profile}${window.location.search}`
				);
				// track member navigation
				posthog.capture('Profile Page Visited', {
					source: 'web'
				});
				break;
			case MemberProfileTabs.Preferences:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.Preferences}${window.location.search}`
				);
				// load necessary data
				this.selectedGroup?.getJoinSurvey(false, () => {
					// assign responses to survey
					this.selectedGroup.joinSurveyModel.assignResponses(
						this.member?.surveyMap
					);
					this.selectedGroup?.joinSurveyModel?.loadConditionalLogic();
				});
				// track member navigation
				posthog.capture('Preferences Page Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.Directory:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.Directory}${window.location.search}`
				);
				this.selectedGroup?.refreshAllMembers();
				// load member directory
				this.changeDirectoryPage(0);
				// load search options
				if (!this.searchOptions || this.searchOptions?.length < 1) {
					this.loadGroupSearchParameters();
				}

				// track navigation to member directory
				posthog.capture('Directory Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.Upcoming:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.Experiences}${window.location.search}`
				);

				// load group events
				if (this.selectedGroup?.futureAdHocMatches.length < 1) {
					this.loadUpcomingMatches();
				}

				// track navigation to upcoming events
				posthog.capture('Upcoming Events Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.Requests:
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.Requests}${window.location.search}`
				);
				// track navigation to requests page
				posthog.capture('Match Requests Page Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.Activity:
				// update url
				window.history.pushState(
					{},
					null as any,
					`/${MemberProfilePathNamesEnum.History}${window.location.search}`
				);
				// track member navigation
				posthog.capture('Activity Page Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
			case MemberProfileTabs.ApproveIntros:
				this.getMemberMatchPreviews();
				// track navigation to approvals page
				posthog.capture('Approvals Page Visited', {
					source: 'web',
					group: this.groupName
				});
				break;
		}
		this.tab = tab;
	}

	@action
	public setShowReauthenticationModal(show: boolean) {
		if (show) {
			deleteMemberAuthCookies();
		}
		this.showReauthenticationModal = show;
	}

	@action
	public showSaveSuccessNotification() {
		this.saveChangesSuccess = true;
		setTimeout(() => {
			// After 3 seconds set the saveChangesSuccess value to false
			this.saveChangesSuccess = false;
		}, 3000);
	}

	public updateMemberProfile(params: IUpdateMemberProfileParams) {
		// update profile
		this.profile?.updateProfile(params).subscribe(() => {
			this.showSaveSuccessNotification();
			// capture action
			posthog.capture('User Profile Updated', {
				source: 'web',
				uid: this.userId,
				params
			});
		});
		if (
			(params.introduction && params.introduction !== this.member?.bio) ||
			params.enableAutomaticBookings !== undefined ||
			params.enableAvailableHours !== undefined
		) {
			this.member
				?.update({
					introduction: params.introduction,
					enableAutomaticBookings: params.enableAutomaticBookings,
					enableAvailableHours: params.enableAvailableHours
				})
				.subscribe(() => {
					this.resetEditSurveyInputs();
					// capture action
					posthog.capture('Updated Scheduling Preferences', {
						source: 'web',
						group: this.selectedGroup?.name,
						introduction: params.introduction,
						enableAutomaticBookings: params.enableAutomaticBookings,
						enableAvailableHours: params.enableAvailableHours
					});
				});
		}
	}

	/**
	 * Update this members availability
	 * @param availability
	 */
	public updateMemberAvailability(availability: UserAvailabilityMap): void {
		this.profile?.updateAvailability(availability).subscribe(() => {
			this.showSaveSuccessNotification();
			// capture action
			posthog.capture('Member Availability Updated', {
				source: 'web',
				group: this.selectedGroup?.name,
				uid: this.userId
			});
		});
	}

	public changeEventRegistration(params: IChangeEventRegistrationParams) {
		// find matching round
		const match: IMatchingRoundModel = this.upcomingMatches.find(
			(match) => match.id === params.eid
		);
		// register for event
		match?.changeEventRegistration(params.signUp).subscribe(() => {
			// notify user
			this.setNotification({
				type: 'success',
				message: params.signUp
					? 'Signed up for match!'
					: 'Opted-Out of match.'
			});
		});
	}

	/**
	 * Records editing of a survey question
	 */
	@action
	public editQuestion(params: IEditSurveyQuestionParams) {
		switch (params.type) {
			// if freetext, map the question id to the answer
			case SurveyQuestionTypeEnum.Freetext:
				this.editedFreetextQuestions.set(
					params.id,
					params.value as string
				);
				break;
			// if likert, mapt the question id to the answer value
			case SurveyQuestionTypeEnum.Likert:
				this.editedLikertQuestions.set(
					params.id,
					params.value as number
				);
				break;
			// if radio, multiselect, or intentional - follow logic here
			case SurveyQuestionTypeEnum.Radio:
			case SurveyQuestionTypeEnum.Multiselect:
			case SurveyQuestionTypeEnum.Intentional:
				// if the user 'adds' an answer
				if (params.action === 'added') {
					// delete from removed answers if previously removed
					if (this.removedSurveyAnswers.has(params.value as number)) {
						this.removedSurveyAnswers.delete(
							params.value as number
						);
						// otherwise, add to addedSurveyAnswers
					} else {
						this.addedSurveyAnswers.add(params.value as number);
					}
					// if the user 'removes' an answer
				} else {
					// delete from added answers if previously added
					if (this.addedSurveyAnswers.has(params.value as number)) {
						this.addedSurveyAnswers.delete(params.value as number);
						// otherwise, add to removed answers
					} else {
						this.removedSurveyAnswers.add(params.value as number);
					}
				}
				break;
		}
	}

	@action
	public editAlgoParamWeight(weightParams: IEditSurveyQuestionWeightParams) {
		this.editedAlgoParamWeights.set(weightParams.id, weightParams.value);
	}

	/**
	 * Updates the edit mode
	 */
	@action
	public enterEditMode(qid: number) {
		this.editedIndices = [];
		this.editedIndices.push(qid);
	}

	/**
	 *
	 * Resets the edit mode of all questions after the user saves changes
	 */
	@action
	public exitEditMode() {
		this.editedIndices = [];
	}

	/**
	 * Changes the directory search term
	 * @param val
	 */
	@action
	public changeDirectorySearchTerm(val: string) {
		this.searchInProgress = true;
		this.directorySearchTerm = val?.toLowerCase() || '';
		this.applyFiltersAndSearch({});
	}

	/**
	 * Changes the directory page
	 * @param page
	 */
	@action
	public changeDirectoryPage(page: number) {
		// set directory page
		this.setDirectoryPage(page);
		// load members
		this.loadMemberDirectory();
	}

	/**
	 * Update and apply new filters
	 * @param options
	 * @param values
	 */
	@action
	public applyFiltersAndSearch = debounce((update: IFiltersUpdate) => {
		// set local variables
		if (update.locations) {
			this.locationFilters = update.locations;
		}
		if (update.companies) {
			this.companyFilters = update.companies;
		}
		if (update.filterRows) {
			this.filters = update.filterRows.filter(
				(row: QuestionFilterRowModel) => !!row.qid
			);
		}
		// set directory loading
		this.setDirectoryLoading(true);
		// reset directory page
		this.setDirectoryPage(0);
		// clear all members
		this.selectedGroup?.refreshAllMembers();
		// apply the filters
		this.selectedGroup
			?.searchMembers({
				query: this.directorySearchTerm,
				filters: this.filters,
				locations: this.locationFilters,
				companies: this.companyFilters,
				active: true,
				page: this.directoryPage
			})
			.subscribe(() => {
				// set that directory is loaded and search is no longer in progress
				this.setDirectoryLoading(false);
				this.searchInProgress = false;
			});
	}, 500);

	/**
	 * Sets the notification on the page to the matching data
	 * @param data
	 */
	@action
	public setNotification(
		data: INotificationData | undefined,
		duration: number = 2500
	) {
		this.notification = data as INotificationData;
		if (this.notification) {
			setTimeout(() => this.setNotification(undefined), duration);
		}
	}

	/**
	 * Resets all inputs used in editing the survey
	 */
	@action
	public resetEditSurveyInputs() {
		this.editedFreetextQuestions.clear();
		this.editedLikertQuestions.clear();
		this.editedAlgoParamWeights.clear();
		this.removedSurveyAnswers.clear();
		this.addedSurveyAnswers.clear();
	}

	/**
	 * Send a request to the server to create a match approval for a given recommendations
	 */
	public respondToMemberRecommendation(
		uid: number,
		approved: boolean,
		likes?: Map<number, string>
	) {
		// capture invite
		posthog.capture('Responding to a member recommendation', {
			group: this.selectedGroup?.name,
			approved: approved,
			source: 'web'
		});
		// respond to recommendation
		RestApiClient.serviceRequest({
			generator: () =>
				RecommendationsService.respondToRecommendationClubsDisplayIdMembersMemberIdRecommendationsPut(
					{
						displayId: encodeURIComponent(this.displayId),
						memberId: this.userId,
						requestBody: {
							approved: approved,
							likes:
								likes?.entries() &&
								Object.fromEntries(likes.entries()),
							target: uid
						}
					}
				)
		}).subscribe();
	}

	/**
	 * Convert the proposer_response_action url query param (string) to an enum
	 */
	@action
	public setProposerResponseAction(action: ProposerResponseActionEnum) {
		if (
			!action ||
			!Object.values(ProposerResponseActionEnum).includes(action)
		) {
			this.proposerResponseAction = undefined;
			return;
		}
		this.proposerResponseAction = action;
	}

	/**
	 * Set whether to disable protected navigation
	 * @param disable
	 */
	@action
	public setDisableProtectedNavigation(disable: boolean) {
		this.disableProtectedNavigation = disable;
	}

	/**
	 * Update the value of show mobile
	 * @param show
	 */
	@action
	public setShowMobile(show: boolean) {
		this.showMobile = show;
	}

	/**
	 * Load settings required for group
	 */
	private loadUserGroups() {
		this.profile
			.loadMemberGroups()
			.subscribe((data: GetClubMembershipsResponse) => {
				data.data.forEach((group: ClubMembership) => {
					this._memberships.set(group.displayId, group);
					this._groups.set(group.displayId, new GroupModel(group));
					if (group.cookie === this.cookie) {
						this._groups.get(group.displayId).loadConfig();
						this.setDisplayId(group.displayId);
						// set group
						posthog.group(
							'club',
							this.selectedGroup.gid.toString()
						);
						// track that the event occurred
						posthog.capture('Profile Group Set', {
							source: 'web',
							group: this.groupName
						});

						// fire observer
						this.selectedGroup.configInitialized.subscribe(() => {
							this.observeGroupsHaveLoaded.next();
						});
						if (this.tab === MemberProfileTabs.Home) {
							this.loadMemberRecommendations();
							// create url search params object
							const urlParams = new URLSearchParams(
								window.location.search
							);
							// Send API request to respond to proposer request
							// this.sendProposerRequestAPIResponse(
							// 	urlParams.get('proposer_request_id'),
							// 	group.displayId
							// );
						}
					}
				});
			});
	}

	/**
	 * Load data required for the review match tab
	 */
	private loadReviewMatchesData() {
		this.profile?.loadMatches().subscribe();
	}

	/**
	 * Get match previews for this member
	 */
	private getMemberMatchPreviews() {
		// capture request
		posthog.capture('Loaded Match Previews for Member', {
			group: this.selectedGroup?.name,
			source: 'web'
		});
		// create url search params object
		const urlParams = new URLSearchParams(window.location.search);
		// set the number of approvals required for the approvals page
		this.setApprovalsRequired(parseInt(urlParams.get('approvals')));
		RestApiClient.serviceRequest({
			generator: () =>
				MatchRequestsService.getMatchRequestsUsersMatchRequestsGet({
					uid: this.profile?.uid
				})
		}).subscribe((data: any) => {
			// set default parameters
			this.setDisplayId(data.displayId);
			// map connections to previews - include mid in response
			this.setApprovalsMatchPreviews(data.previews);
			// set approvals required
			this.setApprovalsRequired(data.required);
			// if approvals are 0, then opt-in user
			if (this.approvalsRequired === 0) {
				// TODO:
			}
		});
	}

	/**
	 * Get member directory data
	 */
	private loadMemberDirectory() {
		this.selectedGroup
			?.searchMembers({
				page: this.directoryPage,
				active: true,
				query:
					this.directorySearchTerm?.length > 0
						? this.directorySearchTerm
						: undefined,
				filters: this.filters.length > 0 ? this.filters : undefined,
				locations:
					this.locationFilters.length > 0
						? this.locationFilters
						: undefined,
				companies:
					this.companyFilters.length > 0
						? this.companyFilters
						: undefined
			})
			.subscribe(() => {
				// set that directory is loaded and search is no longer in progress
				this.setDirectoryLoading(false);
				this.searchInProgress = false;
			});
	}

	/**
	 * Get group search parameters
	 */
	private loadGroupSearchParameters() {
		RestApiClient.serviceRequest<SearchParametersGetResponse>({
			generator: () =>
				MembersService.getSearchParametersClubsDisplayIdMembersSearchGet(
					{
						displayId: encodeURIComponent(this.displayId)
					}
				)
		}).subscribe((data: SearchParametersGetResponse) => {
			this.setSearchOptions(data.options);
			this.setLocationFilters(data.locations);
			this.setCompanyFilters(data.companies);
		});
	}

	/**
	 * Load upcoming events for the current group
	 */
	private loadUpcomingMatches() {
		this.setExperiencesLoading(true);
		this.selectedGroup.getMatchingRounds().subscribe(() => {
			this.setExperiencesLoading(false);
			this.selectedGroup?.futureAdHocMatches.forEach(
				(match: IMatchingRoundModel) => {
					match.loadSignUps();
				}
			);
		});
	}

	/**
	 * Get member recommendations
	 */
	private loadMemberRecommendations() {
		this.setRecommendationsLoaded(false);
		RestApiClient.serviceRequest<GetMemberRecommendations>({
			generator: () =>
				MembersService.getMemberRecommendationsClubsDisplayIdMembersMemberIdRecommendationsGet(
					{
						displayId: encodeURIComponent(this.displayId),
						memberId: this.profile?.uid
					}
				)
		}).subscribe((data: GetMemberRecommendations) => {
			this.setRecommendations(data.recommendations);
		});
	}

	/**
	 * Update whether the directory is loading
	 * @param loading
	 */
	@action
	private setDirectoryLoading(loading: boolean) {
		this.loadingDirectory = loading;
	}

	/**
	 * Update whether experiences are loading for this user
	 * @param loading
	 */
	@action
	private setExperiencesLoading(loading: boolean) {
		this.loadingExperiences = loading;
	}

	/**
	 * Set the display id of the group
	 */
	@action
	private setDisplayId(displayId: string) {
		this.displayId = displayId;
	}

	/**
	 * Set the number of approvals required for this user to be opted-in for the week
	 * @param approvals
	 */
	@action
	public setApprovalsRequired(approvals: number) {
		this.approvalsRequired = approvals;
	}

	/**
	 * Set the match preview
	 */
	@action
	public setMatchPreview(preview: IOptInPreviewData) {
		this.matchPreview = preview;
	}

	/**
	 * Set the member whose profile we will view
	 * @param uid
	 */
	@action
	public setMemberToView(uid: number) {
		// set member to view
		this.memberToView = uid;
		// load member profile
		if (this.memberToView) {
			this.getGroupMemberData(this.memberToView).getProfile();
		}
	}

	/**
	 * Set matches for the /approve-intros page
	 * @param matches
	 */
	@action
	private setApprovalsMatchPreviews(matches: IOptInPreviewData[]) {
		matches.forEach((match: IOptInPreviewData) => {
			this._matchPreviews.set(match.cid, match);
		});
	}

	/**
	 * Set the value of emailVerificationErrorMessage
	 */
	@action
	private setEmailVerificationErrorMessage(message: string) {
		this.emailVerificationErrorMessage = message;
	}

	/**
	 * Set the recommendations to loading or loaded
	 * @param
	 */
	@action
	private setRecommendationsLoaded(loaded: boolean) {
		this.recommendationsLoaded = loaded;
	}

	/**
	 * Set the recommendations array
	 * @param recs
	 */
	@action
	private setRecommendations(recs: number[]) {
		this.recommendationUids = recs;
	}

	/**
	 * Set the filter options for this group
	 * @param options
	 */
	@action
	private setSearchOptions(options: SearchOption[]) {
		this.searchOptions = options;
	}

	/**
	 * Set the location filter options for this group
	 * @param options
	 */
	@action
	private setLocationFilters(locations: string[]) {
		this.locations = locations;
	}

	/**
	 * Set the companyfilter options for this group
	 * @param options
	 */
	@action
	private setCompanyFilters(companies: string[]) {
		this.companies = companies;
	}

	/**
	 * Set the page of the directory that we are currently serving
	 * @param page
	 */
	@action
	private setDirectoryPage(page: number) {
		this.directoryPage = page;
	}

	/**
	 * Validate the member is allowed to be on the current page
	 */
	@action
	private validateAccessToPage() {
		if (
			this.suspended ||
			(this.disableDirectoryTab &&
				this.tab === MemberProfileTabs.Directory) ||
			(this.disableRequestsTab && this.tab === MemberProfileTabs.Requests)
		) {
			this.moveTabs(MemberProfileTabs.Profile);
		}
	}

	/**
	 * Set the user from the cookie
	 */
	@action
	public setUserFromCookie(cookie: string) {
		this.cookie = cookie;

		this.setUser();
	}

	/**
	 * Set the user from the cookie
	 */
	@action
	private setUser() {
		// refresh login timer on this user to keep them logged in for 30 days
		setMemberAuthCookie(this.cookie);

		// set cookie on the Rest API Client Model
		RestApiClient.setUser(this.cookie);

		// continue with login
		this.profile = new UserProfile({ cookie: this.cookie });

		// Show the reauthentication modal if the profile fails to load
		this.profile.profileLoadFailed.subscribe(() => {
			this.setShowReauthenticationModal(true);
		});

		const urlParams = new URLSearchParams(window.location.search);

		combineLatest([
			this.observeGroupsHaveLoaded,
			this.profile.profileLoaded
		])
			.pipe(first())
			.subscribe(() => {
				// identify user with posthog
				posthog.identify(this.userId.toString(), {
					uid: this.userId,
					email: this.email,
					name: this.userName
				});
				// get called when change groups or nav pages
				this.member = new GroupMember({
					displayId: this.displayId,
					model: {
						uid: this.userId,
						name: this.userName,
						firstName: this.firstName,
						lastName: this.lastName,
						email: this.email
					} as any
				} as any);
				this.member.getProfile();
				// determine whether to accept invitation
				const acceptInvite = urlParams.get('invitation');
				if (!!acceptInvite) {
					this.handleActiveStatusChange(true);
				}
				// validate page access
				this.validateAccessToPage();
				// get join survey
				this.member.profileLoaded.subscribe(() => {
					this.selectedGroup.getJoinSurvey(false, () => {
						// assign responses to survey
						this.selectedGroup.joinSurveyModel.assignResponses(
							this.member?.surveyMap
						);
						// Load conditional logic if we are initializing the member profile on the preferences page
						if (
							window.location.pathname.split('/')[1] ===
							MemberProfilePathNamesEnum.Preferences
						) {
							this.selectedGroup?.joinSurveyModel.loadConditionalLogic();
						}
					});
				});
				// load rest of data
				this.loadReviewMatchesData();

				// Check if the user is attempting to change/verify their email
				const emailVerificationHash = urlParams.get(
					'email_state'
				) as string;

				if (emailVerificationHash) {
					this.verifyNewEmail(emailVerificationHash);
				}
			});
	}
}
