import {
	action,
	computed,
	makeObservable,
	observable,
	ObservableMap
} from 'mobx';
import posthog from 'posthog-js';
import { Observable, of, Subject } from 'rxjs';
import { IErrorResponse } from '../../data-models/response/error/ErrorResponse';
import { RestApiClient } from '../api/rest/restApiClientModel';
import { HttpMethodEnum, MemberTagModel } from '../api/rest/types';
import { IDynamicQuestionModel } from '../survey/question/dynamic/dynamic-question';
import { SurveyClassModel } from '../survey/survey';
import { GroupConfig, IGroupConfig } from './config/group-config';
import { IGroupIdentificationData } from './types';
import { IGroupAdmin } from '../../data-models/response/rest/groups/admins/GroupAdminsGetResponse';
import { CustomDataUsageEnum } from '../../data-models/shared/customData/types';
import { CustomData, ICustomDataModel } from '../customData/custom-data-model';
import { AppState } from '../../AppModel';
import {
	GroupMember,
	IGroupMember,
	IGroupMemberParams
} from '../groupMember/GroupMember';
import {
	IEventMatchesWrapper,
	IMatchesByWeekData
} from '../../data-models/response/rest/groups/matches/GroupMatchesGetResponse';
import { catchError, map } from 'rxjs/operators';
import { CommunicationModeEnum } from '../../data-models/shared/matchingParams/types';
import { GroupMemberInfoEnum } from './types';
import { GroupSetupEnum } from '../../data-models/response/rest/groups/setup/GroupSetupGetResponse';
import { formatTimezone } from '../../utils/formatTimeForEvent';
import { GroupAccess, IGroupAccess } from './access/group-access';
import { IGroupInsightsFilter } from '../../data-models/response/rest/groups/insights/filters/InsightsFiltersGetResponse';
import {
	IMatchingRoundModel,
	MatchingRoundClassModel
} from './matching-round/matching-round';
import {
	IInviteRoundModel,
	InviteRoundClassModel
} from './invite-round/invite-round';
import { JoinSurveyModel } from '../survey/join/join-survey';
import { IAbstractQuestionModel } from '../survey/question/abstract-question';
import { StaticQuestionModel } from '../survey/question/static/static-question';
import { QuestionVisibilityEnum } from '../survey/question/types';
import {
	AnsweredQuestionModel,
	ChannelsService,
	Club,
	ClubChannel,
	ClubsAccessUpdateRequest,
	ClubsConfigPutRequest,
	ClubsConfigPutResponse,
	ClubSetupModel,
	ClubsMembersGetResponse,
	ClubsMembersPostRequest,
	ClubsMembersPostResponse,
	ClubsService,
	ClubsUsersGetResponse,
	CommunicationTypeEnum,
	ConnectionTypeEnum,
	CreateMatchingRoundRequest,
	CreateQuestionPostRequest,
	CreateQuestionPostResponse,
	DataExportRequest,
	DiscordService,
	EngagementEvent,
	EngagementSearchParameters,
	EngagementService,
	EventDetails,
	FeedbackDetails,
	FeedbackSearchParameters,
	FeedbackService,
	FixedSlidesEnum,
	GetCommunicationChannelsResponse,
	GetEngagementResponse,
	GetFeedbackResponse,
	GetMatchingRoundsResponse,
	GroupExportTypeEnum,
	GroupMemberSortAlgoEnum,
	IcebreakerModel,
	IcebreakersService,
	InsightsSearchRequest,
	InsightsService,
	InsightsSummary,
	InvitesService,
	MatchesSearchParameters,
	MatchesService,
	MatchingRoundModel,
	MatchingRoundsService,
	MatchingRuleEnum,
	MatchOverview,
	MatchReviewSummary,
	MemberExistenceCheck,
	MemberProfileModel,
	MembersCountGetResponse,
	MemberSearchRequest,
	MemberSearchResponse,
	MembersService,
	MessageTypeEnum,
	MessagingData,
	MessagingService,
	PostMatchesSearchResponse,
	ReportsService,
	SearchTypeEnum,
	SurveyTypes,
	TagBasedMatchingLogicService,
	TaggingService,
	TagMatchingRule,
	TeamService,
	UpdateQuestionPutRequest,
	UpdateQuestionPutResponse,
	UserLiteData,
	UsersService,
	CreateCheckoutSessionResponse,
	CreatePortalSessionResponse,
	GetSubscriptionResponse,
	CustomSubscriptionData,
	ClubsInitializeAiChatPostResponse,
	CreateTrialSubscriptionResponse,
	Subscription,
	PortalFlow,
	StripePriceLookupKeyEnum,
	ClubsPutRequest
} from '../../api-client';
import { GetInvitesResponse } from '../../api-client/models/GetInvitesResponse';
import { InviteRoundModel } from '../../api-client/models/InviteRoundModel';
import { ClubsTeamsGetResponse } from '../../api-client/models/ClubsTeamsGetResponse';
import { ClubAdminModel } from '../../api-client/models/ClubAdminModel';
import { GetTagsResponse } from '../../api-client/models/GetTagsResponse';
import { GroupMemberStatusEnum } from '../groupMember/types';

export interface IGroupModel {
	/**
	 * Unique id associated with this group
	 */
	gid: number;
	/**
	 * Unique user-readable display id associated with this group
	 */
	displayId: string;
	/**
	 * Unique group name
	 */
	name: string;
	/**
	 * Join survey questions for this group
	 */
	joinSurvey: IAbstractQuestionModel[];
	/**
	 * Join survey questions that are required for onboarding
	 */
	requiredForOnboardingJoinSurvey: IAbstractQuestionModel[];
	/**
	 * Join survey questions for this group that are dynamically defined by the user
	 */
	userDefinedJoinSurvey: IDynamicQuestionModel[];
	/**
	 * Join survey questions for this group
	 */
	reviewSurvey: IDynamicQuestionModel[];
	/**
	 * Link that future members can use to join the group
	 */
	joinLink: string;
	/**
	 * True when we should require the member bio in the join form for this group
	 */
	includeBioInJoinForm: boolean;
	/**
	 * True when we should require the member location in the join form for this group
	 */
	includeLocationInJoinForm: boolean;
	/**
	 * True when we should require the member job info in the join form for this group
	 */
	includeJobTitleInJoinForm: boolean;
	/**
	 * True when we should require the member company in the join form for this group
	 */
	includeCompanyInJoinForm: boolean;
	/**
	 * True when we should require the member education info in the join form for this group
	 */
	includeEducationInJoinForm: boolean;
	/**
	 * True when we should require the mmember degrees in the join form for this group
	 */
	includeDegreesInJoinForm: boolean;
	/**
	 * True when we should require the member LinkedIn in the join form for this group
	 */
	includeLinkedInInJoinForm: boolean;
	/**
	 * True when we should require the member Twitter in the join form for this group
	 */
	includeTwitterInJoinForm: boolean;
	/**
	 * True when we should require the member personal site in the join form for this group
	 */
	includePersonalSiteInJoinForm: boolean;
	/**
	 * True when we should require the member pronouns in the join form for this group
	 */
	includePronounsInJoinForm: boolean;
	/**
	 * True when we should require the member phone number in the join form for this group
	 */
	includePhoneNumberInJoinForm: boolean;
	/**
	 * Welcome Title for this groups join survey
	 */
	welcomeTitle: string;
	/**
	 * Welcome message for this group
	 */
	welcomeMessage: string;
	/**
	 * Customized wording for the matching times question
	 */
	matchingTimesQuestionWording: string;
	/**
	 * True if personality matching is enabled for this group, false otherwise
	 */
	personalityIntros: boolean;
	/**
	 * True if scheduling intros is enabled for this group
	 */
	scheduleIntros: boolean;
	/**
	 * True if double opt-in is enabled for this group, false otherwise
	 */
	doubleOptIn: boolean;
	/**
	 * True if single opt-in is enabled for this group, false otherwise
	 */
	singleOptIn: boolean;
	/**
	 * True if autoaccepting members is enabled for this group, false otherwise
	 */
	autoaccept: boolean;
	/**
	 * Prefix of welcome email for this group
	 */
	firstEmailTop: string;
	/**
	 * Appendage of welcome email for this group
	 */
	firstEmailBottom: string;
	/**
	 * Prefix of Intro email for this group
	 */
	introEmailTop: string;
	/**
	 * Path to the image for this group
	 */
	imagePath: string;
	/**
	 * Unique discord channel id to send intros updates on discord
	 */
	discordChannelId: number;
	/**
	 * True if tone matching is enabled for this group, false otherwise
	 */
	toneMatching: boolean;
	/**
	 * True if random matching is enabled for this group, false otherwise
	 */
	randomMatch: boolean;
	/**
	 * True if the users choose weights for the algo param questions
	 */
	usersChooseWeights: boolean;
	/**
	 * Algorithm parameters for this group
	 */
	algoParams: ICustomDataModel[];
	/**
	 * Additional data for this group
	 */
	additionalData: ICustomDataModel[];
	/**
	 * Ordered members of this group
	 */
	orderedGroupMembers: IGroupMember[];
	/**
	 * Active members of this group
	 */
	activeMembers: IGroupMember[];
	/**
	 * Uploaded members of this group
	 */
	uploadedMembers: IGroupMember[];
	/**
	 * Inactive members of this group
	 */
	inactiveMembers: IGroupMember[];
	/**
	 * Members that need attention from the admin
	 */
	attentionNeededMembers: IGroupMember[];
	/**
	 * Members invited to this group
	 */
	invitedMembers: IGroupMember[];
	/**
	 * Pending members of this group
	 */
	pendingMembers: IGroupMember[];
	/**
	 * Number of members in this group
	 */
	size: number;
	/**
	 * Number of members that have had their information loaded
	 */
	loadedMembers: number;
	/**
	 * True if there are upcoming matches
	 */
	hasUpcomingMatches: boolean;
	/**
	 * All upcoming matching rounds for the group - ordered by Date ascending (closest events at top)
	 */
	futureAdHocMatches: IMatchingRoundModel[];
	/**
	 * All upcoming invite rounds for the group - ordered by Date ascending (closest events at top)
	 */
	futureInviteRounds: IInviteRoundModel[];
	/**
	 * All past matching rounds for the group - ordered by Date ascending (closest events at top)
	 */
	pastAdHocMatches: IMatchingRoundModel[];
	/**
	 * All past matches for this group - organized by week
	 */
	pastMatches: IMatchesByWeekData[];
	/**
	 * All icebreakers for the group
	 */
	icebreakers: IcebreakerModel[];
	/**
	 * Number of pages of active members in the group
	 */
	numberOfSearchedMemberPages: number;
	/**
	 * Number of active members in the group
	 */
	numberOfActiveMembers: number;
	/**
	 * Number of inactive members in the group
	 */
	numberOfInactiveMembers: number;
	/**
	 * Number of uploaded members in the group
	 */
	numberOfUploadedMembers: number;
	/**
	 * Number of invited members in the group
	 */
	numberOfInvitedMembers: number;
	/**
	 * Number of pending members in the group
	 */
	numberOfPendingMembers: number;
	/**
	 * Number of attention needed members in the group
	 */
	numberOfAttentionNeededMembers: number;
	/**
	 * True when custom data has been modified, false otherwise
	 */
	isCustomDataModified: boolean;
	/**
	 * True when this group is a discord Intro group
	 */
	isDiscordGroup: boolean;
	/**
	 * True when this group is a email Intro group
	 */
	isEmailGroup: boolean;
	/**
	 *
	 * True when this group's intros is paused
	 */
	isIntrosPaused: boolean;
	/**
	 * True if we should include the frequency question in our join survey
	 */
	includeFrequencyQuestion: boolean;
	/**
	 * True if the directory is enabled for this community
	 */
	isDirectoryEnabled: boolean;
	/**
	 * True if the proposer should be CC'd on proposal emails
	 */
	isProposerCCd: boolean;
	/**
	 * True if Slack flow is enabled for this community
	 */
	isSlackIntrosEnabled: boolean;
	/**
	 * True if Discord flow is enabled for this community
	 */
	isDiscordIntrosEnabled: boolean;
	/**
	 * True if this group should use the legacy email template
	 */
	useLegacyWelcomeTemplate: boolean;
	/**
	 * Custom directory link for this group (if provided)
	 */
	customDirectoryLink: string;
	/**
	 * True when user replies to emails should go to the admin
	 */
	replyToAdmin: boolean;
	/**
	 * True when admin should be CC'd on all Intros
	 */
	ccAdmin: boolean;
	/**
	 * Admins display name to show on their emails
	 */
	emailDisplayName: string;
	/**
	 * Title of the calendar invite that is sent between 2 members of a scheduled intro
	 */
	calendarInviteTitle: string;
	/**
	 * Verified email from which to send messages
	 */
	verifiedSender: string;
	/**
	 * True if the verified sender is confirmed
	 */
	verifiedSenderConfirmed: boolean;
	/**
	 * Date this group was created
	 */
	dateCreated: string;
	/**
	 * Color to use for this group's primary buttons
	 */
	buttonColor: string;
	/**
	 * Color to use for this group's primary text
	 */
	textColor: string;
	/**
	 * Color to use for the background of the group's join form
	 */
	joinFormColor: string;
	/**
	 * True if we should use the default join form image as background
	 */
	useJoinFormImage: boolean;
	/**
	 * True if we should disable Intros aesthetic images
	 */
	disableImages: boolean;
	/**
	 * True if we should include the join form promo
	 */
	includeJoinFormPromo: boolean;
	/**
	 * True when the matching form is complete
	 */
	matchingFormComplete: boolean;
	/**
	 * True when the welcome page is complete
	 */
	welcomePageComplete: boolean;
	/**
	 * True when the opt-in preview is complete
	 */
	optInPreviewComplete: boolean;
	/**
	 * True when the intros page is complete
	 */
	introPageComplete: boolean;
	/**
	 * True when the CM Testing page is complete
	 */
	testExperienceComplete: boolean;
	/**
	 * True when the feedback page is complete
	 */
	feedbackPageComplete: boolean;
	/**
	 * True when we are loading custom data
	 */
	isLoadingCustomData: boolean;
	/**
	 * True when we have loaded the config for this group
	 */
	isConfigInitialized: boolean;
	/**
	 * True when we have loaded the tags from the database
	 */
	tagsLoaded: boolean;
	/**
	 * True when we have loaded matches from the database
	 */
	matchesLoaded: boolean;
	/**
	 * True when we have loaded matching rounds from the database
	 */
	matchingRoundsLoaded: boolean;
	/**
	 * True when the group has completed rollout
	 */
	rolloutComplete: boolean;
	/**
	 * True when the group needs discord rollout
	 */
	needsDiscordRollout: boolean;
	/**
	 * Returns the id, displayId, and name of a group
	 */
	groupIdentificationData: IGroupIdentificationData;
	/**
	 * Subject to notify subscribers when group's config has been initialized
	 */
	configInitialized: Subject<any>;
	/**
	 * True if this group should be ineditable
	 */
	readOnly: boolean;
	/**
	 * True when the join survey is loading
	 */
	joinSurveyLoading: boolean;
	/**
	 * True when the review survey is loading
	 */
	reviewSurveyLoading: boolean;
	/**
	 * True when the config is loading
	 */
	configLoading: boolean;
	/**
	 * True when we are loading insights data
	 */
	insightsLoading: boolean;
	/*
	 * Custom welcome instructions used in plaintext welcome emails
	 */
	welcomeInstructions: string;
	/**
	 * Custom signature used in plaintext welcome emails
	 */
	customSignature: string;
	/**
	 * True if welcome email is plain text
	 */
	plainTextWelcome: boolean;
	/**
	 * True if feedback email is plain text
	 */
	plainTextFeedback: boolean;
	/**
	 * True if welcome email is plain text
	 */
	plainTextOptIn: boolean;
	/**
	 * The JSON object for the group's opt in email
	 */
	optInJSON: object;
	/**
	 * The subject line for the group's opt in email
	 */
	optInSubject: string;
	/**
	 * The JSON object for the group's opt out email
	 */
	optOutJSON: object;
	/**
	 * The subject line for the group's opt out email
	 */
	optOutSubject: string;
	/**
	 * The JSON object for the group's welcome email
	 */
	welcomeJSON: object;
	/**
	 * The subject line for the group's welcome email
	 */
	welcomeSubject: string;
	/**
	 * Greater than 0 if we should default this group to auto enroll
	 */
	defaultToAutoEnroll: boolean;
	/**
	 * Heading for a group's auto enroll invite
	 */
	autoEnrollInviteHeading: string;
	/**
	 * Body for a group's auto enroll invite
	 */
	autoEnrollInviteBody: string;
	joinSurveyModel: JoinSurveyModel;
	/**
	 * Heading for a group's general invite
	 */
	generalInviteHeading: string;
	/**
	 * Body for a group's general invite
	 */
	generalInviteBody: string;
	/**
	 * True if introduction email is plain text
	 */
	plainTextIntro: boolean;
	/**
	 * UID of the creator of the group
	 */
	creatorId: number;
	/**
	 * List of all admins for this group
	 */
	admins: ClubAdminModel[];
	/**
	 * List of all matching rules for this group
	 */
	matchingRules: TagMatchingRule[];
	/**
	 * List of all active matching rules
	 */
	activeMatchingRules: TagMatchingRule[];
	/**
	 * List of all active tags for this group
	 */
	tags: MemberTagModel[];
	/**
	 * Preferred timezone for the group
	 */
	timezone: string;
	/**
	 * True if the group is using the matching v2 flow
	 */
	useMatchingV2: boolean;
	/**
	 * True if the matching v2 flow is started (group has enough members)
	 */
	hasGroupKickedOff: boolean;
	/**
	 * True if the group can access zapier integration
	 */
	hasZapierAccess: boolean;
	/**
	 * True if the group can access slack
	 */
	hasSlackAccess: boolean;
	/**
	 * True if the group can allow members to define their own weights
	 */
	hasMemberDefinedWeightsAccess: boolean;
	/**
	 * True if the group can access custom brand colors
	 */
	hasCustomBrandColorsAccess: boolean;
	/**
	 * True if the group can access chatbot
	 */
	hasChatbotAccess: boolean;
	/**
	 * True if the group can access custom directory
	 */
	hasCustomDirectoryAccess: boolean;
	/**
	 * True if the group can access member scheduling
	 */
	hasMemberSchedulingAccess: boolean;
	/**
	 * True if the group can access directory intro requests
	 */
	hasDirectoryIntroRequestsAccess: boolean;
	/**
	 * True if the group can access connection history
	 */
	hasConnectionHistoryAccess: boolean;
	/**
	 * True if the group can access its directory
	 */
	hasDirectoryAccess: boolean;
	/**
	 * True if the group can access internal tagging
	 */
	hasInternalTaggingAccess: boolean;
	/**
	 * True if the group can access conditional matching
	 */
	hasConditionalMatchingAccess: boolean;
	/**
	 * True if the group can access email verification
	 */
	hasEmailVerificationAccess: boolean;
	/**
	 * True if the group can access text notifications
	 */
	hasNotificationAccess: boolean;
	/**
	 * True if the group can access data enrichment
	 */
	hasDataEnrichmentAccess: boolean;
	/**
	 * True if we should include the Intros footer in all group emails
	 */
	includeIntrosFooter: boolean;
	/**
	 * True if group members should have access to intercom support
	 */
	membersHaveIntercomSupport: boolean;
	/**
	 * True if the group admin should have access the identity report
	 */
	canAccessIdentityReport: boolean;
	/**
	 * True if the group admin should have access the expanded member info report
	 */
	canAccessExpandedReport: boolean;
	/**
	 * True if the group admin should have access the survey report
	 */
	canAccessSurveyReport: boolean;
	/**
	 * True if the group admin should have access the insights overview report
	 */
	canAccessOverviewReport: boolean;
	/**
	 * True if the group admin should have access the insights engagement report
	 */
	canAccessEngagementReport: boolean;
	/**
	 * True if the group admin should have access the insights feedback report
	 */
	canAccessFeedbackReport: boolean;
	/**
	 * Welcome message to send to members who join the Slack
	 */
	slackWelcomeMessage: string;
	/**
	 * Opt-In message to send to members on slack
	 */
	slackOptInMessage: string;
	/**
	 * Opt-Out message to send to members on slack
	 */
	slackOptOutMessage: string;
	/**
	 * Intro message to send to members on slack
	 */
	slackIntroMessage: string;
	/**
	 * Opt-In message to send to members on discord
	 */
	discordOptInMessage: string;
	/**
	 * Opt-Out message to send to members on discord
	 */
	discordOptOutMessage: string;
	/**
	 * The time for the upper bound of the group's period of time that member communications can be sent out
	 */
	communicationStartTime: string;
	/**
	 * The time for the lower bound of the group's period of time that member communications can be sent out
	 */
	communicationEndTime: string;
	/**
	 * Formatted version of the group's selected timezone
	 */
	formattedTimezone: string;
	/**
	 * The list of adhoc matching rounds for the group
	 */
	matchingRounds: IMatchingRoundModel[];
	/**
	 * Map of our insights label to the overview insights for that label
	 */
	insightsOverview: ObservableMap<string, InsightsSummary>;
	/**
	 * Map of our insights label to the engagement for that label
	 */
	insightsEngagement: ObservableMap<string, EngagementEvent[]>;
	/**
	 * Map of our insights label to the feedback for that label
	 */
	insightsFeedback: ObservableMap<string, MatchReviewSummary[]>;
	/**
	 * Map of our engagement events to additional info on the event
	 */
	engagementAdditionalInfo: ObservableMap<number, EventDetails[]>;
	/**
	 * Map of our engagement events to additional info on the event
	 */
	feedbackDetails: ObservableMap<number, FeedbackDetails[]>;
	/**
	 * Filters which can be applied on the insights page
	 */
	insightsFilters: IGroupInsightsFilter[];
	/**
	 * True if we should send new slack members the welcome message
	 */
	sendNewSlackMembersWelcomeMessage: boolean;
	/**
	 * True if we should allow multiple channels for this group
	 */
	allowMultipleChannels: boolean;
	/**
	 * True if this group has Zapier connected
	 */
	isZapierConnected: boolean;
	/**
	 * True if we should send members who directly join through zapier an invite message
	 */
	zapierAutoInvite: boolean;
	/**
	 * True if we should automatically enroll members who directly join through zapier
	 */
	zapierAutoEnroll: boolean;
	/**
	 * List of channels allowed for this group
	 */
	configuredChannels: CommunicationTypeEnum[];
	/*
	 * The subject line for the group's intro email
	 */
	introEmailSubjectLine: string;
	/**
	 * True the intro email should include the member's bio
	 */
	introEmailBasicsShowBio: boolean;
	/**
	 * True the intro email should include the member's location
	 */
	introEmailBasicsShowLocation: boolean;
	/**
	 * True the intro email should include the member's job title
	 */
	introEmailBasicsShowJobTitle: boolean;
	/**
	 * True the intro email should include the member's company
	 */
	introEmailBasicsShowCompany: boolean;
	/**
	 * True the intro email should include the member's education
	 */
	introEmailBasicsShowEducation: boolean;
	/**
	 * True the intro email should include the member's degrees
	 */
	introEmailBasicsShowDegrees: boolean;
	/**
	 * True the intro email should include the member's LinkedIn
	 */
	introEmailBasicsShowLinkedin: boolean;
	/**
	 * True the intro email should include the member's Twitter
	 */
	introEmailBasicsShowTwitter: boolean;
	/**
	 * True the intro email should include the member's personal site
	 */
	introEmailBasicsShowPersonalSite: boolean;
	/**
	 * True the intro email should include the member's pronouns
	 */
	introEmailBasicsShowPronouns: boolean;
	/**
	 * True the intro email should include the member's phone number
	 */
	introEmailBasicsShowPhoneNumber: boolean;
	/**
	 * True if automated connections are enabled for this group
	 */
	automatedConnectionsEnabled: boolean;
	/**
	 * True if AI assistant connections are enabled for this group
	 */
	aiAssistantConnectionsEnabled: boolean;
	/**
	 * True if directory connections are enabled for this group
	 */
	directoryConnectionsEnabled: boolean;
	/**
	 * List of all enabled connection types for this group
	 */
	connectionChannels: ConnectionTypeEnum[];
	/**
	 * Matches that occurred in this group
	 */
	matches: MatchOverview[];
	/**
	 * Engagement events for this group
	 */
	engagementEvents: EngagementEvent[];
	/**
	 * Match reviews submitted in this group
	 */
	matchReviews: MatchReviewSummary[];
	/**
	 * All user light data for the group
	 */
	allUserLiteInfo: UserLiteData[];
	/**
	 * Number of matches in this group
	 */
	numberOfMatches: number;
	/**
	 * Number of engagement events in this group
	 */
	numberOfEngagementEvents: number;
	/**
	 * Number of match reviews in this group
	 */
	numberOfMatchReviews: number;
	/**
	 * The stripe subscription id for this group
	 */
	stripeSubscriptionId: string;
	/**
	 * The name of the subscription for this group
	 */
	subscriptionName: string;
	/**
	 * True if the group's payment is managed externally
	 */
	paymentManagedExternally: boolean;
	/**
	 * The ID of the OpenAI assistant for this group
	 */
	openAiAssistantId: string;
	/**
	 * True if the OpenAI assistant is enabled for this group
	 */
	openAiAssistantEnabled: boolean;
	/**
	 * Instructions for the group's OpenAI assistant (if enabled)
	 */
	openAiAssistantInstructions: string;
	/**
	 * True if the group has feature access to the chatbot
	 */
	featureFlagChatbot: boolean;
	/**
	 * The first example of the opening message for the slack bot intro
	 */
	slackIntroOpeningMessageExample1: string;
	/**
	 * The second example of the opening message for the slack bot intro
	 */
	slackIntroOpeningMessageExample2: string;
	/**
	 * The third example of the opening message for the slack bot intro
	 */
	slackIntroOpeningMessageExample3: string;
	/**
	 * The first example of the middle message for the slack bot intro
	 */
	slackIntroMiddleMessageExample1: string;
	/**
	 * The second example of the middle message for the slack bot intro
	 */
	slackIntroMiddleMessageExample2: string;
	/**
	 * The third example of the middle message for the slack bot intro
	 */
	slackIntroMiddleMessageExample3: string;
	/**
	 * The first example of the next steps section for the slack bot intro
	 */
	slackIntroNextStepsMessageExample1: string;
	/**
	 * The second example of the next steps section for the slack bot intro
	 */
	slackIntroNextStepsMessageExample2: string;
	/**
	 * The third example of the next steps section for the slack bot intro
	 */
	slackIntroNextStepsMessageExample3: string;
	/**
	 * True if we should display a scheduler in a slack intro
	 */
	displaySchedulerInSlackIntro: boolean;
	/**
	 * True if we should display a scheduler in an intro request
	 */
	displaySchedulerInIntroRequest: boolean;
	/**
	 * The club's subscription data
	 */
	subscription: Subscription;
	/**
	 * True if the group is currently in a trial period
	 */
	isInSubscriptionTrial: boolean;
	/**
	 * True if the group's subscription has been cancelled
	 */
	isSubscriptionCancelled: boolean;
	/**
	 * True if the group's subscription has been paused
	 */
	isSubscriptionPaused: boolean;
	/**
	 * The percentage of the trial period that has elapsed
	 */
	trialPeriodCompletionRatio: number;
	/**
	 * The number of days remaining in the trial period
	 */
	trialPeriodDaysRemaining: number;
	/**
	 * True if the group is on a free plan
	 */
	isOnFreePlan: boolean;
	/**
	 * True if the group is on a starter plan
	 */
	isOnStarterPlan: boolean;
	/**
	 * True if the group is on a pro plan
	 */
	isOnProPlan: boolean;
	/**
	 * Returns the group member data of the user associated with the passed id
	 * @param id
	 */
	getMemberInfo(id: number): IGroupMember;
	/**
	 * Return user info on the given member
	 */
	getUserInfo(uid: number): UserLiteData;
	/**
	 * Triggers the group to load its join survey
	 * :param admin: True if an admin is fetching
	 */
	getJoinSurvey(admin: boolean, callback?: () => void): void;
	/**
	 * Triggers the group to load its review survey
	 * :param admin: True if an admin is fetching
	 */
	getReviewSurvey(admin: boolean): void;
	/**
	 * Triggers the group to load its custom data
	 */
	getCustomData(): void;
	/**
	 * Triggers the group to load its members
	 */
	addIcebreakerToGroup(question: string): Observable<unknown>;
	/**
	 * Adds a new icebreaker icebreaker to given group
	 */
	getIcebreakers(): void;
	/**
	 * Gets all icebreakers from given group
	 */
	deleteIcebreaker(uid: number): Observable<unknown>;
	/**
	 * Retrieves all members fo the group
	 */
	refreshAllMembers(): void;

	/**
	 * Loads setup data for the group
	 */
	getSetupData(forceFetch?: boolean): void;
	/**
	 * Accept a member to the group
	 * @param id
	 */
	acceptMember(id: number): Observable<void>;
	/**
	 * Remove a member from the group
	 * @param id
	 */
	removeMember(id: number, admin: boolean): Observable<void>;
	/**
	 * Reject a member from the gropu
	 * @param id
	 */
	rejectMember(id: number): Observable<void>;
	/**
	 * Confirm or update a members email
	 * @param id
	 * @param email
	 */
	confirmOrUpdateMemberEmail(id: number, email?: string): Observable<void>;
	/**
	 * Fetch filters for the insights page for this group
	 */
	getInsightsFilters(): void;
	/**
	 * Fetch insights that align with the passed filter params
	 */
	getInsights(label: string, params: InsightsSearchRequest): void;
	/**
	 * Fetch matches that aligns with the passed filter params
	 */
	getMatches(
		page: number,
		size: number,
		clearOnReload: boolean,
		params?: MatchesSearchParameters
	): void;
	/**
	 * Fetch engagement that aligns with the passed filter params
	 */
	getEngagement(
		page: number,
		size: number,
		clearOnReload: boolean,
		params?: EngagementSearchParameters
	): void;
	/**
	 * Fetch feedback that aligns with the passed filter params
	 */
	getMatchReviews(
		page: number,
		size: number,
		clearOnReload: boolean,
		params?: FeedbackSearchParameters
	): void;
	/**
	 * Fetch engagement details for the activity log entry with the specified id
	 * @param id
	 */
	loadEngagementDetails(id: number): void;
	/**
	 * Fetch feedbakc details for the review specified by the passed id
	 * @param id
	 */
	loadFeedbackDetails(id: number): Observable<unknown>;
	/**
	 * Retrieve match feedback for a given match and user
	 */
	getMatchFeedback(
		hash: string,
		uid: number
	): Observable<AnsweredQuestionModel[]>;
	/**
	 * Create a matching round
	 */
	createMatchingRound(body: CreateMatchingRoundRequest): Observable<unknown>;
	/**
	 * Removes a question from the join survey
	 */
	removeJoinSurveyQuestion(qid: number): Observable<void>;
	/**
	 * Loads all necessary group data for navigating from the user homepage into
	 * the group workspace
	 *
	 * 1. Load events and matching rounds
	 * 2. Load the group config
	 * 3. Load the group setup data
	 *
	 */
	loadGroupWorkspace(): void;
	/**
	 * Load config for this group
	 */
	loadConfig(): void;
	/**
	 * Load matching rounds for this group
	 */
	getMatchingRounds(): Observable<void>;
	/**
	 * Load invite rounds for this group
	 */
	getInviteRounds(): Observable<void>;
	/**
	 * Get a specific matching round by ID
	 */
	getMatchingRoundById(id: number): MatchingRoundClassModel;
	/**
	 * Get a specific invite round for a matching round
	 */
	getInviteRoundByMrid(mrid: number): InviteRoundClassModel;
	/**
	 * Callback to load feature access for the group
	 */
	loadFeatureAccess(): void;
	/**
	 * Add a user to this group
	 */
	addUserToGroup(
		request: ClubsMembersPostRequest
	): Observable<ClubsMembersPostResponse>;
	/**
	 * Accept a user's invite to join a matching round by matching round hash
	 */
	acceptMatchingRoundInvite(email: string, mrid: number): Observable<void>;
	/**
	 * Check if a member (by email) is a member of this group
	 */
	checkIfEmailIsMember(email: string): Observable<MemberExistenceCheck>;
	/**
	 * Load the number of members under each status in the group
	 */
	loadMemberStatusCount(): Observable<MembersCountGetResponse>;
	/**
	 * Search for members
	 * @param params
	 */
	searchMembers(
		params: MemberSearchRequest
	): Observable<MemberSearchResponse>;
	/**
	 * Add a new question to this groups join survey
	 * @param params
	 * @param callback to trigger after the question is added
	 */
	addJoinSurveyQuestion(
		params: CreateQuestionPostRequest
	): Observable<CreateQuestionPostResponse>;
	/**
	 * Edit a question in this groups join survey
	 * @param params
	 */
	editJoinSurveyQuestion(
		qid: number,
		params: UpdateQuestionPutRequest
	): Observable<UpdateQuestionPutResponse>;

	/**
	 * Update the string value used as the answer to a survey question
	 */
	updateSurveyQuestionOption(
		qid: number,
		id: number,
		value: string
	): Observable<void>;
	/**
	 * Change the visibility of the survey question specified by the qid
	 * @param qid
	 */
	changeVisibilityForSurveyQuestion(qid: number): Observable<IErrorResponse>;
	/**
	 * Move a fixed question in this group's join survey
	 */
	moveFixedSlide(id: FixedSlidesEnum, index: number): Observable<void>;
	/**
	 * Removes a question from the Review survey
	 */
	removeReviewSurveyQuestion(qid: number): Observable<void>;
	/**
	 * Add a new question to this groups Review survey
	 * @param params
	 */
	addReviewSurveyQuestion(
		params: CreateQuestionPostRequest
	): Observable<CreateQuestionPostResponse>;
	/**
	 * Sets the usage of the custom data element specified by the question id
	 */
	setCustomDataUsage(questionId: number, usage: CustomDataUsageEnum): void;
	/**
	 * Saves the custom data edits
	 */
	saveCustomDataUsage(usage: CustomDataUsageEnum): Observable<void>;
	/**
	 * Update the name of the group
	 * @param name
	 */
	updateNameAndDisplayId(name: string, displayId: string): void;
	/**
	 * Update the group's subscription name
	 */
	update(values: ClubsPutRequest): Observable<void>;
	/**
	 * Update the group's subscription subscription trial end date
	 */
	updateStripeSubscriptionTrialEnd(stripeSubscriptionTrialEnd: string): void;
	/**
	 * Delete the group's subscription
	 */
	deleteSubscription(): Observable<void>;
	/**
	 * Update that the verified sender is confirmed
	 * @param confirmed
	 */
	updateVerifiedSenderConfirmed(confirmed: boolean): void;
	/**
	 * Bulk update the group's config
	 */
	updateConfig(
		params: ClubsConfigPutRequest
	): Observable<ClubsConfigPutResponse>;
	/**
	 * Migrate this group to use the new welcome template
	 */
	migrateToNewWelcomeTemplate(): Observable<ClubsConfigPutResponse>;
	/**
	 * Kickoff discord for this group
	 */
	kickoffDiscord(welcomeText: string, helpText: string): Observable<void>;
	/**
	 * Send a test message to the passed email or phone number
	 * @param contact
	 * @param messageType
	 * @param isTextGroup
	 */
	sendTestMessage(
		contact: string,
		messageType: MessageTypeEnum,
		iMessage: boolean,
		subject?: string,
		body?: string
	): Observable<void>;
	/**
	 * Send a test slack message
	 */
	sendTestSlackMessage(messageType: string): Observable<IErrorResponse>;
	/**
	 * Send a custom slack message
	 */
	sendCustomSlackMessage(
		message: string,
		userIds: number[],
		subject?: string
	): Observable<IErrorResponse>;
	/**
	 * Change the group's access to a feature
	 * @param feature
	 * @param access
	 */
	changeFeatureAccess(params: ClubsAccessUpdateRequest): Observable<unknown>;
	/**
	 * Update that the group has been properly configured for the specified onboarding step
	 * @param page
	 */
	updateSetupData(page: GroupSetupEnum): void;
	/**
	 * Reload this groups configuration
	 */
	refreshConfig(): void;
	/**
	 * Get a subset of answers for the question with the specified id
	 */
	getPreviewAnswersForQuestion(qid: number): string[];
	/**
	 * Load the specified data for group member with given uid
	 * @param uid
	 */
	loadMemberInfo(uid: number, infoType: GroupMemberInfoEnum): void;
	/**
	 * Load all admins of this group
	 */
	loadAdmins(): Observable<ClubsTeamsGetResponse>;
	/**
	 * Load tags for this group
	 */
	loadTags(): Observable<GetTagsResponse>;
	/**
	 * Load matching rules for this group
	 */
	loadMatchingRules(): Observable<TagMatchingRule[]>;
	/**
	 * Removes an admin from this group
	 */
	removeAdmin(uid: number): Observable<unknown>;
	/**
	 * Adds an admin to the group's list of admins
	 */
	addAdmin(admin: IGroupAdmin): void;
	/**
	 * Add a tag to the group
	 */
	addTag(tag: string): void;
	/**
	 * Remove a tag from the group
	 */
	removeTag(tid: number): void;
	/**
	 * Bulk activate multiple members in the group
	 */
	bulkActivateMembers(uids: number[]): Observable<void>;
	/**
	 * Bulk suspend multiple members in the group
	 */
	bulkSuspendMembers(uids: number[]): Observable<void>;
	/**
	 * Update tags for members in the group
	 * @param tag
	 * @param toAdd
	 * @param toRemove
	 * @param param3
	 */
	updateMemberTags(
		tag: string,
		toAdd: number[],
		toRemove: number[]
	): Observable<void>;
	/**
	 * Change the visibility of a tag
	 * @param tid
	 * @param visible
	 */
	changeTagVisibility(tid: number, visible: boolean): void;
	/**
	 * Change the label of a tag
	 * @param tid
	 * @param label
	 */
	changeTagLabel(tid: number, label: string): void;
	/**
	 * Add a new matching rule for this group
	 * @param first
	 * @param second
	 * @param relationship
	 */
	addMatchingRule(
		first: number,
		second: number,
		relationship: MatchingRuleEnum
	): void;
	/**
	 * Change whether a matching rule is active or inactive
	 * @param id
	 * @param active
	 */
	updateMatchingRule({
		id,
		active,
		first,
		second,
		relationship
	}: {
		id: number;
		active?: boolean;
		first?: number;
		second?: number;
		relationship?: MatchingRuleEnum;
	}): void;
	/**
	 * Request an exported report
	 */
	requestExportedData(params: DataExportRequest): Observable<unknown>;
	/**
	 * Returns True if this group can request this report type (false otherwise)
	 * @param type
	 */
	canRequestReportType(type: GroupExportTypeEnum): boolean;
	/**
	 * The date that the admin last notified the users with incomplete surveys
	 */
	lastNotifiedUsers: string;
	/**
	 * True if there are still members in the group that have incomplete surveys
	 */
	incompleteUserSurveys: boolean;
	/**
	 * Resend the invite to an existing member
	 */
	reinviteMember(uid: number): Observable<unknown>;
	/**
	 * Get a payment link for upgrading a group's subscription
	 */
	createCheckoutSession(
		priceLookupKey: StripePriceLookupKeyEnum,
		customSubscriptionData: CustomSubscriptionData,
		successUrl?: string,
		cancelUrl?: string
	): Observable<CreateCheckoutSessionResponse>;
	/**
	 * Create a trial subscription for the group
	 */
	createTrialSubscription(
		priceLookupKey: StripePriceLookupKeyEnum,
		customSubscriptionData: CustomSubscriptionData,
		trialEnd?: string
	): Observable<CreateTrialSubscriptionResponse>;
	/**
	 * Get a link for managing the group's subscription
	 */
	manageSubscription(flow?: PortalFlow): Observable<unknown>;
	/**
	 * Get all data for the group's subscription
	 */
	getSubscription(): Observable<GetSubscriptionResponse>;
	/**
	 * Initialize the AI assistant for this group
	 */
	initializeAiChat(): Observable<unknown>;
	/**
	 * Deactivate the AI assistant for this group
	 */
	deactivateAiChat(): Observable<unknown>;
	/**
	 * Sync slack members with the group
	 */
	syncSlackMembers(slackTeamId?: string): Observable<IErrorResponse>;
}

export class GroupModel implements IGroupModel {
	@observable
	public gid: number;
	@observable
	public displayId: string;
	@observable
	public name: string;

	/**
	 * True when we are loading custom data
	 */
	@observable
	public isLoadingCustomData: boolean = false;
	/**
	 * True when we are loading insights data
	 */
	@observable
	public insightsLoading: boolean = false;
	/**
	 * True when we have loaded the tags from the database
	 */
	@observable
	public tagsLoaded: boolean = false;
	/**
	 * True when we have loaded matches from the database
	 */
	@observable
	public matchesLoaded: boolean = false;
	/**
	 * True when we have loaded matching rounds from the database
	 */
	@observable
	public matchingRoundsLoaded: boolean = false;
	/**
	 * Map of our insights label to the overview insights for that label
	 */
	@observable
	public insightsOverview: ObservableMap<
		string,
		InsightsSummary
	> = new ObservableMap<string, InsightsSummary>();
	/**
	 * Map of our insights label to the engagement for that label
	 */
	@observable
	public insightsEngagement: ObservableMap<
		string,
		EngagementEvent[]
	> = new ObservableMap<string, EngagementEvent[]>();

	/**
	 * Map of our insights label to the feedback for that label
	 */
	@observable
	public insightsFeedback: ObservableMap<
		string,
		MatchReviewSummary[]
	> = new ObservableMap<string, MatchReviewSummary[]>();

	/**
	 * Map of our engagement event ids to the additional info for each event
	 */
	@observable
	public engagementAdditionalInfo: ObservableMap<
		number,
		EventDetails[]
	> = new ObservableMap<number, EventDetails[]>();

	/**
	 * Map of our feedback events to details on the event
	 */
	public feedbackDetails: ObservableMap<
		number,
		FeedbackDetails[]
	> = new ObservableMap<number, FeedbackDetails[]>();

	/**
	 * Map of matches that have occurred in this group
	 */
	@observable
	public matchesMap: ObservableMap<number, MatchOverview> = new ObservableMap<
		number,
		MatchOverview
	>();

	/**
	 * Map of the engagement events that have occurred in this group
	 */
	@observable
	public engagementEventsMap: ObservableMap<
		number,
		EngagementEvent
	> = new ObservableMap<number, EngagementEvent>();

	/**
	 * Map of the match reviews that have been submitted in this group
	 */
	@observable
	public matchReviewsMap: ObservableMap<
		number,
		MatchReviewSummary
	> = new ObservableMap<number, MatchReviewSummary>();

	/**
	 * Track the number of matches in the current search
	 */
	@observable
	public numberOfMatches: number;

	/**
	 * Track the number of engagement events in the current search
	 */
	@observable
	public numberOfEngagementEvents: number;

	/**
	 * Track the number of match reviews in the current search
	 */
	@observable
	public numberOfMatchReviews: number;

	/**
	 * Filters which can be applied on the insights page
	 */
	@observable.ref
	public insightsFilters: IGroupInsightsFilter[] = [];

	/**
	 * Map of question id to custom data models
	 */
	@observable
	private customData: ObservableMap<
		number,
		ICustomDataModel
	> = new ObservableMap<number, ICustomDataModel>();

	/**
	 * Map of active group member ids to their group member objects
	 */
	@observable
	private _allGroupMembers: ObservableMap<
		number,
		IGroupMember
	> = new ObservableMap<number, IGroupMember>();

	/**
	 * List of member ids that match the search results
	 */
	@observable
	public orderedMemberUids: number[] = [];
	/**
	 * Past matches for this group
	 */
	@observable.ref
	public pastMatches: IMatchesByWeekData[];
	/**
	 * Icebreakers for this group
	 */
	@observable.ref
	public icebreakers: IcebreakerModel[] = [];
	/**
	 * Number of members matching the search
	 */
	@observable
	public numberOfSearchedMembers: number = 0;
	/**
	 * Number of pages of members matching the searchp
	 */
	@observable
	public numberOfSearchedMemberPages: number;
	/**
	 * Number of active members in the group
	 */
	@observable
	public numberOfActiveMembers: number = 0;
	/**
	 * Number of inactive members in group - used while we delay loading data on members until needed
	 */
	@observable
	public numberOfInactiveMembers: number = 0;
	/**
	 * Number of uploaded members in group - used while we delay loading data on members until needed
	 */
	@observable
	public numberOfUploadedMembers: number = 0;
	/**
	 * Number of invited members in group - used while we delay loading data on members until needed
	 */
	@observable
	public numberOfInvitedMembers: number = 0;
	/**
	 * Number of pending members in group - used while we delay loading data on members until needed
	 */
	@observable
	public numberOfPendingMembers: number = 0;
	/**
	 * Number of pages of attention needed members in the group
	 */
	@observable
	public numberOfAttentionNeededMembers: number = 0;
	/**
	 * Configuration for this group
	 */
	@observable
	private config: IGroupConfig;
	/**
	 * Feature access permissions for the group
	 */
	@observable
	private access: IGroupAccess;
	/**
	 * Join survey model for this group
	 */
	@observable
	private _joinSurvey: JoinSurveyModel;
	/**
	 * Review survey model for this group
	 */
	@observable
	private _reviewSurvey: SurveyClassModel;
	/**
	 * Map of matchingRound ids to their matchingRound models
	 */
	@observable
	protected _matchingRounds: ObservableMap<
		number,
		MatchingRoundClassModel
	> = new ObservableMap<number, MatchingRoundClassModel>();
	/**
	 * Map of invite round ids to their invite round models
	 */
	@observable
	protected _inviteRounds: ObservableMap<
		number,
		InviteRoundClassModel
	> = new ObservableMap<number, InviteRoundClassModel>();
	/**
	 * question ids of the custom data elements modified on the opt-in page
	 */
	@observable
	private modifiedCustomData = new ObservableMap<number, ICustomDataModel>();

	/**
	 * Track lite user data for all users in this group
	 */
	@observable
	private _userLiteData: ObservableMap<
		number,
		UserLiteData
	> = new ObservableMap<number, UserLiteData>();

	/**
	 * Override value for isSlackIntrosEnabled UNTIL the full config is loaded
	 */
	@observable
	private _isSlackOverride: boolean = false;
	/**
	 * Override value for isDiscordIntrosEnabled UNTIL the full config is loaded
	 */
	@observable
	private _isDiscordOverride: boolean = false;
	/**
	 * Override value for paused UNTIL the full config is loaded
	 */
	@observable
	private _isPaused: boolean = false;
	/**
	 * Override for the group image UNTIL the full config is loaded
	 */
	@observable
	private _imageOverride: string;
	/**
	 * Setup data specifying if the group has been properly configured
	 */
	@observable
	protected setupData: ClubSetupModel;
	/**
	 * True when we have loaded the config for this group
	 */
	@observable
	public isConfigInitialized: boolean = false;
	/**
	 * UID of the creator of the group
	 */
	@observable
	public creatorId: number;
	/**
	 * All admins of this group
	 */
	@observable
	public admins: ClubAdminModel[] = [];
	/**
	 * All matching rules for tags of this group
	 */
	@observable
	public matchingRules: TagMatchingRule[] = [];
	/**
	 * All tags for members of this group
	 */
	@observable
	public tags: MemberTagModel[] = [];
	/**
	 * Subscription ID for this group
	 */
	@observable
	public stripeSubscriptionId: string;

	/**
	 * Subscription name for this group
	 */
	@observable
	public subscriptionName: string;

	/**
	 * True if the group's payment is managed externally
	 */
	@observable
	public paymentManagedExternally: boolean;

	/**
	 * The ID of the OpenAI assistant for this group
	 */
	@observable
	public openAiAssistantId: string;

	/**
	 * True if the OpenAI assistant is enabled for this group
	 */
	@observable
	public openAiAssistantEnabled: boolean;

	/**
	 * True if the group has feature access to the chatbot
	 */
	@observable
	public featureFlagChatbot: boolean;

	/**
	 * The club's subscription data
	 */
	@observable
	public subscription: Subscription;

	/**
	 * Subject to notify subscribers when group's config has been initialized
	 */
	public configInitialized: Subject<any>;

	constructor(params: Partial<Club>) {
		makeObservable(this);
		// set local identification parameters
		this.gid = params.gid;
		this.displayId = params.displayId;
		this.creatorId = params.creatorId;
		this.name = params.name;
		this._imageOverride = params.image;
		this._isPaused = params.archived;
		this.stripeSubscriptionId = params.stripeSubscriptionId;
		this.subscriptionName = params.subscriptionName;
		this.paymentManagedExternally = params.paymentManagedExternally;
		this.openAiAssistantId = params.openAiAssistantId;
		this.openAiAssistantEnabled = params.openAiAssistantEnabled;
		this.featureFlagChatbot = params.featureFlagChatbot;

		// establish subject to track if the config has been initialized
		this.configInitialized = new Subject();
	}

	/**
	 *
	 */
	@computed
	public get openAiAssistantInstructions(): string {
		return this.config?.openAiAssistantInstructions;
	}

	/**
	 * All matching rules for tags of this group
	 */
	@computed
	public get activeMatchingRules(): TagMatchingRule[] {
		return this.matchingRules?.filter((rule) => rule.active);
	}

	@computed
	public get groupIdentificationData(): IGroupIdentificationData {
		return {
			gid: this.gid,
			displayId: this.displayId,
			creatorId: this.creatorId,
			name: this.name
		};
	}

	/**
	 * Join survey model
	 */
	@computed
	public get joinSurveyModel(): JoinSurveyModel {
		return this._joinSurvey;
	}

	/**
	 * True if we should include the frequency question in our join survey
	 */
	@computed
	public get includeFrequencyQuestion(): boolean {
		return this._joinSurvey?.includeFrequencyQuestion;
	}

	@computed
	public get joinSurvey(): IAbstractQuestionModel[] {
		// return join survey or empty array
		return this._joinSurvey?.questions || [];
	}

	@computed
	public get requiredForOnboardingJoinSurvey(): IAbstractQuestionModel[] {
		// return required join survey or empty array
		return (
			this._joinSurvey?.questions?.filter(
				(question: IAbstractQuestionModel) =>
					question instanceof StaticQuestionModel ||
					[
						QuestionVisibilityEnum.ONBOARDING,
						QuestionVisibilityEnum.VISIBLE
					].includes((question as IDynamicQuestionModel).visibility)
			) || []
		);
	}

	@computed
	public get userDefinedJoinSurvey(): IDynamicQuestionModel[] {
		return this._joinSurvey?.dynamicQuestions || [];
	}

	@computed
	public get reviewSurvey(): IDynamicQuestionModel[] {
		// return review survey or empty array
		return (this._reviewSurvey?.questions as IDynamicQuestionModel[]) || [];
	}

	@computed
	public get joinLink(): string {
		return `${process.env.REACT_APP_UI}/join/${this.displayId}`;
	}

	@computed
	public get welcomeTitle(): string {
		return this.config?.welcomeTitle;
	}

	@computed
	public get welcomeMessage(): string {
		return this.config?.welcomeMessage;
	}

	@computed
	public get matchingTimesQuestionWording(): string {
		return this.config?.matchingTimesQuestion;
	}

	@computed
	public get includeBioInJoinForm(): boolean {
		return this.config?.includeBioInJoinForm;
	}

	@computed
	public get includeLocationInJoinForm(): boolean {
		return this.config?.includeLocationInJoinForm;
	}

	@computed
	public get includeJobTitleInJoinForm(): boolean {
		return this.config?.includeJobTitleInJoinForm;
	}

	@computed
	public get includeCompanyInJoinForm(): boolean {
		return this.config?.includeCompanyInJoinForm;
	}

	@computed
	public get includeEducationInJoinForm(): boolean {
		return this.config?.includeEducationInJoinForm;
	}

	@computed
	public get includeDegreesInJoinForm(): boolean {
		return this.config?.includeDegreesInJoinForm;
	}

	@computed
	public get includeLinkedInInJoinForm(): boolean {
		return this.config?.includeLinkedInInJoinForm;
	}

	@computed
	public get includeTwitterInJoinForm(): boolean {
		return this.config?.includeTwitterInJoinForm;
	}

	@computed
	public get includePersonalSiteInJoinForm(): boolean {
		return this.config?.includePersonalSiteInJoinForm;
	}

	@computed
	public get includePronounsInJoinForm(): boolean {
		return this.config?.includePronounsInJoinForm;
	}

	@computed
	public get includePhoneNumberInJoinForm(): boolean {
		return this.config?.includePhoneNumberInJoinForm;
	}

	@computed
	public get personalityIntros(): boolean {
		return this.config?.personalityIntros > 0;
	}

	@computed
	public get toneMatching(): boolean {
		return this.config?.toneMatching > 0;
	}

	@computed
	public get randomMatch(): boolean {
		return this.config?.randomMatch;
	}

	@computed
	public get usersChooseWeights(): boolean {
		return this.config?.usersChooseWeights;
	}

	@computed
	public get scheduleIntros(): boolean {
		return this.config?.scheduleIntro;
	}

	@computed
	public get doubleOptIn(): boolean {
		return this.config?.doubleOptIn;
	}

	@computed
	public get singleOptIn(): boolean {
		return this.config?.singleOptIn;
	}

	@computed
	public get autoaccept(): boolean {
		return this.config?.autoaccept;
	}

	@computed
	public get dateCreated(): string {
		return this.config?.dateCreated;
	}

	@computed
	public get isDiscordGroup(): boolean {
		return this.config?.isDiscord;
	}

	@computed
	public get isEmailGroup(): boolean {
		return true;
	}

	@computed
	public get isIntrosPaused(): boolean {
		return this.config ? this.config?.paused : this._isPaused;
	}

	@computed
	public get emailDisplayName(): string {
		return this.config?.emailDisplayName;
	}

	@computed
	public get calendarInviteTitle(): string {
		return this.config?.calendarInviteTitle;
	}

	@computed
	public get verifiedSender(): string {
		return this.config?.verifiedSender;
	}

	@computed
	public get verifiedSenderConfirmed(): boolean {
		return this.config?.verifiedSenderConfirmed;
	}

	@computed
	public get isSlackIntrosEnabled(): boolean {
		return this.config ? this.config?.isSlack : this._isSlackOverride;
	}

	@computed
	public get isDiscordIntrosEnabled(): boolean {
		return this.config ? this.config?.isDiscord : this._isDiscordOverride;
	}

	@computed
	public get isDirectoryEnabled(): boolean {
		return this.config?.isDirectoryEnabled;
	}

	@computed
	public get isProposerCCd(): boolean {
		return this.config?.isProposerCCd;
	}

	@computed
	public get customDirectoryLink(): string {
		return this.config?.customDirectoryLink;
	}

	@computed
	public get replyToAdmin(): boolean {
		return this.config?.replyToAdmin;
	}

	@computed
	public get ccAdmin(): boolean {
		return this.config?.ccAdmin;
	}

	@computed
	public get firstEmailTop(): string {
		return this.config?.firstEmailTop;
	}

	@computed
	public get introEmailTop(): string {
		return this.config?.introEmailTop;
	}

	@computed
	public get firstEmailBottom(): string {
		return this.config?.firstEmailBottom;
	}

	@computed
	public get welcomeInstructions(): string {
		return this.config?.welcomeInstructions;
	}

	@computed
	public get customSignature(): string {
		return this.config?.customSignature;
	}

	@computed
	public get plainTextWelcome(): boolean {
		return this.config?.plainTextWelcome;
	}

	@computed
	public get plainTextFeedback(): boolean {
		return this.config?.plainTextFeedback;
	}

	@computed
	public get plainTextOptIn(): boolean {
		return this.config?.plainTextOptIn;
	}

	@computed
	public get optInJSON(): object {
		return this.config?.optInJSON;
	}

	@computed
	public get optInSubject(): string {
		return this.config?.optInSubject;
	}

	@computed
	public get optOutJSON(): object {
		return this.config?.optOutJSON;
	}

	@computed
	public get optOutSubject(): string {
		return this.config?.optOutSubject;
	}

	@computed
	public get welcomeJSON(): object {
		return this.config?.welcomeJSON;
	}

	@computed
	public get welcomeSubject(): string {
		return this.config?.welcomeSubject;
	}

	@computed
	public get defaultToAutoEnroll(): boolean {
		return this.config?.defaultToAutoEnroll;
	}

	@computed
	public get autoEnrollInviteHeading(): string {
		return this.config?.autoEnrollInviteHeading;
	}

	@computed
	public get autoEnrollInviteBody(): string {
		return this.config?.autoEnrollInviteBody;
	}

	@computed
	public get generalInviteHeading(): string {
		return this.config?.generalInviteHeading;
	}

	@computed
	public get generalInviteBody(): string {
		return this.config?.generalInviteBody;
	}

	@computed
	public get plainTextIntro(): boolean {
		return this.config?.plainTextIntro;
	}

	@computed
	public get buttonColor(): string {
		return this.config?.buttonColor;
	}

	@computed
	public get textColor(): string {
		return this.config?.textColor;
	}

	@computed
	public get joinFormColor(): string {
		return this.config?.joinFormColor;
	}

	@computed
	public get useJoinFormImage(): boolean {
		return this.config?.useJoinFormImage;
	}

	@computed
	public get disableImages(): boolean {
		return this.config?.disableImages;
	}

	@computed
	public get includeJoinFormPromo(): boolean {
		return this.config?.includeJoinFormPromo;
	}

	@computed
	public get imagePath(): string {
		return this.config
			? this.config?.imagePath || '/assets/images/intros-logo.svg'
			: this._imageOverride;
	}

	@computed
	public get discordChannelId(): number {
		return this.config?.discordChannelId;
	}

	@computed
	public get incompleteUserSurveys(): boolean {
		return this.config?.incompleteUserSurveys;
	}

	@computed
	public get lastNotifiedUsers(): string {
		return this.config?.lastNotifiedUsers;
	}

	@computed
	public get sendNewSlackMembersWelcomeMessage(): boolean {
		return this.config?.sendNewSlackMembersWelcomeMessage;
	}

	@computed
	public get hasZapierAccess(): boolean {
		return this.access?.hasZapierAccess;
	}

	@computed
	public get hasSlackAccess(): boolean {
		return this.access?.hasSlackAccess;
	}

	@computed
	public get hasMemberDefinedWeightsAccess(): boolean {
		return this.access?.hasMemberDefinedWeightsAccess;
	}

	@computed
	public get hasCustomBrandColorsAccess(): boolean {
		return this.access?.hasCustomBrandColorsAccess;
	}

	@computed
	public get hasChatbotAccess(): boolean {
		return this.access?.hasChatbotAccess;
	}

	@computed
	public get hasCustomDirectoryAccess(): boolean {
		return this.access?.hasCustomDirectoryAccess;
	}

	@computed
	public get hasMemberSchedulingAccess(): boolean {
		return this.access?.hasMemberSchedulingAccess;
	}

	@computed
	public get hasDirectoryIntroRequestsAccess(): boolean {
		return this.access?.hasDirectoryIntroRequestsAccess;
	}

	@computed
	public get hasConnectionHistoryAccess(): boolean {
		return this.access?.hasConnectionHistoryAccess;
	}

	@computed
	public get hasDirectoryAccess(): boolean {
		return this.access?.hasDirectoryAccess;
	}

	@computed
	public get hasConditionalMatchingAccess(): boolean {
		return this.access?.hasConditionalMatchingAccess;
	}

	@computed
	public get hasInternalTaggingAccess(): boolean {
		return this.access?.hasInternalTaggingAccess;
	}

	@computed
	public get hasEmailVerificationAccess(): boolean {
		return this.access?.hasEmailVerificationAccess;
	}

	@computed
	public get hasNotificationAccess(): boolean {
		return this.access?.hasNotificationAccess;
	}

	@computed
	public get hasDataEnrichmentAccess(): boolean {
		return this.access?.hasDataEnrichmentAccess;
	}

	@computed
	public get includeIntrosFooter(): boolean {
		return this.access?.includeIntrosFooter;
	}

	@computed
	public get membersHaveIntercomSupport(): boolean {
		return this.access?.membersHaveIntercomSupport;
	}

	@computed
	public get canAccessIdentityReport(): boolean {
		return this.access?.canAccessIdentityReport;
	}

	@computed
	public get canAccessExpandedReport(): boolean {
		return this.access?.canAccessExpandedReport;
	}

	@computed
	public get canAccessSurveyReport(): boolean {
		return this.access?.canAccessSurveyReport;
	}

	@computed
	public get canAccessOverviewReport(): boolean {
		return this.access?.canAccessOverviewReport;
	}

	@computed
	public get canAccessEngagementReport(): boolean {
		return this.access?.canAccessEngagementReport;
	}

	@computed
	public get canAccessFeedbackReport(): boolean {
		return this.access?.canAccessFeedbackReport;
	}

	@computed
	public get algoParams(): ICustomDataModel[] {
		return (
			Array.from(this.customData.values()).filter(
				(data: ICustomDataModel) => data.algoParam
			) || []
		);
	}

	@computed
	public get additionalData(): ICustomDataModel[] {
		return (
			Array.from(this.customData.values()).filter(
				(data: ICustomDataModel) => !data.algoParam
			) || []
		);
	}

	@computed
	public get orderedGroupMembers(): IGroupMember[] {
		return this.orderedMemberUids.map((uid: number) =>
			this._allGroupMembers.get(uid)
		);
	}

	@computed
	public get activeMembers(): IGroupMember[] {
		return Array.from(this._allGroupMembers.values()).filter(
			(member) => member.status === GroupMemberStatusEnum.Active
		);
	}

	@computed
	public get uploadedMembers(): IGroupMember[] {
		return Array.from(this._allGroupMembers.values()).filter(
			(member) => member.status === GroupMemberStatusEnum.Uploaded
		);
	}

	@computed
	public get inactiveMembers(): IGroupMember[] {
		/**
		 * A member is inactive if,
		 * 1. They were not uploaded and they are inactive
		 * 2. They were uploaded, and they accepted an invitation, but now they are inactive
		 */
		return Array.from(this._allGroupMembers.values()).filter(
			(member) => member.status === GroupMemberStatusEnum.Inactive
		);
	}

	@computed
	public get attentionNeededMembers(): IGroupMember[] {
		return Array.from(this._allGroupMembers.values()).filter(
			(member) => member.status === GroupMemberStatusEnum.Attention
		);
	}

	@computed
	public get invitedMembers(): IGroupMember[] {
		return Array.from(this._allGroupMembers.values()).filter(
			(member) => member.status === GroupMemberStatusEnum.Invited
		);
	}

	@computed
	public get pendingMembers(): IGroupMember[] {
		return Array.from(this._allGroupMembers.values()).filter(
			(member) => member.status === GroupMemberStatusEnum.Pending
		);
	}

	@computed
	public get matchingRounds(): MatchingRoundClassModel[] {
		return Array.from(this._matchingRounds.values());
	}

	@computed
	public get inviteRounds(): InviteRoundClassModel[] {
		return Array.from(this._inviteRounds.values());
	}

	@computed
	public get futureAdHocMatches(): IMatchingRoundModel[] {
		return this.matchingRounds
			.filter(
				(round: IMatchingRoundModel) =>
					round.time.valueOf() > Date.now() && round.active
			)
			.sort((a: IMatchingRoundModel, b: IMatchingRoundModel) =>
				a.time.valueOf() > b.time.valueOf() ? 1 : -1
			);
	}

	@computed
	public get futureInviteRounds(): IInviteRoundModel[] {
		return this.inviteRounds
			.filter(
				(round: IInviteRoundModel) =>
					round.time.valueOf() > Date.now() && round.active
			)
			.sort((a: IInviteRoundModel, b: IInviteRoundModel) =>
				a.time.valueOf() > b.time.valueOf() ? 1 : -1
			);
	}

	@computed
	public get pastAdHocMatches(): IMatchingRoundModel[] {
		return this.matchingRounds
			.filter(
				(round: IMatchingRoundModel) =>
					round.time.valueOf() < Date.now() && round.active
			)
			.sort((a: IMatchingRoundModel, b: IMatchingRoundModel) =>
				a.time.valueOf() > b.time.valueOf() ? 1 : -1
			);
	}

	@computed
	public get isCustomDataModified(): boolean {
		return this.modifiedCustomData.size > 0;
	}

	@computed
	public get size(): number {
		return this.numberOfActiveMembers;
	}

	@computed
	public get loadedMembers(): number {
		return this._allGroupMembers.size;
	}

	@computed
	public get readOnly(): boolean {
		return false;
	}

	@computed
	public get matchingFormComplete(): boolean {
		return this.setupData && this.setupData.joinFormComplete;
	}

	@computed
	public get welcomePageComplete(): boolean {
		return this.setupData && this.setupData.welcomePageVisited;
	}

	@computed
	public get optInPreviewComplete(): boolean {
		return this.setupData && this.setupData.optInPreviewViewed;
	}

	@computed
	public get introPageComplete(): boolean {
		return this.setupData && this.setupData.introPageVisited;
	}

	@computed
	public get testExperienceComplete(): boolean {
		return this.setupData && this.setupData.testExperienceComplete;
	}

	@computed
	public get feedbackPageComplete(): boolean {
		return this.setupData && this.setupData.feedbackFormPreviewed;
	}

	@computed
	public get joinSurveyLoading(): boolean {
		return this._joinSurvey?.loading;
	}

	@computed
	public get reviewSurveyLoading(): boolean {
		return this._reviewSurvey?.loading;
	}

	@computed
	public get configLoading(): boolean {
		return this.config?.loading;
	}

	@computed
	public get rolloutComplete(): boolean {
		return this.setupData && this.setupData.rollout;
	}

	@computed
	public get needsDiscordRollout(): boolean {
		return this.isDiscordGroup && this.setupData && !this.rolloutComplete;
	}

	@computed
	public get timezone(): string {
		return this.config?.timezone;
	}

	@computed
	public get useMatchingV2(): boolean {
		return this.config?.useMatchingV2;
	}

	@computed
	public get hasGroupKickedOff(): boolean {
		return this.config?.hasGroupKickedOff;
	}

	@computed
	public get slackWelcomeMessage(): string {
		return this.config?.slackWelcomeMessage;
	}

	@computed
	public get slackOptInMessage(): string {
		return this.config?.slackOptInMessage;
	}

	@computed
	public get slackOptOutMessage(): string {
		return this.config?.slackOptOutMessage;
	}

	@computed
	public get slackIntroMessage(): string {
		return this.config?.slackIntroMessage;
	}

	@computed
	public get discordOptInMessage(): string {
		return this.config?.discordOptInMessage;
	}

	@computed
	public get discordOptOutMessage(): string {
		return this.config?.discordOptOutMessage;
	}

	@computed
	public get communicationStartTime(): string {
		return this.config?.communicationStartTime;
	}

	@computed
	public get communicationEndTime(): string {
		return this.config?.communicationEndTime;
	}

	@computed
	public get connectionChannels(): ConnectionTypeEnum[] {
		// create local object
		const connectionChannels: ConnectionTypeEnum[] = [];
		// dynamically add based on config
		if (this.config?.automatedConnectionsEnabled) {
			connectionChannels.push(ConnectionTypeEnum.AUTOMATED);
		}
		if (this.config?.directoryConnectionsEnabled) {
			connectionChannels.push(ConnectionTypeEnum.DIRECTORY);
		}
		if (this.config?.aiAssistantConnectionsEnabled) {
			connectionChannels.push(ConnectionTypeEnum.AIASSISTANT);
		}
		// return
		return connectionChannels;
	}

	@computed
	public get formattedTimezone(): string {
		return formatTimezone(this.config.timezone);
	}

	@computed
	public get allowMultipleChannels(): boolean {
		return this.configuredChannels.length > 1;
	}

	@computed
	public get configuredChannels(): CommunicationTypeEnum[] {
		if (this.isSlackIntrosEnabled && this.isDiscordIntrosEnabled) {
			return [
				CommunicationTypeEnum.EMAIL,
				CommunicationTypeEnum.SLACK,
				CommunicationTypeEnum.DISCORD
			];
		} else if (this.isSlackIntrosEnabled) {
			return [CommunicationTypeEnum.EMAIL, CommunicationTypeEnum.SLACK];
		} else if (this.isDiscordIntrosEnabled) {
			return [CommunicationTypeEnum.EMAIL, CommunicationTypeEnum.DISCORD];
		}
		return [CommunicationTypeEnum.EMAIL];
	}

	@computed
	public get useLegacyWelcomeTemplate(): boolean {
		return this.config.legacyWelcomeMessage;
	}

	@computed
	public get hasUpcomingMatches(): boolean {
		return this.futureAdHocMatches.length > 0;
	}

	@computed
	public get isZapierConnected(): boolean {
		return this.config.isZapierConnected;
	}

	@computed
	public get zapierAutoInvite(): boolean {
		return this.config.zapierAutoInvite;
	}

	@computed
	public get zapierAutoEnroll(): boolean {
		return this.config.zapierAutoEnroll;
	}

	@computed
	public get introEmailSubjectLine(): string {
		return this.config.introEmailSubjectLine;
	}

	@computed
	public get introEmailBasicsShowBio(): boolean {
		return this.config.introEmailBasicsShowBio;
	}

	@computed
	public get introEmailBasicsShowLocation(): boolean {
		return this.config.introEmailBasicsShowLocation;
	}

	@computed
	public get introEmailBasicsShowJobTitle(): boolean {
		return this.config.introEmailBasicsShowJobTitle;
	}

	@computed
	public get introEmailBasicsShowCompany(): boolean {
		return this.config.introEmailBasicsShowCompany;
	}

	@computed
	public get introEmailBasicsShowEducation(): boolean {
		return this.config.introEmailBasicsShowEducation;
	}

	@computed
	public get introEmailBasicsShowDegrees(): boolean {
		return this.config.introEmailBasicsShowDegrees;
	}

	@computed
	public get introEmailBasicsShowLinkedin(): boolean {
		return this.config.introEmailBasicsShowLinkedin;
	}

	@computed
	public get introEmailBasicsShowTwitter(): boolean {
		return this.config.introEmailBasicsShowTwitter;
	}

	@computed
	public get introEmailBasicsShowPersonalSite(): boolean {
		return this.config.introEmailBasicsShowPersonalSite;
	}

	@computed
	public get introEmailBasicsShowPronouns(): boolean {
		return this.config.introEmailBasicsShowPronouns;
	}

	@computed
	public get introEmailBasicsShowPhoneNumber(): boolean {
		return this.config.introEmailBasicsShowPhoneNumber;
	}

	@computed
	public get automatedConnectionsEnabled(): boolean {
		return this.config.automatedConnectionsEnabled;
	}
	@computed
	public get aiAssistantConnectionsEnabled(): boolean {
		return this.config.aiAssistantConnectionsEnabled;
	}
	@computed
	public get directoryConnectionsEnabled(): boolean {
		return this.config.directoryConnectionsEnabled;
	}

	@computed
	public get matches(): MatchOverview[] {
		return Array.from(this.matchesMap.values());
	}

	@computed
	public get engagementEvents(): EngagementEvent[] {
		return Array.from(this.engagementEventsMap.values());
	}

	@computed
	public get matchReviews(): MatchReviewSummary[] {
		return Array.from(this.matchReviewsMap.values());
	}

	@computed
	public get allUserLiteInfo(): UserLiteData[] {
		return Array.from(this._userLiteData.values());
	}

	@computed
	public get slackIntroOpeningMessageExample1(): string {
		return this.config.slackIntroOpeningMessageExample1;
	}

	@computed
	public get slackIntroOpeningMessageExample2(): string {
		return this.config.slackIntroOpeningMessageExample2;
	}

	@computed
	public get slackIntroOpeningMessageExample3(): string {
		return this.config.slackIntroOpeningMessageExample3;
	}

	@computed
	public get slackIntroMiddleMessageExample1(): string {
		return this.config.slackIntroMiddleMessageExample1;
	}

	@computed
	public get slackIntroMiddleMessageExample2(): string {
		return this.config.slackIntroMiddleMessageExample2;
	}

	@computed
	public get slackIntroMiddleMessageExample3(): string {
		return this.config.slackIntroMiddleMessageExample3;
	}

	@computed
	public get slackIntroNextStepsMessageExample1(): string {
		return this.config.slackIntroNextStepsMessageExample1;
	}

	@computed
	public get slackIntroNextStepsMessageExample2(): string {
		return this.config.slackIntroNextStepsMessageExample2;
	}

	@computed
	public get slackIntroNextStepsMessageExample3(): string {
		return this.config.slackIntroNextStepsMessageExample3;
	}

	@computed
	public get displaySchedulerInSlackIntro(): boolean {
		return this.config.displaySchedulerInSlackIntro;
	}

	@computed
	public get displaySchedulerInIntroRequest(): boolean {
		return this.config.displaySchedulerInIntroRequest;
	}

	@computed
	public get isInSubscriptionTrial(): boolean {
		// True if we are between the start and end of the trial period
		return this.subscription?.status === 'trialing';
	}

	@computed
	public get isSubscriptionCancelled(): boolean {
		return this.subscription?.status === 'canceled';
	}

	@computed
	public get isSubscriptionPaused(): boolean {
		return this.subscription?.status === 'paused';
	}

	@computed
	public get trialPeriodCompletionRatio(): number {
		// Ensure subscription dates are valid
		const trialEnd = new Date(this.subscription?.trialEndDate).valueOf();
		const trialStart = new Date(
			this.subscription?.trialStartDate
		).valueOf();
		const now = Date.now();

		// Handle edge cases
		if (trialStart >= trialEnd) {
			return 1; // The trial period is considered completed if start is after or the same as end
		}
		if (now <= trialStart) {
			return 0; // The trial period hasn't started yet
		}
		if (now >= trialEnd) {
			return 1; // The trial period is completed
		}

		// Calculate the percentage of the trial period that has passed
		return (now - trialStart) / (trialEnd - trialStart);
	}

	@computed
	public get trialPeriodDaysRemaining(): number {
		// Ensure subscription dates are valid
		const trialEnd = this.subscription?.trialEndDate;
		if (!trialEnd) {
			return 0; // No trial end date, assume trial is over
		}

		const trialEndDate = new Date(trialEnd);
		const now = new Date();

		// Handle edge cases
		if (now.getTime() >= trialEndDate.getTime()) {
			return 0; // The trial period is completed
		}

		// Calculate the number of days remaining in the trial period
		const msPerDay = 1000 * 60 * 60 * 24;
		const daysRemaining =
			(trialEndDate.getTime() - now.getTime()) / msPerDay;
		return Math.ceil(daysRemaining);
	}

	@computed
	public get isOnFreePlan(): boolean {
		return (
			this.subscriptionName === undefined ||
			this.subscriptionName === null ||
			this.subscriptionName === ''
		);
	}

	@computed
	public get isOnStarterPlan(): boolean {
		return this.subscriptionName === 'Starter Tier';
	}

	@computed
	public get isOnProPlan(): boolean {
		return !this.isOnFreePlan && !this.isOnStarterPlan;
	}

	@action
	public loadGroupWorkspace() {
		// load matching rounds
		this.getMatchingRounds().subscribe();
		// load invite rounds
		this.getInviteRounds().subscribe();
		// load all user info
		this.getUserLiteInfo();

		// check if config already initialized
		if (!this.config) {
			// create a new group config
			this.loadConfig();
		}

		// load group setup access
		this.loadFeatureAccess();
	}

	@action
	public loadFeatureAccess(): void {
		// load group setup access
		this.access = new GroupAccess({
			gid: this.gid,
			displayId: this.displayId,
			name: this.name
		});
	}

	public canRequestReportType(type: GroupExportTypeEnum): boolean {
		switch (type) {
			case GroupExportTypeEnum.IDENTITY:
				return this.canAccessIdentityReport;
			case GroupExportTypeEnum.EXPANDED:
				return this.canAccessExpandedReport;
			case GroupExportTypeEnum.SURVEY:
				return this.canAccessSurveyReport;
			case GroupExportTypeEnum.OVERVIEW:
				return this.canAccessOverviewReport;
			case GroupExportTypeEnum.ENGAGEMENT:
				return this.canAccessEngagementReport;
			case GroupExportTypeEnum.FEEDBACK:
				return this.canAccessFeedbackReport;
		}
	}

	public removeJoinSurveyQuestion(questionId: number) {
		return this._joinSurvey.removeQuestion(questionId) as Observable<void>;
	}

	public addJoinSurveyQuestion(params: CreateQuestionPostRequest) {
		return this._joinSurvey.addSurveyQuestion(params);
	}

	public editJoinSurveyQuestion(
		qid: number,
		params: UpdateQuestionPutRequest
	) {
		return this._joinSurvey.editSurveyQuestion(qid, params);
	}

	public changeVisibilityForSurveyQuestion(qid: number) {
		return this._joinSurvey.toggleVisibilityForSurveyQuestion(qid) as any;
	}

	public moveFixedSlide(id: FixedSlidesEnum, index: number) {
		return this._joinSurvey.moveStaticQuestion(id, index).pipe(
			map((response) => {
				return response;
			})
		);
	}

	public updateSurveyQuestionOption(qid: number, id: number, value: string) {
		return this._joinSurvey.updateQuestionOption(qid, id, value);
	}

	public removeReviewSurveyQuestion(questionId: number) {
		return this._reviewSurvey.removeQuestion(
			questionId
		) as Observable<void>;
	}

	public addReviewSurveyQuestion(params: CreateQuestionPostRequest) {
		return this._reviewSurvey.addSurveyQuestion(params);
	}

	public updateNameAndDisplayId(name: string, displayId: string): void {
		RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.updateGroupClubsDisplayIdPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						name: name,
						displayId: displayId
					}
				}),
			userToken: AppState.user.cookie
		}).subscribe(() => {
			if (name) {
				this.name = name;
			}
			if (displayId) {
				this.displayId = displayId;
				if (this.config) {
					this.config.setDisplayId(this.displayId);
				}
			}
		});
	}

	public update(values: ClubsPutRequest): Observable<void> {
		return RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.updateGroupClubsDisplayIdPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: values
				}),
			userToken: AppState.user.cookie
		}).pipe(
			map(() => {
				if (values.subscriptionName) {
					this.subscriptionName = values.subscriptionName;
				}
				if (values.paymentManagedExternally !== undefined) {
					this.paymentManagedExternally =
						values.paymentManagedExternally;
				}
			})
		);
	}

	public updateStripeSubscriptionTrialEnd(
		stripeSubscriptionTrialEnd: string
	): void {
		RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.updateClubSubscriptionClubsDisplayIdSubscriptionPut(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							trial_end: stripeSubscriptionTrialEnd
						}
					}
				),
			userToken: AppState.user.cookie
		}).subscribe();
	}

	public deleteSubscription() {
		return RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.deleteClubSubscriptionClubsDisplayIdSubscriptionDelete(
					{ displayId: encodeURIComponent(this.displayId) }
				),
			userToken: AppState.user.cookie
		}).pipe(
			map(() => {
				this.subscription = undefined;
				this.subscriptionName = undefined;
				this.stripeSubscriptionId = undefined;
				this.paymentManagedExternally = false;
			})
		);
	}

	public updateVerifiedSenderConfirmed(verified: boolean) {
		this.config?.setSenderVerified(verified);
	}

	public updateConfig(params: ClubsConfigPutRequest) {
		return this.config?.update(params);
	}

	public changeFeatureAccess(params: ClubsAccessUpdateRequest) {
		return this.access?.changeFeatureAccess(params);
	}

	public getMemberInfo(id: number): IGroupMember {
		return this._allGroupMembers.get(id);
	}

	public getUserInfo(uid: number): UserLiteData {
		return this._userLiteData.get(uid);
	}

	@action
	public setCustomDataUsage(questionId: number, usage: CustomDataUsageEnum) {
		if (this.customData.has(questionId)) {
			if (this.modifiedCustomData.has(questionId)) {
				// triggers if the user hits the same switch an even number of times
				this.modifiedCustomData.delete(questionId);
			} else {
				// triggers to add this custom data to the modified data
				this.modifiedCustomData.set(
					questionId,
					this.customData.get(questionId) as ICustomDataModel
				);
			}

			(this.customData.get(questionId) as ICustomDataModel).toggleUsage(
				usage
			);
		}
	}

	public addUserToGroup(body: ClubsMembersPostRequest) {
		return RestApiClient.serviceRequest<ClubsMembersPostResponse>({
			generator: () =>
				MembersService.addMemberClubsDisplayIdMembersPost({
					displayId: encodeURIComponent(this.displayId),
					requestBody: body
				})
		});
	}

	public acceptMatchingRoundInvite(email: string, mrid: number) {
		return RestApiClient.serviceRequest({
			generator: () =>
				MatchingRoundsService.respondToInviteForMatchingRoundClubsDisplayIdMatchingRoundsMatchingRoundIdInvitesPut(
					{
						displayId: encodeURIComponent(this.displayId),
						matchingRoundId: mrid,
						requestBody: {
							accept: true
						}
					}
				),
			userToken: undefined
		}) as Observable<void>;
	}

	public checkIfEmailIsMember(email: string) {
		return RestApiClient.serviceRequest<MemberExistenceCheck>({
			generator: () =>
				MembersService.memberSearchClubsDisplayIdMembersSearchPost({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						email: email,
						type: SearchTypeEnum.MEMBERSHIP
					}
				})
		});
	}

	public loadMemberStatusCount(): Observable<MembersCountGetResponse> {
		return RestApiClient.serviceRequest<MembersCountGetResponse>({
			generator: () =>
				MembersService.getNumberOfUsersByStatusClubsDisplayIdMembersCountGet(
					{
						displayId: encodeURIComponent(this.displayId)
					}
				)
		}).pipe(
			map((response: MembersCountGetResponse) => {
				this.setNumberOfActiveMembers(response.Active);
				this.setNumberOfInactiveMembers(response.Inactive);
				this.setNumberOfUploadedMembers(response.Uploaded);
				this.setNumberOfInvitedMembers(response.Invited);
				this.setNumberOfAttentionNeededMembers(response.Attention);
				this.setNumberOfPendingMembers(response.Pending);

				return response;
			})
		);
	}

	public searchMembers(params: MemberSearchRequest) {
		// apply the filters
		return RestApiClient.serviceRequest<MemberSearchResponse>({
			generator: () =>
				MembersService.memberSearchClubsDisplayIdMembersSearchPost({
					displayId: encodeURIComponent(this.displayId),
					requestBody: params
				})
		}).pipe(
			map((data: MemberSearchResponse) => {
				// set group members
				data.members.forEach((val: MemberProfileModel) => {
					/**
					 * Create a group member model for this member. If the member already exists, delete it and replace it with the new one.
					 */
					if (!this._allGroupMembers.has(val.uid)) {
						this._allGroupMembers.delete(val.uid);
					}

					this._allGroupMembers.set(
						val.uid,
						new GroupMember({
							displayId: this.displayId,
							model: val
						} as IGroupMemberParams)
					);
				});
				// set number of results
				this.setNumberOfSearchedMembers(data.count);
				// set number of pages of results
				this.setNumberOfSearchedMemberPages(data.pages);
				// set search results
				this.extendSearchResults(data.members);
				// return the response
				return data;
			})
		);
	}

	public getCustomData() {
		this.setLoadingCustomData(true);
		RestApiClient.serviceRequest<MessagingData[]>({
			generator: () =>
				MessagingService.getDataUsageClubsDisplayIdMessagingGet({
					displayId: encodeURIComponent(this.displayId)
				}),
			userToken: AppState.user.cookie
		}).subscribe((data: MessagingData[]) => {
			// update the local config
			this.setCustomData(data);
			// set loading custom data false
			this.setLoadingCustomData(false);
		});
	}

	@action
	public refreshAllMembers() {
		// Reset members of active search
		this.orderedMemberUids = [];
	}

	/**
	 * Load all suer lite info for this group
	 */
	public getUserLiteInfo() {
		return RestApiClient.serviceRequest<ClubsUsersGetResponse>({
			generator: () =>
				UsersService.getUserLiteDataClubsDisplayIdUsersGet({
					displayId: encodeURIComponent(this.displayId)
				})
		}).subscribe((response: ClubsUsersGetResponse) => {
			this.setUserLiteData(response.users);
		});
	}

	@action
	public loadAdmins(): Observable<ClubsTeamsGetResponse> {
		return RestApiClient.serviceRequest<ClubsTeamsGetResponse>({
			generator: () =>
				TeamService.getTeamClubsTeamDisplayIdGet({
					displayId: encodeURIComponent(this.displayId)
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: ClubsTeamsGetResponse) => {
				this.admins = data.admins;

				return data;
			})
		);
	}

	@action
	public loadTags(): Observable<GetTagsResponse> {
		return RestApiClient.serviceRequest<GetTagsResponse>({
			generator: () =>
				TaggingService.getTagsClubsDisplayIdTagsGet({
					displayId: encodeURIComponent(this.displayId)
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: GetTagsResponse) => {
				this.tagsLoaded = true;
				this.tags = data.tags;
				// pass on response
				return data;
			})
		);
	}

	@action
	public loadMatchingRules(): Observable<TagMatchingRule[]> {
		return RestApiClient.serviceRequest<TagMatchingRule[]>({
			generator: () =>
				TagBasedMatchingLogicService.getMatchingRulesClubsDisplayIdTagsMatchingLogicGet(
					{
						displayId: encodeURIComponent(this.displayId)
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: TagMatchingRule[]) => {
				this.matchingRules = data;
				// pass on response
				return data;
			})
		);
	}

	@action
	public removeAdmin(uid: number): Observable<unknown> {
		// capture event
		posthog.capture('Admin Removed', {
			group: this.name,
			removed_admin_id: uid,
			source: 'web'
		});

		// remove admin
		return RestApiClient.serviceRequest({
			generator: () =>
				TeamService.removeTeamAdminClubsTeamDisplayIdDelete({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						user: uid
					}
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// Remove admin from group
				const admin = this.admins.find((admin) => admin.uid === uid);
				if (admin) {
					admin.active = false;
					admin.dateInvited = undefined;
				}
			})
		);
	}

	@action
	public addAdmin(admin: IGroupAdmin) {
		// Check if an admin record already exists for this user
		const existingAdmin = this.admins.find(
			(_admin) => _admin.email === admin.email
		);

		if (existingAdmin) {
			// If the admin record already exists, update the date invited
			existingAdmin.dateInvited = admin.dateInvited;
		} else if (admin) {
			// If the admin record doesn't exist, add it
			this.admins.push(admin);
		}
	}

	/**
	 * Add a tag to the group
	 * @param tag
	 */
	@action
	public addTag(tag: string) {
		RestApiClient.serviceRequest<MemberTagModel>({
			generator: () =>
				TaggingService.createTagClubsDisplayIdTagsPost({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						label: tag
					}
				}),
			userToken: AppState.user?.cookie
		}).subscribe((data: MemberTagModel) => {
			this.tags.push(data);
			// capture action
			posthog.capture('Added Tag to Group', {
				group: this.name,
				tag: tag,
				source: 'web'
			});
		});
	}

	/**
	 * Remove a tag from the group
	 * @param tid
	 */
	@action
	public removeTag(tid: number) {
		RestApiClient.serviceRequest({
			generator: () =>
				TaggingService.deleteTagClubsDisplayIdTagsTidDelete({
					displayId: encodeURIComponent(this.displayId),
					tid: tid
				}),
			userToken: AppState.user?.cookie
		}).subscribe(() => {
			// remove the tag locally
			this.tags = this.tags.filter((tag) => tag.tid !== tid);
			// capture action
			posthog.capture('Removing a Tag from a Group', {
				group: this.name,
				tid: tid,
				source: 'web'
			});
		});
	}

	/**
	 * Bulk activate multiple members
	 */
	@action
	public bulkActivateMembers(uids: number[]) {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.bulkActivateMembersClubsDisplayIdMembersbulkActivateMembersPut(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							userIds: uids
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// update locally
				uids.forEach((uid: number) => {
					let member: IGroupMember = this._allGroupMembers.get(uid);
					member.active = true;
					member.accepted = true;
					member.rejected = false;
					member.suspended = false;
					member.dateLeft = undefined;
					member.uploaded = true;
				});
			})
		);
	}

	/**
	 * Bulk suspend multiple members
	 */
	@action
	public bulkSuspendMembers(uids: number[]) {
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.bulkSuspendMembersClubsDisplayIdMembersbulkSuspendMembersPut(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							userIds: uids
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// update locally
				uids.forEach((uid: number) => {
					// this._allGroupMembers.get(uid).status =
					// 	GroupMemberStatusEnum.Inactive;
					let member: IGroupMember = this._allGroupMembers.get(uid);
					member.active = false;
					member.dateLeft = new Date();
					member.uploaded = false;
					member.rejected = true;
					member.suspended = true;
				});
			})
		);
	}

	/**
	 * Update tags for members in this group
	 * @param tag
	 * @param toAdd
	 * @param toRemove
	 */
	@action
	public updateMemberTags(
		label: string,
		toAdd: number[],
		toRemove: number[]
	) {
		// get tid to update
		const tid: number = this.tags.find((tag) => tag.label === label).tid;
		// send request to server
		return RestApiClient.serviceRequest({
			generator: () =>
				MembersService.updateMultipleMembersClubsDisplayIdMembersPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						tid: tid,
						add: toAdd,
						remove: toRemove
					}
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// update locally add tags to all the members
				toAdd.forEach((uid: number) => {
					this._allGroupMembers.get(uid).addTag(label);
				});
				// update locally remove tags from all the members
				toRemove.forEach((uid: number) => {
					this._allGroupMembers.get(uid).removeTag(label);
				});
				// capture action
				posthog.capture('Updated Member Tags', {
					group: this.name,
					tid: tid,
					added: toAdd,
					removed: toRemove,
					source: 'web'
				});
			})
		);
	}

	/**
	 * Change the visiblity of a tag
	 * @param tid
	 * @param visible
	 */
	@action
	public changeTagVisibility(tid: number, visible: boolean) {
		RestApiClient.serviceRequest({
			generator: () =>
				TaggingService.updateTagClubsDisplayIdTagsTidPut({
					displayId: encodeURIComponent(this.displayId),
					tid: tid,
					requestBody: {
						visible: visible
					}
				}),
			userToken: AppState.user?.cookie
		}).subscribe(() => {
			// update the tag locally
			this.tags.find((tag) => tag.tid === tid).visible = visible;
			// capture action
			posthog.capture(
				`Tag Visibility Changes to ${
					visible ? 'Shown' : 'Hidden'
				} to Members`,
				{
					group: this.name,
					tid: tid,
					source: 'web'
				}
			);
		});
	}

	/**
	 * Change the label of a tag
	 * @param tid
	 * @param visible
	 */
	@action
	public changeTagLabel(tid: number, label: string) {
		RestApiClient.serviceRequest({
			generator: () =>
				TaggingService.updateTagClubsDisplayIdTagsTidPut({
					displayId: encodeURIComponent(this.displayId),
					tid: tid,
					requestBody: {
						label: label
					}
				}),
			userToken: AppState.user?.cookie
		}).subscribe(() => {
			// update the tag locally
			this.tags.find((tag) => tag.tid === tid).label = label;
			// capture action
			posthog.capture('Tag Label Updated', {
				group: this.name,
				tid: tid,
				source: 'web'
			});
		});
	}

	@action
	public addMatchingRule(
		first: number,
		second: number,
		relationship: MatchingRuleEnum
	) {
		RestApiClient.serviceRequest<TagMatchingRule>({
			generator: () =>
				TagBasedMatchingLogicService.createMatchingRuleClubsDisplayIdTagsMatchingLogicPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							first: first,
							second: second,
							relationship: relationship
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: TagMatchingRule) => {
			this.matchingRules.push(data);
			// capture action
			posthog.capture('Added Tag Matching Rule', {
				group: this.name,
				first: first,
				second: second,
				relationship: relationship,
				source: 'web'
			});
		});
	}

	@action
	public updateMatchingRule({
		id,
		active,
		first,
		second,
		relationship
	}: {
		id: number;
		active?: boolean;
		first?: number;
		second?: number;
		relationship?: MatchingRuleEnum;
	}) {
		RestApiClient.serviceRequest<TagMatchingRule>({
			generator: () =>
				TagBasedMatchingLogicService.updateMatchingRuleClubsDisplayIdTagsMatchingLogicPut(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							id: id,
							active: active,
							first: first,
							second: second,
							relationship: relationship
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((data: TagMatchingRule) => {
			// Find the rule in the list and update it
			let updatedRule: TagMatchingRule = this.matchingRules.find(
				(rule) => rule.id === id
			);

			updatedRule.active = data.active;
			updatedRule.first = data.first;
			updatedRule.firstLabel = data.firstLabel;
			updatedRule.second = data.second;
			updatedRule.secondLabel = data.secondLabel;
			updatedRule.relationship = data.relationship;
			// capture action
			posthog.capture('Updated Tag Matching Rule', {
				group: this.name,
				tid: id,
				source: 'web'
			});
		});
	}

	public getSetupData(forceFetch?: boolean) {
		// block us from loading multiple times
		if (!this.setupData || forceFetch) {
			RestApiClient.serviceRequest<ClubSetupModel>({
				generator: () =>
					ClubsService.getClubSetupDataClubsDisplayIdSetupDataGet({
						displayId: encodeURIComponent(this.displayId)
					}),
				userToken: AppState.user?.cookie
			}).subscribe((data: ClubSetupModel) => {
				this.setSetupData(data);
			});
		}
	}

	public updateSetupData(page: GroupSetupEnum) {
		RestApiClient.serviceRequest({
			generator: () =>
				ClubsService.updateClubSetupDataClubsDisplayIdSetupDataPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						page: page
					}
				}),
			userToken: AppState.user?.cookie
		}).subscribe(() => {
			this.setSetupStepComplete(page);
		});
	}

	public getInsightsFilters() {
		this.setInsightsFilters(
			this.pastAdHocMatches.map((round: IMatchingRoundModel) => {
				return {
					eid: round.id,
					label: round.time.toDateString()
				};
			})
		);
	}

	public getInsights(label: string, params: InsightsSearchRequest) {
		// check for cached data before making request
		if (!this.insightsOverview.has(label)) {
			// mark insights are loading
			this.setInsightsLoading(true);
			RestApiClient.serviceRequest<InsightsSummary>({
				generator: () =>
					InsightsService.getInsightsDataClubsDisplayIdInsightsPost({
						displayId: encodeURIComponent(this.displayId),
						requestBody: params
					}),
				userToken: AppState.user?.cookie
			}).subscribe((data: InsightsSummary) => {
				this.setInsightsOverview(label, data);
				// mark insights as loaded
				this.setInsightsLoading(false);
			});
		}
	}

	/**
	 * Load matches from the server
	 * @param page
	 * @param size
	 * @param params
	 */
	public getMatches(
		page: number = 0,
		size: number = 25,
		clearOnReload: boolean = false,
		params?: MatchesSearchParameters
	) {
		RestApiClient.serviceRequest<PostMatchesSearchResponse>({
			generator: () =>
				MatchesService.getMatchesClubsDisplayIdMatchesSearchPost({
					displayId: encodeURIComponent(this.displayId),
					page: page,
					size: size,
					requestBody: params || {}
				})
		}).subscribe((response: PostMatchesSearchResponse) => {
			if (clearOnReload) {
				this.matchesMap.clear();
			}
			this.addToMatches(response.matches);
			this.setNumberOfMatches(response.count);
			this.setMatchesLoaded();
		});
	}

	/**
	 * Loads engagement from the server
	 * @param page
	 * @param size
	 * @param params
	 */
	public getEngagement(
		page: number = 0,
		size: number = 25,
		clearOnReload: boolean = false,
		params?: EngagementSearchParameters
	) {
		RestApiClient.serviceRequest<GetEngagementResponse>({
			generator: () =>
				EngagementService.getEngagementClubsDisplayIdEngagementSearchPost(
					{
						displayId: encodeURIComponent(this.displayId),
						page: page,
						size: size,
						requestBody: params || {}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe((response: GetEngagementResponse) => {
			if (clearOnReload) {
				this.engagementEventsMap.clear();
			}
			this.addToEngagementEvents(response.events);
			this.setNumberOfEngagementEvents(response.count);
		});
	}

	public getMatchReviews(
		page: number = 0,
		size: number = 25,
		clearOnReload: boolean = false,
		params?: FeedbackSearchParameters
	) {
		RestApiClient.serviceRequest<GetFeedbackResponse>({
			generator: () =>
				FeedbackService.getReviewsClubsDisplayIdFeedbackSearchPost({
					displayId: encodeURIComponent(this.displayId),
					page: page,
					size: size,
					requestBody: params || {}
				}),
			userToken: AppState.user?.cookie
		}).subscribe((response: GetFeedbackResponse) => {
			if (clearOnReload) {
				this.matchReviewsMap.clear();
			}
			this.addToMatchReviews(response.reviews);
			this.setNumberOfMatchReviews(response.count);
		});
	}

	public loadEngagementDetails(id: number) {
		if (!this.engagementAdditionalInfo.has(id)) {
			RestApiClient.serviceRequest<EventDetails[]>({
				generator: () =>
					EngagementService.getEngagementDetailsClubsDisplayIdEngagementIdGet(
						{
							displayId: encodeURIComponent(this.displayId),
							id: id
						}
					),
				userToken: AppState.user?.cookie
			}).subscribe((data: EventDetails[]) => {
				this.setEngagementAdditionalDetails(id, data);
			});
		}
	}

	public loadFeedbackDetails(id: number): Observable<unknown> {
		return RestApiClient.serviceRequest<FeedbackDetails[]>({
			generator: () =>
				FeedbackService.getEngagementDetailsClubsDisplayIdFeedbackIdGet(
					{
						displayId: encodeURIComponent(this.displayId),
						id: id,
						cookie: AppState.user?.cookie
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: FeedbackDetails[]) => {
				this.setFeedbackDetails(id, data);
			})
		);
	}

	public requestExportedData(params: DataExportRequest) {
		return RestApiClient.serviceRequest({
			generator: () =>
				ReportsService.exportRequestClubsDisplayIdReportsPost({
					displayId: encodeURIComponent(this.displayId),
					requestBody: params
				})
		});
	}

	public acceptMember(id: number) {
		if (this._allGroupMembers.has(id)) {
			const member = this._allGroupMembers.get(id) as IGroupMember;
			return member.accept().pipe(
				map(() => {
					// add user to group members, remove from pending group members
					member.getProfile();
					// set number of pending members
					this.setNumberOfPendingMembers(
						this.numberOfPendingMembers - 1
					);
					// increment number of active members
					this.setNumberOfActiveMembers(
						this.numberOfActiveMembers + 1
					);
				})
			);
		}
	}

	public rejectMember(id: number) {
		if (this._allGroupMembers.has(id)) {
			const member = this._allGroupMembers.get(id) as IGroupMember;
			return member.reject().pipe(
				map(() => {
					// set number of pending members
					this.setNumberOfPendingMembers(
						this.numberOfPendingMembers - 1
					);
					this.setNumberOfInactiveMembers(
						this.numberOfInactiveMembers + 1
					);
				})
			);
		}
	}

	public confirmOrUpdateMemberEmail(id: number, email?: string) {
		if (this._allGroupMembers.has(id)) {
			const member = this._allGroupMembers.get(id) as IGroupMember;
			return member.updateUserProfile(email ? { email: email } : {}).pipe(
				map(() => {
					// set number of pending members
					this.setNumberOfAttentionNeededMembers(
						this.numberOfAttentionNeededMembers - 1
					);
				})
			);
		}
	}

	public removeMember(id: number, admin: boolean) {
		if (this._allGroupMembers.has(id)) {
			const member = this._allGroupMembers.get(id) as IGroupMember;
			return member.remove(admin).pipe(
				map(() => {
					// remove from results
					this.orderedMemberUids = this.orderedMemberUids.filter(
						(uid: number) => uid !== id
					);
					// remove user from all group members
					this._allGroupMembers.delete(id);
					// reload status counts for group
					this.loadMemberStatusCount().subscribe();
					// capture action
					posthog.capture('Admin Removed Member from Group', {
						source: 'web',
						group: AppState.selectedGroup?.name,
						uid: id
					});
				})
			);
		}
	}

	public getMatchingRounds(): Observable<void> {
		return RestApiClient.serviceRequest<GetMatchingRoundsResponse>({
			generator: () =>
				MatchingRoundsService.getMatchingRoundsClubsDisplayIdMatchingRoundsGet(
					{
						displayId: encodeURIComponent(this.displayId),
						active: true
					}
				)
		}).pipe(
			map((data: GetMatchingRoundsResponse) => {
				this.setMatchingRounds(data.rounds);
				this.setMatchingRoundsLoaded();
			})
		);
	}

	public getMatchingRoundById(id: number): MatchingRoundClassModel {
		return this._matchingRounds.get(id);
	}

	public getInviteRoundByMrid(mrid: number): InviteRoundClassModel {
		// Get upcoming invite round
		const targettingInviteRound: InviteRoundClassModel = this.inviteRounds.find(
			(round) => round.match === mrid
		);
		// return invite round if it exists
		if (targettingInviteRound) {
			return targettingInviteRound;
		}
		// find matching round specified by id
		const matchingRound: MatchingRoundClassModel = this._matchingRounds.get(
			mrid
		);
		if (matchingRound) {
			// get invite round that comes before
			const priorInviteRound: InviteRoundClassModel = this.inviteRounds
				.sort((a: InviteRoundClassModel, b: InviteRoundClassModel) =>
					a.time > b.time ? -1 : 1
				)
				.find(
					(round) => round.active && round.time < matchingRound.time
				);
			// return undefined if no prior invite round
			if (!priorInviteRound) {
				return undefined;
			}
			// get matching round that comes before
			const priorMatchingRound: MatchingRoundClassModel = this.matchingRounds
				.sort(
					(a: MatchingRoundClassModel, b: MatchingRoundClassModel) =>
						a.time > b.time ? -1 : 1
				)
				.find(
					(round) => round.active && round.time < matchingRound.time
				);
			// return invite round if it comes before matching round
			return !priorMatchingRound ||
				priorMatchingRound.time < priorInviteRound.time
				? priorInviteRound
				: undefined;
		} else {
			return undefined;
		}
	}

	public getInviteRounds(): Observable<void> {
		return RestApiClient.serviceRequest<GetInvitesResponse>({
			generator: () =>
				InvitesService.getInvitesClubsDisplayIdInvitesGet({
					displayId: encodeURIComponent(this.displayId),
					active: true
				}),
			userToken: AppState.user.cookie
		}).pipe(
			map((data: GetInvitesResponse) => {
				this.setInviteRounds(data.rounds);
			})
		);
	}

	public getMatchFeedback(hash: string, uid: number) {
		return RestApiClient.serviceRequest<AnsweredQuestionModel[]>({
			generator: () =>
				FeedbackService.getFeedbackForMatchMatchesHashFeedbackGet({
					hash: hash,
					uid: uid
				}),
			userToken: AppState.user?.cookie
		});
	}

	public saveCustomDataUsage(usage: CustomDataUsageEnum) {
		return RestApiClient.serviceRequest({
			generator: () =>
				MessagingService.updateDataUsageClubsDisplayIdMessagingPut({
					displayId: encodeURIComponent(this.displayId),
					requestBody: {
						questionIds: Array.from(
							this.modifiedCustomData.values()
						).map((val: ICustomDataModel) => val.qid),
						usage: usage
					}
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// For all toggle updates, log action and whether it was successful
				const questions: Array<ICustomDataModel> = Array.from(
					this.modifiedCustomData.values()
				);
				for (var i = 0; i < questions.length; ++i) {
					const action: string =
						questions[i].usage === usage ||
						questions[i].usage === CustomDataUsageEnum.Both
							? 'enable'
							: 'disable';
				}
				// clear to local edited data cache
				this.modifiedCustomData.clear();
			})
		);
	}

	// send email or text depending on boolean passed in
	public sendTestMessage(
		contact: string,
		messageType: MessageTypeEnum,
		iMessage: boolean,
		subject?: string,
		body?: string
	) {
		return RestApiClient.serviceRequest({
			generator: () =>
				MessagingService.requestTestMessageClubsDisplayIdMessagingTestPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							message: messageType,
							email: this.isEmailGroup ? contact : undefined,
							discordTag: this.isDiscordGroup
								? contact
								: undefined,
							subject: subject,
							body: body
						}
					}
				),
			userToken: AppState.user?.cookie
		}) as Observable<void>;
	}

	public sendTestSlackMessage(messageType: string = 'welcome_message') {
		// sent request
		return RestApiClient.slackServiceRequest<IErrorResponse>(
			`/groups/${encodeURIComponent(
				AppState.selectedGroup.displayId
			)}/sendTestMessage`,
			{
				method: HttpMethodEnum.POST,
				body: {
					messageType: messageType,
					cookie: AppState.user?.cookie
				}
			}
		).pipe(
			map((response: IErrorResponse) => {
				// capture invite
				posthog.capture('Slack Test Welcome Message Sent', {
					group: AppState.selectedGroup?.name,
					source: 'web'
				});

				return response;
			})
		);
	}

	/*
	 * Send a custom slack message to a list of users
	 */
	public sendCustomSlackMessage(
		message: string,
		userIds: number[],
		subject?: string
	) {
		// sent request
		return RestApiClient.slackServiceRequest<IErrorResponse>(
			`/groups/${encodeURIComponent(
				AppState.selectedGroup.displayId
			)}/sendCustomMessage`,
			{
				method: HttpMethodEnum.POST,
				body: {
					subject: subject,
					message: message,
					userIds: userIds,
					cookie: AppState.user?.cookie
				}
			}
		).pipe(
			map((response: IErrorResponse) => {
				// capture invite
				posthog.capture('Slack Custom Message Sent', {
					group: AppState.selectedGroup?.name,
					source: 'web'
				});

				return response;
			})
		);
	}

	public loadConfig() {
		// create a new group config
		this.config = new GroupConfig({
			gid: this.gid,
			displayId: this.displayId,
			name: this.name
		});
		this.config.initialized.subscribe(() => {
			this.isConfigInitialized = true;
			this.configInitialized.next();
		});
	}

	public refreshConfig() {
		this.config.loadConfig();
	}

	public addIcebreakerToGroup(question: string) {
		return RestApiClient.serviceRequest({
			generator: () =>
				IcebreakersService.createIcebreakerClubsDisplayIdIcebreakersPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							question: question
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// if successful, retrieve our icebreakers
				this.getIcebreakers();
			})
		);
	}

	public getIcebreakers() {
		return RestApiClient.serviceRequest<IcebreakerModel[]>({
			generator: () =>
				IcebreakersService.getIcebreakersClubsDisplayIdIcebreakersGet({
					displayId: encodeURIComponent(this.displayId)
				}),
			userToken: AppState.user.cookie
		}).subscribe((data: IcebreakerModel[]) => {
			// update the local memory of our icebreakers
			this.setIcebreakers(data);
		});
	}

	public deleteIcebreaker(uid: number) {
		return RestApiClient.serviceRequest({
			generator: () =>
				IcebreakersService.deleteIcebreakerClubsDisplayIdIcebreakersIcebreakerIdDelete(
					{
						displayId: encodeURIComponent(this.displayId),
						icebreakerId: uid
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// re-fetch icebreakers
				this.getIcebreakers();
			})
		);
	}

	public getPreviewAnswersForQuestion(qid: number) {
		return this._joinSurvey?.getPreviewAnswersForQuestion(qid);
	}

	public migrateToNewWelcomeTemplate() {
		return this.config.update({
			legacyWelcomeMessage: false
		});
	}

	/**
	 * Initiates POST request that creates intros channel in Discord
	 */
	@action
	public kickoffDiscord(welcomeText: string, helpText: string) {
		return RestApiClient.serviceRequest({
			generator: () =>
				DiscordService.kickoffDiscordServerInternalIntegrationsWebhooksDiscordKickoffPost(
					{
						requestBody: {
							displayId: this.displayId,
							welcomeDesc: welcomeText,
							helpDesc: helpText
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				// update setup data
				this.updateSetupData(GroupSetupEnum.Rollout);
			})
		);
	}

	@action
	public getJoinSurvey(admin: boolean, callback?: () => void) {
		if (this.isConfigInitialized) {
			this._joinSurvey = new JoinSurveyModel({
				gid: this.gid,
				displayId: this.displayId,
				type: SurveyTypes.PROFILE,
				emailIndex: this.config?.emailIndex,
				basicsIndex: this.config?.basicsIndex,
				timesIndex: this.config?.timesIndex,
				admin
			});
			if (callback) {
				this._joinSurvey.loaded.subscribe(callback);
			}
		}
		this.configInitialized.subscribe(() => {
			this._joinSurvey = new JoinSurveyModel({
				gid: this.gid,
				displayId: this.displayId,
				type: SurveyTypes.PROFILE,
				emailIndex: this.config?.emailIndex,
				basicsIndex: this.config?.basicsIndex,
				timesIndex: this.config?.timesIndex,
				admin
			});
			if (callback) {
				this._joinSurvey.loaded.subscribe(callback);
			}
		});
	}

	@action
	public getReviewSurvey(admin: boolean) {
		this._reviewSurvey = new SurveyClassModel({
			gid: this.gid,
			displayId: this.displayId,
			type: SurveyTypes.FEEDBACK,
			admin
		});
	}

	@action
	public loadMemberInfo(uid: number, infoType: GroupMemberInfoEnum) {
		if (this._allGroupMembers.has(uid)) {
			this._allGroupMembers.get(uid).getProfile();
		}
	}

	@action
	public createMatchingRound(body: CreateMatchingRoundRequest) {
		// Create matching round
		return RestApiClient.serviceRequest({
			generator: () =>
				MatchingRoundsService.createMatchingRoundClubsDisplayIdMatchingRoundsPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: body
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map(() => {
				this.getMatchingRounds().subscribe();
			})
		);
	}

	/**
	 * Reinvite a single invited member of the group
	 */
	@action
	public reinviteMember(id: number) {
		return this._allGroupMembers.get(id).reinvite();
	}

	/**
	 * Create a checkout session for the group
	 */
	@action
	public createCheckoutSession(
		priceLookupKey?: StripePriceLookupKeyEnum,
		customSubscriptionData?: CustomSubscriptionData,
		successUrl?: string,
		cancelUrl?: string
	) {
		// Ensure that at least one of priceLookupKey or customSubscriptionData is provided
		if (!priceLookupKey && !customSubscriptionData) {
			throw new Error(
				'At least one of priceLookupKey or customSubscriptionData must be provided'
			);
		}

		return RestApiClient.serviceRequest<CreateCheckoutSessionResponse>({
			generator: () =>
				ClubsService.createCheckoutSessionClubsDisplayIdSubscriptionCreateCheckoutSessionPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							priceLookupKey: priceLookupKey,
							customSubscriptionData: customSubscriptionData,
							successUrl: successUrl || window.location.href,
							cancelUrl: cancelUrl || window.location.href
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: CreateCheckoutSessionResponse) => {
				// window.location.href = data.url;
				return data;
			})
		);
	}

	/**
	 * Create a trial subscription for the group
	 */
	@action
	public createTrialSubscription(
		priceLookupKey?: StripePriceLookupKeyEnum,
		customSubscriptionData?: CustomSubscriptionData,
		trialEnd?: string
	) {
		// Ensure that at least one of priceLookupKey or customSubscriptionData is provided
		if (!priceLookupKey && !customSubscriptionData) {
			throw new Error(
				'At least one of priceLookupKey or customSubscriptionData must be provided'
			);
		}

		return RestApiClient.serviceRequest<CreateTrialSubscriptionResponse>({
			generator: () =>
				ClubsService.createTrialSubscriptionClubsDisplayIdSubscriptionCreateTrialSubscriptionPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							priceLookupKey: priceLookupKey,
							customSubscriptionData: customSubscriptionData,
							trialEnd: trialEnd
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: CreateTrialSubscriptionResponse) => {
				this.subscription = {
					interval: 'month',
					trialStartDate: new Date().toISOString(),
					trialEndDate: trialEnd,
					amount: 0,
					payments: [],
					status: 'trialing',
					customerHasPaymentMethod: false
				};
				this.subscriptionName = 'Pro Plan';
				return data;
			})
		);
	}

	/**
	 * Create a portal session for the user to manage their subscription
	 */
	@action
	public manageSubscription(flow?: PortalFlow) {
		return RestApiClient.serviceRequest<CreatePortalSessionResponse>({
			generator: () =>
				ClubsService.createPortalSessionClubsDisplayIdSubscriptionCreatePortalSessionPost(
					{
						displayId: encodeURIComponent(this.displayId),
						requestBody: {
							returnUrl: window.location.href,
							flow: flow
						}
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: CreatePortalSessionResponse) => {
				window.location.href = data.url;
			})
		);
	}

	/**
	 * Get the subscription for the group
	 */
	@action
	public getSubscription(): Observable<GetSubscriptionResponse> {
		return RestApiClient.serviceRequest<GetSubscriptionResponse>({
			generator: () =>
				ClubsService.getClubSubscriptionClubsDisplayIdSubscriptionGet({
					displayId: encodeURIComponent(this.displayId)
				}),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: GetSubscriptionResponse) => {
				console.log('Assigning subscription');
				this.subscription = data.subscription;
				return data;
			}),
			catchError((error) => {
				console.log(
					'Error getting subscription - likely no subscription'
				);
				return of(null);
			})
		);
	}

	/**
	 * Enable the ai chat for the group
	 */
	@action
	public initializeAiChat(): Observable<ClubsInitializeAiChatPostResponse> {
		return RestApiClient.serviceRequest<ClubsInitializeAiChatPostResponse>({
			generator: () =>
				ClubsService.initializeAiChatClubsDisplayIdInitializeAiChatPost(
					{
						displayId: encodeURIComponent(this.displayId)
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: ClubsInitializeAiChatPostResponse) => {
				this.openAiAssistantId = data.openAiAssistantId;
				this.openAiAssistantEnabled = true;
				return data;
			})
		);
	}

	/**
	 * Deactivate the ai chat for the group
	 */
	@action
	public deactivateAiChat(): Observable<any> {
		return RestApiClient.serviceRequest<any>({
			generator: () =>
				ClubsService.deactivateAiChatClubsDisplayIdDeactivateAiChatPost(
					{
						displayId: encodeURIComponent(this.displayId)
					}
				),
			userToken: AppState.user?.cookie
		}).pipe(
			map((data: any) => {
				this.openAiAssistantEnabled = false;
				return data;
			})
		);
	}

	/**
	 * Sync all members in a slack workspace with the group. Optionally connect a slack workspace with a slackTeamId
	 */
	@action
	public syncSlackMembers(slackTeamId?: string) {
		// sync slack members
		return RestApiClient.slackServiceRequest<IErrorResponse>(
			`/groups/${encodeURIComponent(
				AppState.selectedGroup.displayId
			)}/slack/add`,
			{
				method: HttpMethodEnum.POST,
				body: {
					team_id: slackTeamId
				}
			}
		).pipe(
			map((response: IErrorResponse) => {
				return response;
			})
		);
	}

	@action
	private setCustomData(data: MessagingData[]) {
		this.customData.clear();
		data.forEach((val: MessagingData) => {
			this.customData.set(val.qid, new CustomData(val));
		});
	}

	@action
	private setUserLiteData(users: UserLiteData[]) {
		users.forEach((user: UserLiteData) => {
			this._userLiteData.set(user.uid, user);
		});
	}

	@action
	public setNumberOfSearchedMembers(val: number) {
		this.numberOfSearchedMembers = val;
	}

	@action
	private setNumberOfSearchedMemberPages(val: number) {
		this.numberOfSearchedMemberPages = val;
	}

	@action
	private setNumberOfActiveMembers(val: number) {
		this.numberOfActiveMembers = val;
	}

	@action
	private setNumberOfInactiveMembers(val: number) {
		this.numberOfInactiveMembers = val;
	}

	@action
	private setNumberOfUploadedMembers(val: number) {
		this.numberOfUploadedMembers = val;
	}

	@action
	private setNumberOfInvitedMembers(val: number) {
		this.numberOfInvitedMembers = val;
	}

	@action
	private setNumberOfPendingMembers(val: number) {
		this.numberOfPendingMembers = val;
	}

	@action
	private setNumberOfAttentionNeededMembers(val: number) {
		this.numberOfAttentionNeededMembers = val;
	}

	/**
	 * Set whether insights data is loading
	 */
	@action
	private setInsightsLoading(val: boolean) {
		this.insightsLoading = val;
	}

	/**
	 * Cache the insights overview
	 * @param label
	 * @param overview
	 */
	@action
	private setInsightsOverview(label: string, overview: InsightsSummary) {
		this.insightsOverview.set(label, overview);
	}

	/**
	 * Cache the insights engagement data
	 * @param label
	 * @param engagement
	 */
	@action
	private setInsightsEngagement(
		label: string,
		engagement: EngagementEvent[]
	) {
		this.insightsEngagement.set(label, engagement);
	}

	/**
	 * Cache the insights feedback data
	 * @param label
	 * @param engagement
	 */
	@action
	private setInsightsFeedback(label: string, feedback: MatchReviewSummary[]) {
		this.insightsFeedback.set(label, feedback);
	}

	/**
	 * Cache the insights engagement additional details
	 * @param id
	 * @param details
	 */
	@action
	private setEngagementAdditionalDetails(
		id: number,
		details: EventDetails[]
	) {
		this.engagementAdditionalInfo.set(id, details);
	}

	/**
	 * Cache the insights feedback details
	 * @param id
	 * @param details
	 */
	@action
	private setFeedbackDetails(id: number, details: FeedbackDetails[]) {
		this.feedbackDetails.set(id, details);
	}

	@action
	private setMatchingRounds(data: MatchingRoundModel[]) {
		this._matchingRounds.clear();
		data.forEach((round: MatchingRoundModel) => {
			this._matchingRounds.set(
				round.id,
				new MatchingRoundClassModel({
					displayId: this.displayId,
					model: round,
					onReloadMatches: () => this.getMatchingRounds().subscribe()
				})
			);
		});
	}

	@action
	private setInviteRounds(data: InviteRoundModel[]) {
		this._inviteRounds.clear();
		data.forEach((invite: InviteRoundModel) => {
			this._inviteRounds.set(
				invite.id,
				new InviteRoundClassModel({
					displayId: this.displayId,
					model: invite,
					onReloadInvites: () => this.getInviteRounds().subscribe()
				})
			);
		});
	}

	@action
	private setIcebreakers(data: IcebreakerModel[]) {
		this.icebreakers = data;
	}

	@action
	protected setPastMatches(
		data: IMatchesByWeekData[] | IEventMatchesWrapper[]
	) {
		this.pastMatches = data as IMatchesByWeekData[];
	}

	@action
	private setSetupData(data: ClubSetupModel) {
		this.setupData = data;
	}

	@action
	private setSetupStepComplete(page: GroupSetupEnum) {
		switch (page) {
			case GroupSetupEnum.Join:
				this.setupData.joinFormComplete = true;
				break;
			case GroupSetupEnum.Welcome:
				this.setupData.welcomePageVisited = true;
				break;
			case GroupSetupEnum.OptIn:
				this.setupData.optInPreviewViewed = true;
				break;
			case GroupSetupEnum.Intro:
				this.setupData.introPageVisited = true;
				break;
			case GroupSetupEnum.Feedback:
				this.setupData.feedbackFormPreviewed = true;
				break;
			case GroupSetupEnum.Testing:
				this.setupData.testExperienceComplete = true;
				break;
			case GroupSetupEnum.Rollout:
				this.setupData.rollout = true;
				break;
		}
	}

	@action
	private setLoadingCustomData(val: boolean) {
		this.isLoadingCustomData = val;
	}

	@action
	private setInsightsFilters(filters: IGroupInsightsFilter[]) {
		this.insightsFilters = filters;
	}

	@action
	private extendSearchResults(members: MemberProfileModel[]) {
		this.orderedMemberUids.push(...members.map((member) => member.uid));
	}

	/**
	 * Set all matches in matches map
	 * @param matches
	 */
	@action
	private addToMatches(matches: MatchOverview[]) {
		matches.forEach((match: MatchOverview) => {
			this.matchesMap.set(match.mid, match);
		});
	}

	/**
	 * Set all engagement events in engagement events map
	 * @param events
	 */
	@action
	private addToEngagementEvents(events: EngagementEvent[]) {
		events.forEach((event: EngagementEvent) => {
			this.engagementEventsMap.set(event.id, event);
		});
	}

	@action
	private addToMatchReviews(reviews: MatchReviewSummary[]) {
		reviews.forEach((review: MatchReviewSummary) => {
			this.matchReviewsMap.set(review.id, review);
		});
	}

	/**
	 * Set number of matches
	 * @param count
	 */
	@action
	private setNumberOfMatches(count: number) {
		this.numberOfMatches = count;
	}

	/**
	 * Set number of engagement events
	 * @param count
	 */
	@action
	private setNumberOfEngagementEvents(count: number) {
		this.numberOfEngagementEvents = count;
	}

	/**
	 * Set number of match reviews
	 * @param count
	 */
	@action
	private setNumberOfMatchReviews(count: number) {
		this.numberOfMatchReviews = count;
	}

	/**
	 * Set that matches have been loaded
	 */
	@action
	private setMatchesLoaded() {
		this.matchesLoaded = true;
	}

	/**
	 * Set that matching rounds have been loaded
	 */
	@action
	private setMatchingRoundsLoaded() {
		this.matchingRoundsLoaded = true;
	}
}
